import { getAxiosInstance } from "../data-source/axiosProvider";
import {
  mapObjectArrayToCamelCase,
  unknownErrorHandlerFactory,
  mapKeysToSnakeCase,
  removeNullValuesObjArray,
  createInvertedObjectKeyMap,
  splitToObjectsByKeys,
} from "../services/utils";
import {
  S_ATTR_CLIENT,
  S_ATTR_PHYSICAL_PERSON,
  S_MODELS,
} from "../constants/serverContract";
import { CLIENT_TYPES } from "../constants/appConstants";
import _ from "lodash";

const invertedObjKeyMap = createInvertedObjectKeyMap({
  [S_MODELS.CLIENT]: Object.values(S_ATTR_CLIENT),
  [S_MODELS.PHYSICAL_PERSON]: Object.values(S_ATTR_PHYSICAL_PERSON),
});

export async function fetchPhysicalPeople() {
  return getAxiosInstance()
    .get("v1/physicalpeople")
    .then((result) => result.data)
    .then((data) =>
      data.map(({ PhysicalClient, ...rest }) => ({
        ...PhysicalClient,
        ...rest,
      })),
    )
    .then(mapObjectArrayToCamelCase)
    .then(removeNullValuesObjArray);
  //.then( promiseLogger )
}

export async function updatePhysicalPeople(physicalPeople) {
  const { clientId, physicalPersonId } = physicalPeople;
  let splitedObjects = splitToObjectsByKeys(
    mapKeysToSnakeCase(
      _.omit(physicalPeople, ["clientId", "physicalClientId", "createdAt"]),
    ),
    invertedObjKeyMap,
  );

  if (
    [S_MODELS.CLIENT] in splitedObjects &&
    Object.keys(splitedObjects[S_MODELS.CLIENT]).length > 0
  ) {
    await getAxiosInstance().patch(
      `/v1/clients/${clientId}`,
      splitedObjects[S_MODELS.CLIENT],
    );
  }

  if (
    [S_MODELS.PHYSICAL_PERSON] in splitedObjects &&
    Object.keys(splitedObjects[S_MODELS.PHYSICAL_PERSON]).length > 0
  ) {
    await getAxiosInstance().patch(
      `/v1/physicalpeople/${physicalPersonId}`,
      splitedObjects[S_MODELS.PHYSICAL_PERSON],
    );
  }
}

export async function deletePhysicalPerson(argPhysicalPersonId, argClientId) {
  return getAxiosInstance()
    .delete(`/v1/physicalpeople/${argPhysicalPersonId}`)
    .then((result) => getAxiosInstance().delete(`/v1/clients/${argClientId}`));
}

export async function createPhysicalPerson(physicalPerson) {
  let splitedObjects = splitToObjectsByKeys(
    mapKeysToSnakeCase(physicalPerson),
    invertedObjKeyMap,
  );

  return getAxiosInstance()
    .post("/v1/clients", {
      ...splitedObjects[S_MODELS.CLIENT],
      [S_ATTR_CLIENT.CLIENT_TYPE]: CLIENT_TYPES.PHYSICAL_PERSON,
    })
    .then((result) => result.data[S_ATTR_CLIENT.CLIENT_ID])
    .then((clientId) =>
      getAxiosInstance().post("/v1/physicalpeople", {
        ...splitedObjects[S_MODELS.PHYSICAL_PERSON],
        [S_ATTR_PHYSICAL_PERSON.CLIENT_ID_FK]: clientId,
      }),
    );
}
