import NoUserImage from "./NoUserImage";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import { useSelector } from "react-redux";
import makeStyles from "@material-ui/styles/makeStyles";
import red from "@material-ui/core/colors/red";
import UploadIcon from "@material-ui/icons/Publish";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import {
  EMPTY_STRING,
  joinPath,
  isNotEmptyImagePath,
} from "../../../services/utils";
import { BASE_URL } from "../../../constants/appConstants";
import AddPhotoAlternateTwoToneIcon from "@material-ui/icons/AddPhotoAlternateTwoTone";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";
import { useEffect, useMemo, useRef, useState } from "react";
import useRerender from "../../../hooks/rerenderHook";

const sizingFactor = 1.3;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    borderRadius: 6,
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    marginTop: 4,
  },

  rootLarger: {
    maxWidth: 345 * sizingFactor,
  },
  media: {
    //height: 0,
    //paddingTop: '56.25%', // 16:9
    height: 240,
    width: 240,
    marginLeft: "auto",
    marginRight: "auto",
  },
  mediaLarger: {
    height: 240 * sizingFactor,
    width: 240 * sizingFactor,
  },
  actions: {
    position: "relative",
    bottom: "2.1rem",
    left: "-0.3rem",
    height: 0,
    width: 0,
  },
  noImageActions: {
    bottom: "2.8rem",
    left: "-0.35rem",
  },
  uploadedActions: {
    bottom: "2.1rem",
    left: "-0.3rem",
  },
  actionsLarger: {
    bottom: "3.65rem",
    left: "-0.35rem",
  },
  wrapperContainer: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

const fileInputStyle = {
  display: "none",
};

export default function ProfileImage(props) {
  const user = useSelector((store) => store.auth);
  const classes = useStyles();

  const {
    canUpload = null,
    uniqueFileInputId = null,
    thisUser = true,
    className,
    imageUrl = (props.thisUser && user.picturePath) || EMPTY_STRING,
    noImageUrl = EMPTY_STRING,
    title = props.thisUser && user.fullName,
    size = "medium",
    inputFileProps,
    ...rest
  } = props;

  const fileReaderRef = useRef(null);

  const rerender = useRerender();
  const imagePackRef = useRef({
    initialValue: true,
    isDirty: false,
    currentImageUrl: imageUrl,
    image: EMPTY_STRING,
  });

  useEffect(() => {
    if (imagePackRef.current.initialValue) {
      imagePackRef.current.initialValue = false;
      imagePackRef.current.isDirty = false;
      imagePackRef.current.currentImageUrl = imageUrl;
      imagePackRef.current.image = EMPTY_STRING;
    } else {
      imagePackRef.current.isDirty = false;
      imagePackRef.current.currentImageUrl = imageUrl;
      imagePackRef.current.image = EMPTY_STRING;
    }
  }, [imageUrl]);

  const isLargeSize = size === "large";
  const hasImageUrl =
    isNotEmptyImagePath(imagePackRef.current.currentImageUrl) &&
    !imagePackRef.current.isDirty;
  const isUploaded = imagePackRef.current.image !== EMPTY_STRING;

  const isImageDirty = imagePackRef.current.isDirty;

  const tempCurrentImageUrl = imagePackRef.current.currentImageUrl;

  const realImageUrl = useMemo(
    () =>
      hasImageUrl ? joinPath(BASE_URL, tempCurrentImageUrl) : EMPTY_STRING,
    [hasImageUrl, tempCurrentImageUrl],
  );

  const onUploadIconClickHandler = (event) => {
    if (uniqueFileInputId) {
      document
        .querySelector(
          uniqueFileInputId.charAt(0) === "#"
            ? uniqueFileInputId
            : `#${uniqueFileInputId}`,
        )
        .click();
    } else {
      console.log("No file input id in props of ProfileImage component");
    }
  };

  const inputFileOnChangeHandler = (event) => {
    if (fileReaderRef.current != null) {
      fileReaderRef.current.abort();
      fileReaderRef.current = null;
    }

    const tempFileReader = new FileReader();
    fileReaderRef.current = tempFileReader;
    tempFileReader.onload = function (event) {
      if (tempFileReader.readyState === 2) {
        imagePackRef.current.isDirty = true;
        imagePackRef.current.currentImageUrl = EMPTY_STRING;
        imagePackRef.current.image = tempFileReader.result;
        rerender();
      }
    };

    let tempFile = event.target.files[0];
    if (tempFile) {
      tempFileReader.readAsDataURL(tempFile);
    }
  };

  return (
    <div
      className={clsx(
        classes.root,
        isLargeSize && classes.rootLarger,
        classes.wrapperContainer,
        className,
      )}
    >
      <Card
        {...rest}
        className={clsx(
          classes.root,
          isLargeSize && classes.rootLarger,
          className,
        )}
        variant="outlined"
      >
        {hasImageUrl || isImageDirty ? (
          <CardMedia
            className={clsx(classes.media, isLargeSize && classes.mediaLarger)}
            image={!isImageDirty ? realImageUrl : imagePackRef.current.image}
            title={title ? title : undefined}
          />
        ) : (
          <NoUserImage noImageUrl={noImageUrl} />
        )}
      </Card>
      {(canUpload || uniqueFileInputId) && (
        <CardActions
          className={clsx(
            classes.actions,
            isUploaded
              ? classes.uploadedActions
              : !hasImageUrl && classes.noImageActions,
            isLargeSize && classes.actionsLarger,
          )}
        >
          <IconButton onClick={onUploadIconClickHandler}>
            <UploadIcon />
          </IconButton>
        </CardActions>
      )}

      <input
        id={uniqueFileInputId}
        style={fileInputStyle}
        type="file"
        accept="image/*"
        onChange={inputFileOnChangeHandler}
        {...inputFileProps}
      />
    </div>
  );
}
