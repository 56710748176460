import { SvgIcon } from "@material-ui/core";

export default function CompanyLineIcon(props) {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <title>Linija tehnicko pregreda</title>
      <path
        d="M112 304h288M112 208h288"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="32"
      />
    </SvgIcon>
  );
}
/*
<svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><title>Reorder Two</title><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32' d='M112 304h288M112 208h288'/></svg>
*/
