import { SvgIcon } from "@material-ui/core";

export default function ClientsIcon(props) {
  return (
    <SvgIcon
      id="Layer_5"
      enable-background="new 0 0 64 64"
      height="512"
      viewBox="0 0 64 64"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m49.123 47.288-10.259-4.197c-1.132-.463-1.864-1.553-1.864-2.777v-.718c3.077-2.126 5.427-5.126 6.759-8.596h.241c2.757 0 5-2.243 5-5v-8c0-9.374-7.626-17-17-17h-17v1c0 1.034.134 2.035.363 3h-3.544l.194 1.165c.482 2.89 1.746 5.465 3.545 7.542-.363 1.396-.558 2.835-.558 4.293v8c0 2.757 2.243 5 5 5h.241c1.332 3.471 3.682 6.47 6.759 8.596v.718c0 1.224-.732 2.313-1.864 2.776l-10.259 4.197c-6 2.456-9.877 8.231-9.877 14.713v1h54v-1c0-6.482-3.877-12.257-9.877-14.712zm-5.376 4.114-8.278 3.104 2.145 3.217-4.917 3.277h-.549l.753-1.567c.216-.448 4.829-10.06 6.366-14.016l2.876 1.177zm-23.494 0 1.603-4.808 2.877-1.177c1.534 3.94 6.15 13.567 6.365 14.016l.753 1.567h-.548l-4.916-3.277 2.145-3.217zm7.743-3.402h8.009c-1.299 2.932-2.985 6.523-4.005 8.675-1.019-2.153-2.705-5.745-4.004-8.675zm16.418-19.042c.379-1.482.586-3.025.586-4.607v-1.167c1.161.414 2 1.514 2 2.816 0 1.512-1.129 2.754-2.586 2.958zm-28.411-21.958c1.963 4.693 6.6 8 11.997 8h7v-2h-7c-5.729 0-10.448-4.401-10.955-10h14.955c8.271 0 15 6.729 15 15v4.026c-.608-.46-1.314-.79-2.092-.934-.429-2.324-2.462-4.092-4.908-4.092h-12.834c-6.158 0-11.42-4.139-12.933-10zm1.239 8.358c.558.472 1.137.92 1.758 1.308v4.435c-.743.152-1.416.482-2 .924v-4.025c0-.891.087-1.773.242-2.642zm-.242 10.642c0-1.302.839-2.402 2-2.816v1.167c0 1.582.207 3.125.586 4.607-1.457-.204-2.586-1.446-2.586-2.958zm4-1.649v-6.63c1.893.825 3.98 1.279 6.166 1.279h12.834c1.654 0 3 1.346 3 3v2.351c0 7.023-4.406 13.247-11 15.591-6.594-2.344-11-8.567-11-15.591zm7.964 16.445c.864.45 1.772.837 2.719 1.152l.317.106.316-.105c.947-.316 1.855-.702 2.719-1.152.152 1.59 1.037 3.001 2.4 3.812-.153.4-.343.867-.564 1.392h-9.743c-.222-.525-.412-.993-.565-1.392 1.364-.812 2.249-2.223 2.401-3.813zm-13.33 8.344 3.781-1.547-1.668 5.005 7.722 2.896-1.855 2.783 4.083 2.723h-10.697v-6h-2v6h-7.965c.373-5.255 3.665-9.842 8.599-11.86zm33.366 11.86v-6h-2v6h-10.697l4.084-2.723-1.855-2.783 7.722-2.896-1.668-5.005 3.781 1.547c4.934 2.019 8.226 6.605 8.599 11.86z" />
      <circle cx="38" cy="23" r="1" />
      <circle cx="26" cy="23" r="1" />
      <path d="m36 32h-2c0 1.103-.897 2-2 2s-2-.897-2-2h-2c0 2.206 1.794 4 4 4s4-1.794 4-4z" />
    </SvgIcon>
  );
}
