// import path, { isAbsolute } from 'path';
import { useMemo } from "react";
import { BASE_URL } from "../../../constants/appConstants";
import makeStyles from "@material-ui/styles/makeStyles";
import { joinPath } from "../../../services/utils";
import noUserImage from "./no_user_image.svg";
import { EMPTY_STRING, isNotEmptyImagePath } from "../../../services/utils";

const useStyles = makeStyles({
  licence: {
    fontSize: "10px",
    marginLeft: "4px",
  },
  image: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    padding: 4,
  },
});

export default function NoUserImage(props) {
  const classes = useStyles();

  const {
    noImageUrl = EMPTY_STRING,
    altText = "Nema slike korisnika",
    width = 240,
    height = 240,
  } = props;

  const hasNoImageUrl = isNotEmptyImagePath(noImageUrl);
  const realPath = useMemo(
    () => (hasNoImageUrl ? joinPath(BASE_URL, noImageUrl) : noUserImage),
    [noImageUrl, hasNoImageUrl],
  );

  return (
    <>
      <img
        className={classes.image}
        src={realPath}
        width={width}
        height={height}
        alt={altText}
      />
      <div className={classes.licence}>
        Icons made by
        <a
          href="https://www.flaticon.com/authors/pixel-perfect"
          title="Pixel perfect"
        >
          Pixel perfect
        </a>{" "}
        from
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </div>
    </>
  );
}
