import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      //main : "#e6e600"
      //main : '#ffea00'    // this one
      //main : yellow[500]
      // main: '#3194da'   //underit blue color latest
      main: "#CA340D",
    },
    secondary: {
      //main : '#fcba03'
      // main : "#e6e600",
      // main: '#3194da' underit color latest
      main: "#F04E23",
      //main : '#103b5a'
      //main : 'rgb(188, 194, 69)'
      //main : 'hsl(118, 73%, 37%)'
    },
  },
});
