import React, { useEffect } from "react";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import { Line } from "react-chartjs-2";

const Cash1nCash2 = () => {
  const [test, setTest] = React.useState({
    datasets: [],
    labels: [],
    options: {
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: true,
          align: "center",
          anchor: "center",
          color: "white",
        },
      },
    },
  });
  const startDate = new Date("1/1/2021");
  const endDate = new Date();

  const loadFinanceTrafficBranches = async () => {
    const tempBody = {
      start: startDate.toLocaleDateString("sr-RS"),
      end: endDate.toLocaleDateString("sr-RS"),
      branches: [1, 2],
      insurance_companies: [1, 2],
      resolution: "months",
    };

    const result = await getAxiosInstance()
      .get(`/v1/cash/`, { ...tempBody })
      .then((res) => res.data)
      .catch((err) => console.log(err));

    const cash1Data = result.cash1Amounts?.reduce((acc, curr) => {
      const date = new Date(curr.date);
      const month = date.toLocaleString("default", {
        month: "numeric",
        year: "numeric",
      });
      acc[month] = acc[month] || 0;
      acc[month] += curr.amount;
      return acc;
    }, {});

    const cash2Data = result.cash2Amounts?.reduce((acc, curr) => {
      const date = new Date(curr.date);
      const month = date.toLocaleString("default", {
        month: "numeric",
        year: "numeric",
      });
      acc[month] = acc[month] || 0;
      acc[month] += curr.amount;
      return acc;
    }, {});

    const labels = Object.keys(cash1Data);

    const datasets = [
      {
        label: "Keš 1",
        data: Object.values(cash1Data),
        fill: false,
        lineTension: 0.2,
        pointBorderWidth: 10,
        borderColor: "#101820FF",
        backgroundColor: "#101820FF",
      },
      {
        label: "Keš 2",
        data: Object.values(cash2Data),
        fill: false,
        lineTension: 0.2,
        pointBorderWidth: 10,
        borderColor: "#FEE715FF",
        backgroundColor: "#FEE715FF",
      },
    ];

    const chartData = {
      labels,
      datasets,
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: true,
            align: "center",
            anchor: "center",
            color: "white",
          },
        },
      },
    };

    setTest(chartData);
  };

  useEffect(() => {
    loadFinanceTrafficBranches();
  }, []);

  return (
    <div
      style={{
        postion: "relative",
        margin: "auto",
        height: "40vh",
        width: "40vw",
      }}
    >
      <Line data={test} options={test?.options} />
    </div>
  );
};

export default Cash1nCash2;
