import USER_TYPES from "./userTypes";
import {
  S_ATTR_WORK_POS,
  S_ATTR_COMP_BRANCH,
  S_ATTR_COMP_LINE,
  S_ATTR_EMPLOYEE,
} from "./serverContract";
import { camelCasedObjValues } from "../services/utils";

export const BASE_URL = `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`;
export const STORAGE_KEYS = {
  TOKEN: "token",
  REFRESH_TOKEN: "refreshToken",
  REFRESH_TOKEN_EXPIRES_IN: "refreshTokenExpiresIn",
  USER_ID: "userId",
};

export const USER = {
  FULL_NAME: "fullName",
  USER_NAME: "userName",
  OLD_PASSWORD: "oldPassword",
  NEW_PASSWORD: "newPassword",
};

/**@type S_ATTR_EMPLOYEE */
export const EMPLOYEE = camelCasedObjValues(S_ATTR_EMPLOYEE);
/**@type S_ATTR_WORK_POS */
export const WORKING_POSITION = camelCasedObjValues(S_ATTR_WORK_POS);
/**@type S_ATTR_COMP_BRANCH */
export const COMPANY_BRANCH = camelCasedObjValues(S_ATTR_COMP_BRANCH);
/**@type S_ATTR_COMP_LINE */
export const COMPANY_LINE = camelCasedObjValues(S_ATTR_COMP_LINE);

export const CLIENT_TYPES = {
  PHYSICAL_PERSON: 1,
  LEGAL_PERSON: 2,
};

export const profileSubmitFields = [];
