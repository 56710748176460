import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import tableIcons from "../../constants/tableIcons";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import UserDialog from "./UserDialog";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { unknownErrorThanActionHandlerFactory } from "../../services/utils";
import {
  uploadProfileImage,
  remoteUpdateUser,
} from "../../data-source/profileDataSource";
import { LinearProgress } from "@material-ui/core";

export default function UsersTable(props) {
  const [allUsers, setAllUsers] = React.useState([]);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const tableRef = React.useRef();
  const [openModal, setModalOpen] = useState(false);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  useEffect(() => {
    loadAllUsers();
  }, []);

  const dispatch = useDispatch();
  const errorHandlerThanAction = unknownErrorThanActionHandlerFactory(dispatch);

  const loadAllUsers = async () => {
    setModalOpen(true);
    let result = await getAxiosInstance()
      .get(`/v1/users`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setAllUsers(result);
    setModalOpen(false);
  };

  const TableColumns = [
    { title: "Ime i prezime", field: "full_name" },
    { title: "Email", field: "user_name" },
    { title: "Mobilni telefon", field: "cell_phone" },
    { title: "Fiksni telefon", field: "landing_phone" },
  ];
  const submitHandler = async (data) => {
    return await getAxiosInstance()
      .post("v1/users", {
        full_name: data.full_name.trim(),
        user_name: data.user_name.trim(),
        password: "Test123456789!!!",
        user_type: 1,
        cell_phone: data.cell_phone,
        landing_phone: data.landing_phone,
        working_position_id_fk: 1,
        company_branch_id_fk: 1,
        company_line_id_fk: 1,
        active: 1,
      })
      .then((result) => {
        if (data["profile_image"].length > 0) {
          const file = data["profile_image"][0];

          const userId = result.data.user_id;

          return uploadProfileImage(file, result.data.user_id).then(
            (uploadImageResult) => remoteUpdateUser(userId, uploadImageResult),
          );
        }
      })
      .then(() => {
        handleDialogClose();
        //tableRef.current.onQueryChange();
        loadAllUsers();
      })
      .catch(errorHandlerThanAction);
  };

  const updateUserHandler = async (newData) => {
    getAxiosInstance()
      .patch(`v1/users/${newData.user_id}`, {
        full_name: newData.full_name,
        user_name: newData.user_name,
        cell_phone: newData.cell_phone,
        landing_phone: newData.landing_phone,
      })
      .then((res) => {
        loadAllUsers();
      })
      .catch(errorHandlerThanAction);
  };

  const deleteUserHandler = async (newData) => {
    getAxiosInstance()
      .delete(`v1/users/${newData.user_id}`)
      .then((res) => {
        loadAllUsers();
      })
      .catch(errorHandlerThanAction);
  };

  return (
    <>
      {openModal && <LinearProgress style={{ marginBottom: "10px" }} />}
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsDialogOpen(true);
          }}
        >
          Dodaj korisnika
        </Button>
        <MaterialTable
          tableRef={tableRef}
          columns={TableColumns}
          data={allUsers}
          title="Korisnici"
          icons={tableIcons}
          localization={{
            header: { actions: "Akcija" },
            toolbar: { searchPlaceholder: "Pretraži" },
            body: {
              editTooltip: "Izmeni",
              editRow: {
                saveTooltip: "Sačuvaj",
                cancelTooltip: "Poništi",
                deleteText:
                  "Da li ste sigurni da želite da obrišete korisnika?",
              },
              deleteTooltip: "Obrisi",
              deleteRow: { saveTooltip: "Sačuvaj", cancelTooltip: "Poništi" },
            },
          }}
          options={{
            headerStyle: { backgroundColor: "#D1D1D1", fontWeight: "bold" },
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              updateUserHandler(newData, oldData),
            onRowDelete: (newData, oldData) =>
              deleteUserHandler(newData, oldData),
          }}
        />
        {isDialogOpen ? (
          <UserDialog
            title={"Dodaj korisnika"}
            isOpen={isDialogOpen}
            handleDialogClose={handleDialogClose}
            submitHandler={submitHandler}
          />
        ) : null}
      </div>
    </>
  );
}
