import drawerItems, {
  userDrawerItemsIds,
  adminDrawerItemsIds,
  ITEM_TYPE,
} from "../constants/drawerItemsConstants";
import { USER_TYPES } from "../constants/userTypes";

function drawerItemComparator(drawerItem1, drawerItem2) {
  return drawerItem1.position - drawerItem2.position;
}

function filterDrawerIds(argDrawerIds, argDrawerItems) {
  const tempNestedDrawerIds = argDrawerIds.filter(
    (lmdDrawerId) => typeof lmdDrawerId === "object" && "id" in lmdDrawerId,
  );

  const tempDrawerItems = argDrawerItems
    .filter((lmdDrawerItem) => argDrawerIds.includes(lmdDrawerItem.itemId))
    .concat(
      argDrawerItems
        .filter(
          (lmdItem2) =>
            lmdItem2.type === ITEM_TYPE.NESTED_ITEM &&
            tempNestedDrawerIds.some(
              (lmdDrawerId) => lmdDrawerId.id === lmdItem2.itemId,
            ),
        )
        .map((lmdItem2) => ({
          ...lmdItem2,
          items: lmdItem2.items.filter((lmdNestedItem) =>
            tempNestedDrawerIds
              .find(
                (lmdNestedDrawerId) => lmdItem2.itemId === lmdNestedDrawerId.id,
              )
              .item_ids.some(
                (lmdNestedDrawerItemId) =>
                  lmdNestedItem.itemId === lmdNestedDrawerItemId,
              ),
          ),
        }))
        .sort(drawerItemComparator),
    )
    .sort(drawerItemComparator);

  return tempDrawerItems;
}
const _userDrawerItems = filterDrawerIds(userDrawerItemsIds, drawerItems);

const _adminDrawerItems = filterDrawerIds(adminDrawerItemsIds, drawerItems);

export function getDrawerItemsByUserType(argUserType) {
  if (argUserType === USER_TYPES.USER) {
    return _userDrawerItems;
  } else if (argUserType === USER_TYPES.ADMIN) {
    return _adminDrawerItems;
  } else {
    throw new Error("User type not valid - drawer items");
  }
}
