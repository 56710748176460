const SERVER_ATTRIBUTES = {
  USER: {
    USER_ID: "user_id",
    FULL_NAME: "full_name",
    USER_NAME: "user_name",
    PASSWORD: "password",
    USER_TYPE: "user_type",
  },
  CLIENT: {
    CLIENT_ID: "client_id",
    EMAIL: "email",
    PHONE_NUMBER: "phone_number",
    CLIENT_TYPE: "client_type",
  },
  PHYSICAL_PERSON: {
    PHYSICAL_CLIENT_ID: "physical_client_id",
    CLIENT_ID_FK: "client_id_fk",
    FULL_NAME: "full_name",
  },
  WORKING_POSITION: {
    WORKING_POSITION_ID: "working_position_id",
    NAME: "name",
    DESCRIPTION: "description",
  },
  COMPANY_BRANCH: {
    COMPANY_BRANCH_ID: "company_branch_id",
    NAME: "name",
    TOWN: "town",
    ADDRESS: "address",
  },
  COMPANY_LINE: {
    COMPANY_LINE_ID: "company_line_id",
    NAME: "name",
    COMPANY_BRANCH_ID_FK: "company_branch_id_fk",
  },
  EMPLOYEE: {
    EMPLOYEE_ID: "employee_id",
    FULL_NAME: "full_name",
    PHONE_NUMBER: "phone_number",
    WORKING_POSITION_ID_FK: "working_position_id_fk",
    COMPANY_LINE_ID_FK: "company_line_id_fk",
    COMPANY_BRANCH_ID_FK: "company_branch_id_fk",
  },
};
const SERVER_ADDITIONAL_ATTRIBUTES = {
  USER: {
    OLD_PASSWORD: "old_password",
    NEW_PASSWORD: "new_password",
  },
  PASSWORD_RESET: {
    NEW_PASSWORD: "new_password",
    NEW_AGAIN_PASSWORD: "new_again_password",
  },
};

const SERVER_MODELS_NAMES = {
  USER: "users",
  CLIENT: "client",
  PHYSICAL_PERSON: "physical_person",
  WORKING_POSITION: "working_positions",
  COMPANY_BRANCH: "company_branches",
  COMPANY_LINE: "company_lines",
  EMPLOYEE: "employees",
};

const QUERY_PARAMS = {
  AUTH: {
    RESET_PASSWORD_TOKEN: "reset_password_token",
  },
};

export { SERVER_ATTRIBUTES as S_ATTR, SERVER_MODELS_NAMES as S_MODELS };
export { SERVER_ADDITIONAL_ATTRIBUTES as S_AD_ATTR };
export const {
  USER: S_ATTR_USER,
  CLIENT: S_ATTR_CLIENT,
  PHYSICAL_PERSON: S_ATTR_PHYSICAL_PERSON,
  WORKING_POSITION: S_ATTR_WORK_POS,
  COMPANY_BRANCH: S_ATTR_COMP_BRANCH,
  COMPANY_LINE: S_ATTR_COMP_LINE,
  EMPLOYEE: S_ATTR_EMPLOYEE,
} = SERVER_ATTRIBUTES;
export const { AUTH: QUERY_PARAMS_AUTH } = QUERY_PARAMS;
