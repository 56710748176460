import React, { useRef, useState } from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useForm, Controller } from "react-hook-form";
import ProfileImage from "../common/ProflieImage";
import Container from "@material-ui/core/Container";
import { EMPTY_STRING } from "../../services/utils";

const fileInputStyle = {
  display: "none",
};
/*
const profileImageStyle = {
  marginLeft : 0,
  marginRight : 0
}
*/
const fileInputId = "file-input-profile-image-id";

const AddUser = (props) => {
  const { isOpen = false } = props;
  const { register, handleSubmit, control, watch, setValue, errors } =
    useForm();

  //const errorHanlderThanAction = useErrorHook();

  const onError = (errors, e) =>
    console.log("Error handler (use form", errors, e);

  return (
    <div>
      <Dialog
        onClose={props.handleDialogClose}
        open={isOpen}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit(props.submitHandler, onError)}>
          <DialogTitle style={{ textAlign: "center" }}>
            {props.title}
          </DialogTitle>
          <DialogContent>
            <ProfileImage
              thisUser={false}
              uniqueFileInputId={fileInputId}
              inputFileProps={{
                ref: register,
                name: "profile_image",
              }}
            />

            <TextField
              error={!!errors["full_name"]}
              helperText={errors["full_name"] && "Polje je obavezno"}
              inputRef={register({ required: true })}
              autoFocus
              margin="dense"
              name="full_name"
              label="Ime i prezime"
              type="text"
              fullWidth
            />
            <TextField
              error={!!errors["user_name"]}
              helperText={errors["user_name"] && "Polje je obavezno"}
              inputRef={register({ required: true })}
              margin="dense"
              name="user_name"
              label="Email"
              type="text"
              fullWidth
            />
            <TextField
              inputRef={register}
              margin="dense"
              name="cell_phone"
              label="Mobilni telefon"
              type="text"
              fullWidth
            />
            <TextField
              inputRef={register}
              margin="dense"
              name="landing_phone"
              label="Fiksni telefon"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={props.handleDialogClose}
            >
              OTKAŽI
            </Button>
            <Button
              onClick={() => document.getElementById("hidden").click()}
              variant="contained"
              color="primary"
            >
              SAČUVAJ
            </Button>
            <input id="hidden" hidden type="submit" />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddUser;
