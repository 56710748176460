import React, { useEffect } from "react";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import { Line } from "react-chartjs-2";
import plugins from "chartjs-plugin-datalabels";
import randomColor from "randomcolor";
import { getColor } from "../../services/utils";

function FinanceTrafficIspostava(props) {
  let date = new Date();
  date = date.toLocaleDateString("sr-RS");
  const [test, setTest] = React.useState({
    datasets: [],
    labels: [],
    options: {
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: true,
          align: "center",
          anchor: "center",
          color: "white",
        },
      },
    },
  });

  let endDate = new Date();
  endDate = endDate.setFullYear(endDate.getFullYear() - 1);

  ///v1/statistics/composed/registrations/clients

  const loadFinanceTrafficBranches = async () => {
    /*
    const tempBody = {
      start: endDate,
      end: Date.now(),
      lines: [1,2,3,4,5,6],
      branches: [1,2],
      insurance_companies: [1,2],
      client_types: [1,2] 
    }
    console.log(tempBody)
    */
    //// Test body parameters
    const tempBody = {
      start: new Date("1/1/2021").getTime(),
      end: new Date().getTime(),
      lines: [1, 2, 3, 4, 5, 6],
      branches: [1, 2],
      insurance_companies: [1, 2],
      resolution: "months",
    };

    let result = await getAxiosInstance()
      .post(`/v1/statistics/composed/registrations/branches`, { ...tempBody })
      .then((res) => res.data)
      .catch((err) => console.log(err));

    result = {
      labels: Object.entries(result)[0][1]?.labels.flat(),
      datasets: [
        ...Object.entries(result).map(([branchName, branchValue], index) => {
          const branchColor = getColor(index);
          return {
            label: branchName,
            data: branchValue.data.flat(),
            //backgroundColor: Math.floor(Math.random()*16777215).toString(16)
            backgroundColor: index === 0 ? "green" : "blue",
            fill: false,
            lineTension: 0.2,
            pointBorderWidth: 10,
            borderColor: branchColor, //'#3194DA',
            backgroundColor: branchColor, //'#3194DA',
          };
        }),
      ],
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: true,
            align: "center",
            anchor: "center",
            color: "white",
          },
        },
      },
    };
    console.log("Cuveni result", result);

    setTest(result);
  };

  useEffect(() => {
    loadFinanceTrafficBranches();
  }, []);
  // console.log(test)

  return (
    <div
      style={{
        postion: "relative",
        margin: "auto",
        height: "40vh",
        width: "40vw",
      }}
    >
      {/* <p>Dnevna statistika: {date}</p> */}
      <Line data={test != "" ? test : {}} options={test?.options ?? []} />
    </div>
  );
}

export default FinanceTrafficIspostava;
