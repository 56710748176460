import MaterialList from "../common/MaterialList";
import React, { useEffect } from "react";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import { useDispatch } from "react-redux";
import { unknownErrorThanActionHandlerFactory } from "../../services/utils";

export default function VehicleTypes() {
  const columns = [
    {
      title: "Naziv",
      field: "name",
    },
  ];

  const dispatch = useDispatch();
  const errorHandlerThanAction = unknownErrorThanActionHandlerFactory(dispatch);
  const [vehicleTypes, setVehicleTypes] = React.useState([]);

  const loadVehicleTypes = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/vehicletypes`)
      .then((res) => res.data)
      .then((data) => setVehicleTypes(data))
      .catch(errorHandlerThanAction);
  };
  useEffect(() => {
    loadVehicleTypes();
  }, []);

  const addVehicleTypes = async (data) => {
    const { name = null } = data;

    if (name == null || name.trim().length === 0) {
      errorHandlerThanAction("Polje Naziv je obavezno");
      throw new Error("Greska u polju Naziv");
    }
    await getAxiosInstance()
      .post(`/v1/vehicletypes`, {
        name: data.name,
      })
      .then(() => {
        loadVehicleTypes();
      })
      .catch(errorHandlerThanAction);
  };

  const deleteVehicleTypes = async (data) => {
    await getAxiosInstance()
      .delete(`/v1/vehicletypes/${data.vehicle_type_id}`)
      .then(() => {
        loadVehicleTypes();
      })
      .catch(errorHandlerThanAction);
  };

  const updateVehicleTypes = async (data) => {
    await getAxiosInstance()
      .patch(`/v1/vehicletypes/${data.vehicle_type_id}`, {
        name: data.name,
      })
      .then(() => {
        loadVehicleTypes();
      })
      .catch(errorHandlerThanAction);
  };

  return (
    <MaterialList
      columns={columns}
      data={vehicleTypes}
      title="Tipovi Vozila"
      localization={{
        buttons: {
          addText: "Dodaj",
          cancelText: "Poništi",
          updateText: "Izmeni",
        },
      }}
      editable={{
        onRowAdd: (newData) => addVehicleTypes(newData),
        onRowUpdate: (newData) => updateVehicleTypes(newData),
        onRowDelete: (newData) => deleteVehicleTypes(newData),
      }}
      options={{
        listStyle: {
          width: "70ch",
          marginBottom: 2,
        },
      }}
    />
  );
}
