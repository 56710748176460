import { getAxiosInstance } from "../data-source/axiosProvider";
import {
  mapObjectArrayToCamelCase,
  unknownErrorHandlerFactory,
  mapKeysToSnakeCase,
  removeNullValuesObjArray,
  createInvertedObjectKeyMap,
  splitToObjectsByKeys,
  promiseLogger,
  titledPromiseLogger,
  isNilOrEmpty,
  replaceEmptyWithNullInObj,
} from "../services/utils";
import {
  S_ATTR_EMPLOYEE,
  S_ATTR_COMP_BRANCH,
  S_ATTR_COMP_LINE,
  S_MODELS,
} from "../constants/serverContract";
import { CLIENT_TYPES } from "../constants/appConstants";
import _ from "lodash";
import {
  WORKING_POSITION,
  COMPANY_BRANCH,
  COMPANY_LINE,
  EMPLOYEE,
} from "../constants/appConstants";

const invertedObjKeyMap = createInvertedObjectKeyMap({
  [S_MODELS.EMPLOYEE]: Object.values(S_ATTR_EMPLOYEE),
  [S_MODELS.COMPANY_BRANCH]: Object.values(S_ATTR_COMP_BRANCH),
  [S_MODELS.COMPANY_LINE]: Object.values(S_ATTR_COMP_LINE),
  [S_MODELS.COMPANY_BRANCH]: Object.values(S_ATTR_COMP_BRANCH),
});

export async function fetchEmpoloyees() {
  return await getAxiosInstance()
    .get("/v1/employees")
    .then((result) => result.data)
    .then((data) =>
      data.map(
        ({
          EmployeeWorkingPosition,
          EmployeeCompanyBranch,
          EmployeeCompanyLine,
          ...rest
        }) => ({
          ...rest,
        }),
      ),
    )
    .then(mapObjectArrayToCamelCase)
    .then(removeNullValuesObjArray)
    .then(promiseLogger);
}

export async function updateEmployee(employee) {
  const employeId = employee[EMPLOYEE.EMPLOYEE_ID] || null;

  return await getAxiosInstance().patch(
    `/v1/employees/${employeId}`,
    mapKeysToSnakeCase(
      replaceEmptyWithNullInObj(
        _.omit(employee, ["createdAt", EMPLOYEE.EMPLOYEE_ID]),
      ),
    ),
  );
}

export async function deleteEmployee(employee) {
  const { employeeId } = employee;
  return await getAxiosInstance().delete(`/v1/employees/${employeeId}`);
}

export async function createEmployee(employee) {
  return await getAxiosInstance().post(
    `/v1/employees`,
    mapKeysToSnakeCase(_.omitBy(employee, isNilOrEmpty)),
  );
}
