import { getAxiosInstance } from "./axiosProvider";
import {
  mapObjectArrayToCamelCase,
  unknownErrorHandlerFactory,
  mapKeysToSnakeCase,
  removeNullValuesObjArray,
  createInvertedObjectKeyMap,
  splitToObjectsByKeys,
  promiseLogger,
} from "../services/utils";
import {
  S_ATTR_EMPLOYEE,
  S_ATTR_COMP_BRANCH,
  S_ATTR_COMP_LINE,
  S_MODELS,
} from "../constants/serverContract";
import { CLIENT_TYPES } from "../constants/appConstants";
import _ from "lodash";
import {
  WORKING_POSITION,
  COMPANY_BRANCH,
  COMPANY_LINE,
  EMPLOYEE,
} from "../constants/appConstants";

export async function fetchCompanyBranches() {
  return await getAxiosInstance()
    .get("/v1/companybranches")
    .then((result) => result.data)
    .then(mapObjectArrayToCamelCase)
    .then(removeNullValuesObjArray);
}

export async function fetchCompanyLines() {
  return await getAxiosInstance()
    .get("/v1/companylines")
    .then((result) => result.data)
    .then((data) =>
      data.map(({ CompanyBranch, ...rest }) => ({
        ...rest,
      })),
    )
    .then(mapObjectArrayToCamelCase)
    .then(removeNullValuesObjArray);
  //.then( promiseLogger )
}
