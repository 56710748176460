import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  fetchPhysicalPeople,
  createPhysicalPerson,
  deletePhysicalPerson,
  updatePhysicalPeople,
} from "../../../data-source/physicalPeopleDataSource";
import {
  removeNullValuesObj,
  unknownErrorHandlerFactory,
} from "../../../services/utils";
import tableIcons from "../../../constants/tableIcons";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import _, { isEqual as _isEqual } from "lodash";
import { isPhoneNumber } from "../../../services/validationUtils";
import { LinearProgress } from "@material-ui/core";

const alteredTableIcons = {
  ...tableIcons,
  Add: (props) => (
    <button
      data-special-id-selector={"add-physical-person-btn"}
      style={{ display: "none" }}
    ></button>
  ),
};

const onAddNewPhysicalPersonClick = (event) => {
  document
    .querySelector("button[data-special-id-selector='add-physical-person-btn']")
    .click();
};

export default function PhysicalPeopleTable(props) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  const errorHandler = useCallback(unknownErrorHandlerFactory(dispatch), [
    dispatch,
  ]);

  const loadPhysicalPeople = useCallback(() => {
    setModalOpen(true);
    fetchPhysicalPeople()
      .then((data) => {
        setData(data);
        setModalOpen(false);
      })
      .catch(errorHandler);
  }, [setData, errorHandler]);

  useEffect(() => {
    loadPhysicalPeople();
  }, []);

  function validateRow(rowData) {
    const { fullName = null, email = null, phoneNumber = null } = rowData;

    if (fullName == null || isEmpty(fullName, { ignore_whitespace: true })) {
      errorHandler("Polje ime i prezime je obavezno");
      throw new Error("Greska validacije");
    }

    if (
      (email == null || isEmpty(email, { ignore_whitespace: true })) &&
      (phoneNumber == null || isEmpty(phoneNumber, { ignore_whitespace: true }))
    ) {
      errorHandler("Potrebno je navesti email ili broj telefona");
      throw new Error("Greska validacije");
    }
  }

  function sanitizeRow(rowData) {
    return {
      ...rowData,
      email:
        rowData.email != null && rowData.email.trim() === ""
          ? null
          : rowData.email,
      phoneNumber:
        rowData.phoneNumber != null
          ? rowData.phoneNumber.trim()
          : rowData.phoneNumber,
    };
  }

  async function onRowAddHandler(rowData) {
    validateRow(rowData);

    return await createPhysicalPerson(_.omitBy(sanitizeRow(rowData), _.isNull))
      .then(loadPhysicalPeople)
      .catch(errorHandler);
  }

  async function onRowUpdateHandler(newRowData, oldRowData) {
    validateRow(newRowData);
    return await updatePhysicalPeople(sanitizeRow(newRowData))
      .then(loadPhysicalPeople)
      .catch(errorHandler);
  }

  async function onRowDeleteHandler(oldRowData) {
    const { physicalPersonId, clientId } = oldRowData;

    return await deletePhysicalPerson(physicalPersonId, clientId)
      .then(loadPhysicalPeople)
      .catch(errorHandler);
  }

  const columns = [
    {
      title: "Ime i prezime",
      field: "fullName",
      validate: (rowData) =>
        rowData.fullName == null ||
        !isEmpty(rowData.fullName, { ignore_whitespace: true })
          ? true
          : { isValid: false, helperText: "Polje je obavezno" },
    },
    {
      title: "Email",
      field: "email",
      validate: (rowData) =>
        rowData.email == null ||
        isEmail(rowData.email) ||
        isEmpty(rowData.email, { ignore_whitespace: false })
          ? true
          : { isValid: false, helperText: "Neispravan format" },
    },
    {
      title: "Broj telefona",
      field: "phoneNumber",
      validate: (rowData) =>
        rowData.phoneNumber == null ||
        isEmpty(rowData.phoneNumber, { ignore_whitespace: false }) ||
        isPhoneNumber(rowData.phoneNumber)
          ? true
          : { isValid: false, helperText: "Neispravan format" },
    },
    {
      title: "Kreiran",
      field: "createdAt",
      hidden: true,
      defaultSort: "desc",
    },
  ];

  return (
    <>
      {modalOpen && <LinearProgress style={{ marginBottom: "10px" }} />}
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={onAddNewPhysicalPersonClick}
        >
          Dodaj fizičko lice
        </Button>
        <MaterialTable
          icons={alteredTableIcons}
          title="Fizička lica"
          columns={columns}
          data={data}
          editable={{
            onRowAdd: onRowAddHandler,
            onRowUpdate: onRowUpdateHandler,
            onRowDelete: onRowDeleteHandler,
          }}
          localization={{
            header: { actions: "Akcije" },
            toolbar: { searchPlaceholder: "Pretraži" },

            body: {
              emptyDataSourceMessage: "Nema fizičkih lica za prikazivanje",
              addTooltip: "",
              editTooltip: "Izmeni fizičko lice",
              deleteTooltip: "Obriši fizičko lice",
              editRow: {
                deleteText:
                  "Da li ste sigurni da želite da obrišete fizičko lice",
                cancelTooltip: "Poništi",
                saveTooltip: "Snimi",
              },
            },
            toolbar: {
              searchTooltip: "Pretraži fizička lica",
              searchPlaceholder: "Pretraži",
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} od {count}",
              labelRowsSelect: "redova",
              firstTooltip: "Prva stranica",
              lastTooltip: "Zadnja stranica",
              nextTooltip: "Sledeća stranica",
              previousTooltip: "Predhodna stranica",
            },
          }}
          options={{
            headerStyle: { backgroundColor: "#D1D1D1", fontWeight: "bold" },
            addRowPosition: "first",
            pageSize: 10,
          }}
        ></MaterialTable>
      </div>
    </>
  );
}
