import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { useState } from "react";
import Button from "@material-ui/core/Button";

export default function MessageDialog(props) {
  const {
    title = null,
    message = null,
    dialogOpen,
    setDialogOpen,
    button1Text,
    button1Handler,
  } = props;

  const closeDialogHandler = () => {
    setDialogOpen(false);
  };

  console.log("Message dialog rerendering...");

  return (
    dialogOpen && (
      <Dialog open={dialogOpen} onClose={closeDialogHandler}>
        {title && <DialogTitle> {title} </DialogTitle>}
        {message && (
          <DialogContent>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={button1Handler} color="primary">
            {button1Text}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}
