import Materialtable from "material-table";
import Button from "@material-ui/core/Button";
import React, { useState, useEffect } from "react";
import tableIcons from "../../constants/tableIcons";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import { useDispatch } from "react-redux";
import { unknownErrorThanActionHandlerFactory } from "../../services/utils";

export default function CompanyBranches() {
  const [allCompaniesBranches, setAllCompanies] = useState([]);
  const tableRef = React.useRef();

  const dispatch = useDispatch();
  const errorHandlerThanAction = unknownErrorThanActionHandlerFactory(dispatch);

  const loadCompanyBranchesHandler = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/companybranches`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setAllCompanies(result);
  };
  useEffect(() => {
    loadCompanyBranchesHandler();
  }, []);

  const defaultColumns = [
    { title: "Ispostave", field: "name" },
    { title: "Grad", field: "town" },
    { title: "Adresa", field: "address" },
  ];

  const addCompaniesBranchesHandler = async (data) => {
    const { name = null, town = null, address = null } = data;

    if (name == null || name.trim().length === 0) {
      errorHandlerThanAction("Polje ispostave je obavezno");
      throw new Error("Greska u polju ime kompanije");
    }
    if (town == null || town.trim().length === 0) {
      errorHandlerThanAction("Polje grad je obavezno");
      throw new Error("Greska u polju grad");
    }
    if (address == null || address.trim().length === 0) {
      errorHandlerThanAction("Polje adresa je obavezno");
      throw new Error("Greska u polju adresa");
    }
    await getAxiosInstance()
      .post(`/v1/companybranches`, {
        name: data.name,
        town: data.town,
        address: data.address,
      })
      .then(() => {
        loadCompanyBranchesHandler();
      })
      .catch(errorHandlerThanAction);
  };

  const updateCompanyBranchesHandler = async (newData) => {
    const { name = null, town = null, address = null } = newData;

    if (name == null || name.trim().length === 0) {
      errorHandlerThanAction("Polje Ime kompanije je obavezno");
      throw new Error("Greska u polju ime kompanije");
    }
    if (town == null || town.trim().length === 0) {
      errorHandlerThanAction("Polje Grad je obavezno");
      throw new Error("Greska u polju grad");
    }
    if (address == null || address.trim().length === 0) {
      errorHandlerThanAction("Polje Adresa je obavezno");
      throw new Error("Greska u polju adresa");
    }

    await getAxiosInstance()
      .patch(`/v1/companybranches/${newData.company_branch_id}`, {
        name: newData.name,
        town: newData.town,
        address: newData.address,
      })
      .then(() => {
        loadCompanyBranchesHandler();
      });
  };

  const deleteCompanyBranchesHanlder = async (newData) => {
    getAxiosInstance()
      .delete(`/v1/companybranches/${newData.company_branch_id}`)
      .then(() => {
        loadCompanyBranchesHandler();
      });
  };

  const onClickHandler = (event) => {
    document
      .querySelector("[data-mycustomid='add-icon-handler']")
      .parentNode.click();
  };

  return (
    <div>
      <Button color="primary" variant="contained" onClick={onClickHandler}>
        Dodaj Novu Ispostavu
      </Button>
      <Materialtable
        tableRef={tableRef}
        title="Ispostave Kompanije"
        icons={{
          ...tableIcons,
          Add: (props) => (
            <button
              style={{ display: "none" }}
              disabled
              data-mycustomid={"add-icon-handler"}
            ></button>
          ),
        }}
        columns={defaultColumns}
        data={allCompaniesBranches}
        localization={{
          header: { actions: "Akcije" },
          toolbar: { searchPlaceholder: "Pretraži" },
          body: {
            emptyDataSourceMessage: "Nema ispostava za prikazivanje",
            addToolTip: "Dodaj Ispostavu",
            deleteToolTip: "Obriši Ispostavu",
            editTooltip: "Izmeni Ispostavu",
            editRow: {
              deleteText: "Da li ste sigurni da želite da obrišete ispostavu",
              cancelTooltip: "Odustani",
              saveTooltip: "Snimi",
            },
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} od {count}",
            labelRowsSelect: "redova",
            firstTooltip: "Prva stranica",
            lastTooltip: "Zadnja stranica",
            nextTooltip: "Sledeća stranica",
            previousTooltip: "Predhodna stranica",
          },
        }}
        editable={{
          onRowAdd: (newData, oldData) =>
            addCompaniesBranchesHandler(newData, oldData),
          onRowUpdate: (newData, oldData) =>
            updateCompanyBranchesHandler(newData, oldData),
          onRowDelete: (newData, oldData) =>
            deleteCompanyBranchesHanlder(newData, oldData),
        }}
        options={{
          headerStyle: { backgroundColor: "#D1D1D1", fontWeight: "bold" },
          addRowPosition: "first",
          pageSize: 10,
        }}
      />
    </div>
  );
}
