import isEmpty from "validator/lib/isEmpty";
//const phoneNumber = /^(\d+( ?|\/?)?)+(?<! )$/;
const phoneNumber = /^(\d+( ?|\/?)?)+$/;

export function isPhoneNumber(argText) {
  return phoneNumber.test(argText);
}

export function weakNotEmptyFieldValidationFactory(rowKey) {
  return (rowData) =>
    rowData[rowKey] == null || rowData[rowKey] === ""
      ? true
      : isEmpty(rowData[rowKey], { ignore_whitespace: true })
      ? { isValid: false, helperText: "Polje je obavezno" }
      : true;
}

export function isTotallyEmpty(data) {
  return data == null || isEmpty(data, { ignore_whitespace: true });
}
