const SETTINGS = {
  DRAWER: {
    CUSTOM_ITEM_ACTIVATED: true,
  },
  AUTH: {
    FAKE_DATA: false,
  },
  PROFILE: {
    FAKE_DATA: false,
  },
  LOGOUT_SERVICE: {
    LOGOUT_CHECK_INTERVAL: "15s",
  },
};

export default SETTINGS;
export const {
  AUTH: AUTH_SETTINGS,
  DRAWER: DRAWER_SETTINGS,
  PROFILE,
  LOGOUT_SERVICE: LOGOUT_SERVICE_SETTINGS,
} = SETTINGS;
