import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default function filteredSelectFactory(
  dataToFilter,
  valueKey,
  titleKey,
  filterOptions,
) {
  const { dataForeignKey = null, foreignColumnField = null } =
    filterOptions ?? {};
  return function (props) {
    const filterStrategy = !filterOptions
      ? () => true
      : (dataItem) =>
          dataItem[dataForeignKey] === props.rowData[dataForeignKey];

    const onChangeHandler = (event) => {
      props.onChange(event.target.value);
    };

    return (
      <Select {...props} onChange={onChangeHandler}>
        {dataToFilter.filter(filterStrategy).map((dataItem) => (
          <MenuItem value={dataItem[valueKey]}>{dataItem[titleKey]}</MenuItem>
        ))}
      </Select>
    );
  };
}
