//const isDebug = process.env.NODE_ENV === 'development'

class DebugUtils {
  constructor(isDebug) {
    this.isDebug = isDebug;
    console.log("Is Debug", this.isDebug);
  }

  log(arg) {
    this.isDebug && console.log(arg);
  }

  info(arg) {
    this.isDebug && console.info(arg);
  }

  warn(arg) {
    this.isDebug && console.warn(arg);
  }

  error(arg) {
    this.isDebug && console.error(arg);
  }
}

const debug = new DebugUtils(process.env.NODE_ENV === "development");
//console.log("Is Debug", isDebug);
export default debug;
