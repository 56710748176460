import { getAxiosInstance } from "../data-source/axiosProvider";
import { PROFILE } from "../Settings";
import { mapKeysToCamelCase, mapKeysToSnakeCase } from "../services/utils";
import { userFakeDataSource } from "./fake-data-source/fakeDataSource";

import { promiseLogger } from "../services/utils";

export async function remoteFetchUser(argUserId) {
  if (!PROFILE.FAKE_DATA) {
    return getAxiosInstance()
      .get(`/v1/users/${argUserId}`)
      .then((result) => result.data)
      .then((unwrappedData) => mapKeysToCamelCase(unwrappedData));
  } else {
    return userFakeDataSource.getOne(argUserId).then(mapKeysToCamelCase);
  }
}

export async function remoteUpdateUser(argUserId, argUserObj) {
  if (!PROFILE.FAKE_DATA) {
    return getAxiosInstance().patch(
      `/v1/users/${argUserId}`,
      mapKeysToSnakeCase(argUserObj),
    );
  } else {
    return userFakeDataSource.patchOne(argUserId, argUserObj);
  }
}

export async function uploadProfileImage(file, userId) {
  const formData = new FormData();
  const preparedFileName = `${String(userId)}:${file.name}`;

  formData.append("profile_image", file, preparedFileName);

  return getAxiosInstance()
    .post("v1/users/profileimage", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => result.data)
    .then(mapKeysToCamelCase);
}
