import MaterialTable from "material-table";
import React, { useEffect } from "react";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import tableIcons from "../../constants/tableIcons";
import { useDispatch } from "react-redux";
import { unknownErrorThanActionHandlerFactory } from "../../services/utils";
import Button from "@material-ui/core/Button";

const emailValidation = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export default function InsuranceCompanies() {
  const [allCompanies, setAllCompanies] = React.useState([]);
  const tableRef = React.useRef();

  const loadAllCompanies = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/insurancecompanies`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setAllCompanies(result);
  };
  useEffect(() => {
    loadAllCompanies();
  }, []);

  const TableColumns = [
    {
      title: "Ime Kompanije",
      field: "name",
      validate: (rowData) =>
        rowData.name === ""
          ? { isValid: false, helperText: "Polje ne može biti prazno" }
          : true,
    },
    {
      title: "Grad",
      field: "town",
      validate: (rowData) =>
        rowData.town === ""
          ? { isValid: false, helperText: "Polje ne može biti prazno" }
          : true,
    },
    {
      title: "Adresa",
      field: "address",
      validate: (rowData) =>
        rowData.address === ""
          ? { isValid: false, helperText: "Polje ne može biti prazno" }
          : true,
    },
    {
      title: "Email",
      field: "email",
      validate: (rowData) => {
        if (
          emailValidation(rowData.email) ||
          rowData.email == null ||
          rowData.email === ""
        ) {
          return true;
        } else {
          return "Neispravan email format";
        }
      },
    },
    { title: "Fiksni telefon", field: "landing_phone" },
  ];

  const dispatch = useDispatch();
  const errorHandlerThanAction = unknownErrorThanActionHandlerFactory(dispatch);

  const updateCompaniesHandler = async (newData) => {
    const { name = null, town = null, address = null } = newData;

    if (name == null || name.trim().length === 0) {
      errorHandlerThanAction("Polje Ime kompanije je obavezno");
      throw new Error("Greska u polju ime kompanije");
    }
    if (town == null || town.trim().length === 0) {
      errorHandlerThanAction("Polje Grad je obavezno");
      throw new Error("Greska u polju grad");
    }
    if (address == null || address.trim().length === 0) {
      errorHandlerThanAction("Polje Adresa je obavezno");
      throw new Error("Greska u polju adresa");
    }

    await getAxiosInstance()
      .patch(`/v1/insurancecompanies/${newData.insurance_company_id}`, {
        name: newData.name,
        town: newData.town,
        address: newData.address,
        email: newData.email,
        landing_phone: newData.landing_phone,
      })
      .then(() => {
        loadAllCompanies();
      })
      .catch(errorHandlerThanAction);
  };

  const deleteCompaniesHandler = async (newData) => {
    return getAxiosInstance()
      .delete(`/v1/insurancecompanies/${newData.insurance_company_id}`)
      .then(() => {
        loadAllCompanies();
      })
      .catch(errorHandlerThanAction);
  };

  const addCompaniesHandler = async (data) => {
    const { name = null, town = null, address = null } = data;

    if (name == null || name.trim().length === 0) {
      errorHandlerThanAction("Polje ime kompanije je obavezno");
      throw new Error("Greska u polju ime kompanije");
    }
    if (town == null || town.trim().length === 0) {
      errorHandlerThanAction("Polje grad je obavezno");
      throw new Error("Greska u polju grad");
    }
    if (address == null || address.trim().length === 0) {
      errorHandlerThanAction("Polje adresa je obavezno");
      throw new Error("Greska u polju adresa");
    }
    await getAxiosInstance()
      .post(`/v1/insurancecompanies`, {
        name: data.name,
        town: data.town,
        address: data.address,
        email: data.email,
        landing_phone: data.landing_phone,
      })
      .then(() => {
        loadAllCompanies();
      })
      .catch(errorHandlerThanAction);
  };

  const onClickHandler = (event) => {
    document
      .querySelector("[data-mycustomid='add-icon-handler']")
      .parentNode.click();
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={onClickHandler}>
        DODAJ OSIGURAVAJUCE DRUSTVO
      </Button>
      <MaterialTable
        tableRef={tableRef}
        columns={TableColumns}
        data={allCompanies}
        title="Osiguravajuća Društva"
        options={{
          headerStyle: { backgroundColor: "#D1D1D1", fontWeight: "bold" },
        }}
        icons={{
          ...tableIcons,
          Add: (props) => (
            <button
              style={{ display: "none" }}
              disabled
              data-mycustomid={"add-icon-handler"}
            ></button>
          ),
        }}
        localization={{
          toolbar: { searchPlaceholder: "Pretraži" },
          body: {
            editTooltip: "Izmeni",
            editRow: {
              saveTooltip: "Sačuvaj",
              cancelTooltip: "Poništi",
              deleteText:
                "Da li ste sigurni da želite da obrišete osiguravajuće društvo",
            },
            deleteTooltip: "Obriši",
            addTooltip: "Dodaj",
          },
          header: { actions: "Akcije" },
        }}
        editable={{
          onRowAdd: (newData, oldData) => addCompaniesHandler(newData, oldData),
          onRowUpdate: (newData, oldData) =>
            updateCompaniesHandler(newData, oldData),
          onRowDelete: (newData, oldData) =>
            deleteCompaniesHandler(newData, oldData),
        }}
      />
    </div>
  );
}
