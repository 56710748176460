import MaterialTable from "material-table";
import React, { useEffect } from "react";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import tableIcons from "../../constants/tableIcons";
import { useDispatch } from "react-redux";
import { unknownErrorThanActionHandlerFactory } from "../../services/utils";
import Button from "@material-ui/core/Button";

export default function InsuranceCompanies() {
  const [allCompanies, setAllCompanies] = React.useState([]);
  const tableRef = React.useRef();

  const loadAllExpenses = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/expenses`)
      .then((res) => res.data)
      //.then(dataResult => dataResult.map( dataItem => ({...dataItem, dataItem.expense_date: new Date(dataItem.expense_date)})))
      .catch((err) => console.log(err));

    setAllCompanies(result);
  };
  useEffect(() => {
    loadAllExpenses();
  }, []);

  const TableColumns = [
    {
      title: "Naziv troška",
      field: "expense_description",
      validate: (rowData) =>
        rowData.expense_description === ""
          ? { isValid: false, helperText: "Polje ne može biti prazno" }
          : true,
    },
    {
      title: "Cena",
      field: "expense_price",
      validate: (rowData) =>
        rowData.expense_price === ""
          ? { isValid: false, helperText: "Polje ne može biti prazno" }
          : true,
    },
    {
      title: "Datum",
      type: "date",
      field: "expense_date",
      validate: (rowData) =>
        rowData.expense_date === ""
          ? { isValid: false, helperText: "Polje ne može biti prazno" }
          : true,
      render: (rowData) =>
        new Date(rowData.expense_date).toLocaleDateString("sr-RS"),
    },
  ];

  const dispatch = useDispatch();
  const errorHandlerThanAction = unknownErrorThanActionHandlerFactory(dispatch);

  const updateCompaniesHandler = async (newData) => {
    const {
      expense_description = null,
      expense_price = null,
      expense_date = null,
    } = newData;

    try {
      if (
        expense_description == null ||
        expense_description.trim().length === 0
      ) {
        errorHandlerThanAction("Polje naziv troška je obavezno");
        throw new Error("Greska u polju naziv troška");
      }
      if (expense_price == null || expense_price.trim().length === 0) {
        errorHandlerThanAction("Polje cena je obavezno");
        throw new Error("Greska u polju cena");
      }
      if (expense_date == null) {
        errorHandlerThanAction("Polje Datum je obavezno");
        throw new Error("Greska u polju datum");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }

    await getAxiosInstance()
      .patch(`/v1/expenses/${newData.expense_id}`, {
        expense_description: newData.expense_description,
        expense_price: newData.expense_price,
        expense_date: newData.expense_date.getTime(),
      })
      .then(() => {
        loadAllExpenses();
      })
      .catch(errorHandlerThanAction);
  };

  const deleteCompaniesHandler = async (newData) => {
    return getAxiosInstance()
      .delete(`/v1/expenses/${newData.expense_id}`)
      .then(() => {
        loadAllExpenses();
      })
      .catch(errorHandlerThanAction);
  };

  const addCompaniesHandler = async (data) => {
    const {
      expense_description = null,
      expense_price = null,
      expense_date = null,
    } = data;

    if (
      expense_description == null ||
      expense_description.trim().length === 0
    ) {
      errorHandlerThanAction("Polje naziv troška je obavezno");
      throw new Error("Greska u polju naziv troška");
    }
    if (expense_price == null || expense_price.trim().length === 0) {
      errorHandlerThanAction("Polje cena je obavezno");
      throw new Error("Greska u polju cena");
    }
    if (expense_date == null) {
      errorHandlerThanAction("Polje Datum je obavezno");
      throw new Error("Greska u polju datum");
    }
    await getAxiosInstance()
      .post(`/v1/expenses`, {
        expense_description: data.expense_description,
        expense_price: data.expense_price,
        expense_date: data.expense_date.getTime(),
      })
      .then(() => {
        loadAllExpenses();
      })
      .catch(errorHandlerThanAction);
  };

  const onClickHandler = (event) => {
    document
      .querySelector("[data-mycustomid='add-icon-handler']")
      .parentNode.click();
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={onClickHandler}>
        DODAJ TROŠAK
      </Button>
      <MaterialTable
        tableRef={tableRef}
        columns={TableColumns}
        data={allCompanies}
        title="Troškovi"
        options={{
          headerStyle: { backgroundColor: "#D1D1D1", fontWeight: "bold" },
        }}
        icons={{
          ...tableIcons,
          Add: (props) => (
            <button
              style={{ display: "none" }}
              disabled
              data-mycustomid={"add-icon-handler"}
            ></button>
          ),
        }}
        localization={{
          toolbar: { searchPlaceholder: "Pretraži" },
          body: {
            editTooltip: "Izmeni",
            editRow: {
              saveTooltip: "Sačuvaj",
              cancelTooltip: "Poništi",
              deleteText: "Da li ste sigurni da želite da obrišete trošak",
            },
            deleteTooltip: "Obriši",
            addTooltip: "Dodaj",
          },
          header: { actions: "Akcije" },
        }}
        editable={{
          onRowAdd: (newData, oldData) => addCompaniesHandler(newData, oldData),
          onRowUpdate: (newData, oldData) =>
            updateCompaniesHandler(newData, oldData),
          onRowDelete: (newData, oldData) =>
            deleteCompaniesHandler(newData, oldData),
        }}
      />
    </div>
  );
}
