import { useDispatch, useSelector } from "react-redux";
import DrawerAndContent from "./DrawerAndContent";
import Login from "./Login/Login";
import TryAgain from "./Login/TryAgain";
import AppSnackbar from "../common/AppSnackbar";
import { getTokenAndUserId, getUserId } from "../../data-source/authDataSource";
import Progress from "../common/Progress";
import { useEffect } from "react";
import {
  fetchAndStoreUser,
  clearUser,
  logOutLocally,
  setFetchingUser,
} from "../../actions/authActions";
import { Switch, Redirect, Route } from "react-router-dom";
import ForgottenPassword from "./Login/ForgottenPassword";
import ResetPassword from "./Login/ResetPassword";
import isEmpty from "validator/lib/isEmpty";

function LoginAndSnackbar(props) {
  return (
    <>
      <Login />
      <AppSnackbar />
    </>
  );
}

const conditionalRouteFactory =
  (conditionalFunc, redirectPath) =>
  ({ children, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        conditionalFunc(props) ? children : <Redirect to={redirectPath} />
      }
    />
  );

const NoQueryTokenRedirect = conditionalRouteFactory(
  ({ location }) =>
    location.search !== null &&
    !isEmpty(location.search, { ignore_whitespace: true }),
  "/login",
);

export default function App(props) {
  const auth = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const tempTokenAndUserId = getTokenAndUserId();

  useEffect(() => {
    if (tempTokenAndUserId != null) {
      dispatch(fetchAndStoreUser(tempTokenAndUserId.userId));
    } else {
      dispatch(setFetchingUser(false));
    }
  }, []);

  return auth.isLogged ? (
    <DrawerAndContent />
  ) : auth.fetchingUser ? (
    <Progress withoutBackground={true} title="Prijavljivanje..." />
  ) : tempTokenAndUserId == null ? (
    <Switch>
      <Route exact path="/login" component={LoginAndSnackbar} />
      <Route path="/forgottenpassword" component={ForgottenPassword} />
      <NoQueryTokenRedirect>
        <Route path="/resetpassword" component={ResetPassword} />
      </NoQueryTokenRedirect>
      <Redirect path="/" to="/login" />
    </Switch>
  ) : (
    <>
      <TryAgain
        primaryBtnTitle="Pokušaj ponovo"
        primaryBtnActionCreator={() => fetchAndStoreUser(getUserId())}
        secondaryBtnTitle="Nazad na prijavu"
        secondaryBtnActionCreator={logOutLocally}
      />
      <AppSnackbar autoHideDuration={1500} />
    </>
  );
}
