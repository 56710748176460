import React from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useForm, Controller } from "react-hook-form";

const AddLegalPeople = (props) => {
  const { isOpen = false } = props;
  const { register, handleSubmit, errors } = useForm();

  //const errorHanlderThanAction = useErrorHook();

  const onError = (errors, e) =>
    console.log("Error handler (use form", errors, e);

  return (
    <div>
      <Dialog
        onClose={props.handleDialogClose}
        open={isOpen}
        aria-labelledby="form-dialog-title"
      >
        <form
          onSubmit={handleSubmit(
            props?.submitHandler2 || props.submitHandler,
            onError,
          )}
        >
          <DialogTitle style={{ textAlign: "center" }}>
            {props.title}
          </DialogTitle>
          <DialogContent>
            <TextField
              error={!!errors["legal_name"]}
              helperText={errors["legal_name"] && "Polje je obavezno"}
              inputRef={register({ required: true })}
              autoFocus
              margin="dense"
              name="legal_name"
              label="Naziv"
              type="text"
              fullWidth
            />
            <TextField
              error={!!errors["address"]}
              helperText={errors["address"] && "Polje je obavezno"}
              inputRef={register({ required: true })}
              margin="dense"
              name="address"
              label="Adresa"
              type="text"
              fullWidth
            />
            <TextField
              inputRef={register}
              margin="dense"
              name="town"
              label="Grad"
              type="text"
              fullWidth
            />
            <TextField
              inputRef={register}
              margin="dense"
              name="contact_person"
              label="Kontakt osoba"
              type="text"
              fullWidth
            />
            <TextField
              inputRef={register}
              margin="dense"
              name="phone_number"
              label="Telefon"
              type="text"
              fullWidth
            />
            <TextField
              error={!!errors["email"]}
              helperText={errors["email"] && "Polje je obavezno"}
              inputRef={register({ required: true })}
              margin="dense"
              name="email"
              label="Email"
              type="text"
              fullWidth
            />
            {/* <TextField error={!!errors["pib"]} helperText={errors["pib"] && "Polje je obavezno"}
            inputRef={register({ required: true })} margin="dense" name="pib" label="PIB" type="text" fullWidth />
          <TextField error={!!errors["mb"]} helperText={errors["mb"] && "Polje je obavezno"}
            inputRef={register({ required: true })} margin="dense" name="mb" label="MB" type="text" fullWidth /> */}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={props.handleDialogClose}
            >
              OTKAŽI
            </Button>
            <Button
              onClick={() => document.getElementById("hiddenn").click()}
              variant="contained"
              color="primary"
            >
              SAČUVAJ
            </Button>
            <input id="hiddenn" hidden type="submit" />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddLegalPeople;
