import Materialtable, { MTableEditRow } from "material-table";
import Button from "@material-ui/core/Button";
import { useEffect, useState } from "react";
import {
  EMPLOYEE,
  COMPANY_BRANCH,
  COMPANY_LINE,
  WORKING_POSITION,
} from "../../constants/appConstants";
import tableIcons from "../../constants/tableIcons";
import { useSimpleErrorHandler } from "../../hooks/useErrorHandlerHook";
import {
  fetchEmpoloyees,
  createEmployee,
  updateEmployee,
  deleteEmployee,
} from "../../data-source/employeesDataSource";
import { fetchWorkingPositions } from "../../data-source/workingPositionsDataSource";
import {
  convertArrayToObjectAsKeyValueKeys,
  promiseDecorator,
  trimStringValuesInObj,
} from "../../services/utils";
import {
  fetchCompanyBranches,
  fetchCompanyLines,
} from "../../data-source/companyDataSource";
import {
  weakNotEmptyFieldValidationFactory,
  isTotallyEmpty,
} from "../../services/validationUtils";
import _ from "lodash";
import filteredSelectFactory from "./filteredSelectFactory";

const alteredTableIcons = {
  ...tableIcons,
  Add: (props) => (
    <button
      data-special-id-selector={"add-employee-btn"}
      style={{ display: "none" }}
    ></button>
  ),
};

const onAddEmployeeClick = (event) => {
  document
    .querySelector("button[data-special-id-selector='add-employee-btn']")
    .click();
};

export default function EmployeesTable(props) {
  const errorHandler = useSimpleErrorHandler();
  const defaultLookup = {
    1: "Vozac",
    2: "Serviser",
  };

  const [data, setData] = useState([]);
  const [workingPositions, setWorkingPositions] = useState([]);
  const [companyBranches, setCompanyBranches] = useState([]);
  const [companyLines, setCompanyLines] = useState([]);

  const defaultColumns = [
    {
      title: "Ime i prezime",
      field: EMPLOYEE.FULL_NAME,
      validate: weakNotEmptyFieldValidationFactory(EMPLOYEE.FULL_NAME),
    },
    {
      title: "Broj telefona",
      field: EMPLOYEE.PHONE_NUMBER,
    },
    {
      title: "Radna pozicija",
      field: EMPLOYEE.WORKING_POSITION_ID_FK,

      lookup: {},
    },
    {
      title: "Ispostava",
      field: EMPLOYEE.COMPANY_BRANCH_ID_FK,
      lookup: {},
    },
    {
      title: "Linija",
      field: EMPLOYEE.COMPANY_LINE_ID_FK,
      lookup: {},
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  useEffect(() => {
    (async function () {
      let tempEmployes = fetchEmpoloyees()
        .then(promiseDecorator(setData))
        .catch(errorHandler);

      let tempWorkingPositions = fetchWorkingPositions()
        .then(promiseDecorator(setWorkingPositions))
        .catch(errorHandler);

      let tempCompanyBranches = fetchCompanyBranches()
        .then(promiseDecorator(setCompanyBranches))
        .catch(errorHandler);

      let tempCompanyLines = fetchCompanyLines()
        .then(promiseDecorator(setCompanyLines))
        .catch(errorHandler);

      let result = await Promise.all([
        tempEmployes,
        tempWorkingPositions,
        tempCompanyBranches,
        tempCompanyLines,
      ]);
      let tempColumns = [...defaultColumns];
      tempColumns = adjustColumnFields(
        EMPLOYEE.WORKING_POSITION_ID_FK,
        [WORKING_POSITION.WORKING_POSITION_ID, WORKING_POSITION.NAME],
        false,
      )(result[1], tempColumns);
      tempColumns = adjustColumnFields(
        EMPLOYEE.COMPANY_BRANCH_ID_FK,
        [COMPANY_BRANCH.COMPANY_BRANCH_ID, COMPANY_BRANCH.NAME],
        false,
      )(result[2], tempColumns);
      tempColumns = adjustColumnFields(
        EMPLOYEE.COMPANY_LINE_ID_FK,
        [COMPANY_LINE.COMPANY_LINE_ID, COMPANY_LINE.NAME],
        false,
      )(result[3], tempColumns, {
        dataForeignKey: COMPANY_LINE.COMPANY_BRANCH_ID_FK,
        foreignColumnField: COMPANY_BRANCH.COMPANY_BRANCH_ID,
      });
      setColumns(tempColumns);
    })();
  }, []);

  function adjustColumnFields(
    fieldName,
    lookupKeysArray,
    shouldSetState = false,
  ) {
    return function (result, columns, filterOptions) {
      let tempNewColumns = columns;
      const columnIndex = tempNewColumns.findIndex(
        (el) => el.field === fieldName,
      );
      tempNewColumns[columnIndex] = {
        ...tempNewColumns[columnIndex],
        lookup: convertArrayToObjectAsKeyValueKeys(...lookupKeysArray, result),
        editComponent: filteredSelectFactory(
          result,
          ...lookupKeysArray,
          filterOptions,
        ),
      };
      if (shouldSetState) setColumns(tempNewColumns);
      return tempNewColumns;
    };
  }

  async function addEmployeeHandler(rowData) {
    if (isTotallyEmpty(rowData[EMPLOYEE.FULL_NAME])) {
      throw errorHandler("Polje ime i prezime je obavezno");
    }

    return createEmployee(trimStringValuesInObj(rowData))
      .then(fetchEmpoloyees)
      .then(setData);
  }

  async function updateEmployeeHandler(rowData) {
    if (isTotallyEmpty(rowData[EMPLOYEE.FULL_NAME])) {
      throw errorHandler("Polje ime i prezime je obavezno");
    }

    return updateEmployee(trimStringValuesInObj(rowData))
      .then(fetchEmpoloyees)
      .then(setData);
  }

  async function deleteEmployeeHanlder(rowData) {
    return deleteEmployee(rowData).then(fetchEmpoloyees).then(setData);
  }

  return (
    <div>
      <Button color="primary" variant="contained" onClick={onAddEmployeeClick}>
        Dodaj Zaposlenog
      </Button>
      <Materialtable
        title="Zaposleni"
        icons={alteredTableIcons}
        columns={columns}
        data={data}
        localization={{
          header: { actions: "Akcije" },
          toolbar: { searchPlaceholder: "Pretraži" },

          body: {
            emptyDataSourceMessage: "Nema zaposlenih za prikazivanje",
            addToolTip: "Dodaj zaposlenog",
            deleteToolTip: "Obriši zaposlenog",
            editTooltip: "Izmeni zaposlenog",
            editRow: {
              deleteText: "Da li ste sigurni da želite da obrišete radnika",
              cancelTooltip: "Odustani",
              saveTooltip: "Snimi",
            },
          },
          toolbar: {
            searchTooltip: "Pretraži zaposlene",
            searchPlaceholder: "Pretraži",
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} od {count}",
            labelRowsSelect: "redova",
            firstTooltip: "Prva stranica",
            lastTooltip: "Zadnja stranica",
            nextTooltip: "Sledeća stranica",
            previousTooltip: "Predhodna stranica",
          },
        }}
        editable={{
          onRowAdd: addEmployeeHandler,
          onRowUpdate: updateEmployeeHandler,
          onRowDelete: deleteEmployeeHanlder,
        }}
        options={{
          headerStyle: { backgroundColor: "#D1D1D1", fontWeight: "bold" },
          addRowPosition: "first",
          pageSize: 10,
        }}
      />
    </div>
  );
}
