import React, { useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MaterialTable from "material-table";
import tableIcons from "../../constants/tableIcons";
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import { Bar } from "react-chartjs-2";
import plugins from "chartjs-plugin-datalabels";
import Paper from "@material-ui/core/Paper";
import * as dateFormat from "../../services/dateUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190,
    width: 150,
    minHeight: 53,
  },
  buttonPDF: {
    margin: theme.spacing(1),
    minWidth: 190,
    width: 150,
    minHeight: 53,
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  // Show dropdow at bottom of select
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  MenuListProps: {
    tabindex: "1",
    tabIndex: "1",
  },
};

function Home(props) {
  const classes = useStyles();

  const initailDate = { dateFrom: new Date(), dateTo: new Date() };
  const [companyBranches, setCompanyBranches] = React.useState([]);
  const [selectedCompanyBranch, setSelectedCompanyBranch] = React.useState([]);
  const [insuranceCompanies, setInsuranceCompanies] = React.useState([]);
  const [selectedInsuranceCompanies, setSelectedInsuranceCompanies] =
    React.useState([]);
  const [companyLines, setCompanyLines] = React.useState([]);
  const [selectedCompanyLine, setSelectedComapnyLine] = React.useState([]);
  const barData = {
    datasets: [],
    labels: [],
    options: {
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: true,
          align: "center",
          anchor: "center",
          color: "white",
        },
      },
    },
  };
  const [test, setTest] = React.useState(barData);
  const [clientData, setClientData] = React.useState(barData);

  const [table, setTable] = React.useState({ data: [], summary: null });
  const [models, setModels] = React.useState("");
  const [isClicked, setIsClicked] = React.useState(false);

  const handleChangeCompanyBranch = (event) => {
    setSelectedCompanyBranch(event.target.value);
  };
  const handleChangeInsuranceCompany = (event) => {
    setSelectedInsuranceCompanies(event.target.value);
  };
  const handleChangeCompanyLine = (event) => {
    setSelectedComapnyLine(event.target.value);
  };
  const handleChangeModels = (event) => {
    setIsClicked(false);
    setModels(event.target.value);
  };

  const [dates, setDates] = React.useState(initailDate);
  const handleDateChange = (date, name) => {
    const value = date;
    setDates({ ...dates, [name]: value });
  };

  const componentToPrint = useRef(null);

  const loadCompanyBranches = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/companybranches`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setCompanyBranches(result);
  };
  const loadInsuranceCompanies = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/insurancecompanies`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setInsuranceCompanies(result);
  };
  const loadCompanyLines = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/companylines`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setCompanyLines(result);
  };

  const loadTest = async () => {
    const tempBody = {
      start: dates?.dateFrom?.getTime(),
      end: dates?.dateTo?.getTime(),
      lines: selectedCompanyLine,
      branches: selectedCompanyBranch,
      insurance_companies: selectedInsuranceCompanies,
    };

    //// Test body parameters
    // const tempBody = {
    //   start: new Date("1/1/2018").getTime(),
    //   end: new Date().getTime(),
    //   lines: [1,2,3,4,5,6],
    //   branches: [1,2],
    //   insurance_companies: [1,2],
    // }
    //// end Test body parameters

    // let result = await getAxiosInstance().post(`/v1/statistics/${models}`,
    //   { ...tempBody }).then((res) => res.data).catch((err) => console.log(err));
    // result = {
    //   ...result,
    //   data: result?.data.flat(),
    //   labels: result?.labels.flat()
    // }
    let result = await getAxiosInstance()
      .post(`/v1/statistics/composed/revenuesandexpenses`, { ...tempBody })
      .then((res) => res.data)
      .catch((err) => console.log(err));
    // console.log(result?.insurance_revenue?.data.flat());
    result = {
      test: {
        labels: result?.insurance_revenue?.labels.flat(),
        datasets: [
          {
            label: "Ukupna cena tehničkih pregleda",
            data: result?.insurance_revenue?.data.flat(),
            backgroundColor: "green",
          },
          {
            label: "Ukupna cena polisa",
            data: result?.service_revenue?.data.flat(),
            backgroundColor: "blue",
          },

          {
            label: "Ukupna cena usluge",
            data: result?.tech_inspection_revenue?.data.flat(),
            backgroundColor: "orange",
          },
          {
            label: "Ukupan iznos troškova",
            data: result?.expenses?.data.flat(),
            // data: ['0.00', '60000.00', '0.00', '0.00', '0.00', '0.00'],
            backgroundColor: "tomato",
          },
        ],
      },
      clientData: {
        labels: result?.insurance_revenue?.labels.flat(),
        datasets: [
          {
            label: "Broj tehničkih pregleda vozila",
            data: result?.clients?.data?.numberOfRegistrations.flat(),
            backgroundColor: "green",
          },
          {
            label: "Broj pravnih lica",
            data: result?.clients?.data?.numberOfLegalPersons.flat(),
            backgroundColor: "blue",
          },

          {
            label: "Broj fizičkih lica",
            data: result?.clients?.data?.numberOfPhysicalPersons.flat(),
            backgroundColor: "orange",
          },
          {
            label: "Broj putničkih vozila",
            data:result?.clients?.data?.numberOfPassengerVehicles.flat(),
            backgroundColor: "grey"
          },
          {
            label: "Broj teretnih vozila",
            data:result?.clients?.data?.numberOfCargoVehicles.flat(),
            backgroundColor: "red"
          },
          {
            label: "Broj tehničkih pregleda na poligonu",
            data:result?.clients?.data?.numberOfTechnicalPoligons.flat(),
            backgroundColor: "purple"
          }
        ],
      },
    };
    /*
console.log(result)
    result = [
      {
        //...result?.insurance_revenue,
        data: result?.insurance_revenue?.data.flat().filter(el => el !== null),
        labels: result?.insurance_revenue?.labels.flat().filter(el => el !== null)
      },
      {
        //...result?.service_revenue,
        data: result?.service_revenue?.data.flat().filter(el => el !== null),
        labels: result?.service_revenue?.labels.flat().filter(el => el !== null)
      },
      {
        //...result?.tech_inspection_revenue,
        data: result?.tech_inspection_revenue?.data.flat().filter(el => el !== null),
        labels: result?.tech_inspection_revenue?.labels.flat().filter(el => el !== null)
      },
      {
        //...result?.expenses,
        data: result?.expenses?.data.flat().filter(el => el !== null),
        labels: result?.expenses?.labels.flat().filter(el => el !== null)
      }
    ];
    */
    setTest(result.test);
    setClientData(result.clientData);

    let result2 = await getAxiosInstance()
      .post(`/v1/registrations/statistics`, { ...tempBody })
      .then((res) => res.data)
      .catch((err) => console.log(err));
    result2 = result2?.map((el, i) => {
      return {
        ...el,
        client_name:
          el?.Client?.PhysicalPerson === null
            ? el?.Client?.LegaPerson?.legal_name
            : el?.Client?.PhysicalPerson?.full_name,
        registration_date: dateFormat.formatDate(el.registration_date),
      };
    });

    setTable({
      data: result2,
      summary: {
        // ...result2[result2.length - 1],
        registration_plate: "Ukupno",
        total_registration_price: result2?.reduce(
          (acc, next) => acc + Number(next.total_registration_price),
          0,
        ),
        //  insurance_price: '23', agency_service_price: '23'
        // , registration_date: '23', client_name: '23'
      },
    });
  };
  const TableColumns = [
    { title: "Reg. oznaka", field: "registration_plate" },
    { title: "Ime klijenta", field: "client_name" },
    { title: "Cena tehničkog pregleda", field: "insurance_price" },
    { title: "Cena polise", field: "agency_service_price" },
    { title: "Cena usluge", field: "technical_inspection_price" },
    { title: "Ukupna cena", field: "total_registration_price" },
    { title: "Datum registracije", field: "registration_date" },
  ];
  const data = {
    labels: test?.labels,
    /*
    datasets: [
      {
        label: test?.resolution === 'years' && models === 'registrations' ? 'Broj registracija po godinama'
          : test?.resolution === 'months' && models === 'registrations' ? 'Broj registracija po mesecima'
            : test?.resolution === 'days' && models === 'registrations' ? 'Broj registracija po danima'
              : '',
        backgroundColor: models === 'registrations' && isClicked === true ? '#CA340D' : models === 'revenue' && isClicked === true ? '#3e9c35' : '#FAFAFA',
        //data: test?.data,
      },
      ...(test ?? []),      
    ],
    */
    datasets: test,

    options: {
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: true,
          align: "center",
          anchor: "center",
          color: "white",
        },
      },
    },
  };

  const reactToPrintContent = React.useCallback(() => {
    return componentToPrint.current;
  }, [componentToPrint.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <Button
        className={classes.formControl}
        variant="contained"
        color="primary"
      >
        Štampaj
      </Button>
    );
  }, []);

  useEffect(() => {
    loadCompanyBranches();
    loadInsuranceCompanies();
    loadCompanyLines();
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid xs={6} sm={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ width: "210px" }}
              className={classes.formControl}
              variant="inline"
              inputVariant="outlined"
              format="dd.MM.yyyy"
              id="date1"
              label="Datum OD"
              autoOk="true"
              name="dateFrom"
              value={dates.dateFrom}
              onChange={(date) => handleDateChange(date, "dateFrom")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid xs={6} sm={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.formControl}
              variant="inline"
              inputVariant="outlined"
              format="dd.MM.yyyy"
              id="date2"
              label="Datum DO"
              value={dates.dateTo}
              onChange={(date) => handleDateChange(date, "dateTo")}
              autoOk="true"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        {/* <Grid xs={6} sm={3}> */}
        {/* <FormControl variant="" className={classes.formControl}>
            <InputLabel id="select1">Model</InputLabel>
            <Select label="Model"
              value={models}
              onChange={handleChangeModels}
              MenuProps={{ ...MenuProps, autoFocus: true }}
            >
              <MenuItem value={"registrations"}>Registracije</MenuItem>
              <MenuItem value={"revenue"}>Prihodi</MenuItem>
              <MenuItem value={"clients"}>Klijenti</MenuItem>
              <MenuItem value={"insurance_company"}>Osiguravajuća društva</MenuItem>
            </Select>
          </FormControl> */}
        {/* </Grid> */}
        <Grid xs={6} sm={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select2">Ispostava</InputLabel>
            <Select
              MenuProps={{ ...MenuProps, autoFocus: true }}
              labelId="demoselect2"
              id="select"
              multiple
              variant="outlined"
              value={selectedCompanyBranch}
              onChange={handleChangeCompanyBranch}
              input={<Input />}
              // renderValue={(selected) => selected.join(', ')}
              renderValue={(selectedArray) =>
                ` ${" " + selectedArray.length} izabran(ih)`
              }
              // MenuProps={MenuProps}
            >
              {companyBranches?.map((el) => (
                <MenuItem key={el.name} value={el.company_branch_id}>
                  <Checkbox
                    checked={
                      selectedCompanyBranch.indexOf(el.company_branch_id) > -1
                    }
                  />
                  {el.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={6} sm={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select2">Osiguravajuća društva</InputLabel>
            <Select
              style={{ width: "210px" }}
              MenuProps={{ ...MenuProps, autoFocus: true }}
              labelId="demoselect2"
              id="select"
              multiple
              variant="outlined"
              value={selectedInsuranceCompanies}
              onChange={handleChangeInsuranceCompany}
              input={<Input />}
              // renderValue={(selected) => selected.join(', ')}
              renderValue={(selectedArray) =>
                ` ${" " + selectedArray.length} izabran(ih)`
              }
              // MenuProps={MenuProps}
            >
              {insuranceCompanies?.map((el) => (
                <MenuItem key={el.name} value={el.insurance_company_id}>
                  <Checkbox
                    checked={
                      selectedInsuranceCompanies.indexOf(
                        el.insurance_company_id,
                      ) > -1
                    }
                  />
                  {el.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={6} sm={3}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select3">Linije</InputLabel>
            <Select
              MenuProps={{ ...MenuProps, autoFocus: true }}
              labelId="demoselect3"
              id="select3"
              multiple
              variant="outlined"
              value={selectedCompanyLine}
              onChange={handleChangeCompanyLine}
              input={<Input />}
              // renderValue={(selected) => selected.join(', ')}
              renderValue={(selectedArray) =>
                ` ${" " + selectedArray.length} izabran(ih)`
              }
              // MenuProps={MenuProps}
            >
              {companyLines?.map((el) => (
                <MenuItem key={el.name} value={el.company_line_id}>
                  <Checkbox
                    checked={
                      selectedCompanyLine.indexOf(el.company_line_id) > -1
                    }
                  />
                  {el.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={6} sm={3}>
          <Button
            className={classes.formControl}
            variant="contained"
            color="primary"
            onClick={() => {
              loadTest();
              setIsClicked(true);
            }}
          >
            Generiši izveštaj
          </Button>
        </Grid>
        <Grid xs={6} sm={3}>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="Test Name"
            // onBeforeGetContent={handleOnBeforeGetContent}
            removeAfterPrint
            trigger={reactToPrintTrigger}
          />
        </Grid>
        <Grid xs={6} sm={3}>
          {/* <Button className={classes.buttonPDF} style={loadTest ? { disaply: '' } : { display: 'none' }} variant="outlined" color="secondary" onClick={() => loadTest()}>Izvezi u PDF</Button> */}
        </Grid>
      </Grid>
      {/* </Grid> */}
      <div ref={componentToPrint}>
        <Grid xs={12} sm={12}>
          <div style={{ postion: "relative", margin: "auto", height: "40vh" }}>
            <Bar data={test != "" ? test : {}} options={test?.options ?? []} />
          </div>
        </Grid>
        <Grid xs={12} sm={12}>
          <div style={{ postion: "relative", margin: "auto", height: "40vh" }}>
            <Bar
              data={clientData != "" ? clientData : {}}
              options={clientData?.options ?? []}
            />
          </div>
        </Grid>
        <MaterialTable
          // tableRef={tableRef}
          columns={TableColumns}
          data={table.data}
          title="Registracije"
          icons={tableIcons}
          options={{
            toolbar: false,
            paging: false,
            search: false,
            paging: false,
            maxBodyHeight: 215,
            headerStyle: { position: "sticky", top: 0 },
            headerStyle: { fontWeight: "bold" },
          }}
        />
      </div>
      <Paper elevation={1}>
        <div
          style={{
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            color: "green",
          }}
        >
          <h3>
            {table?.summary?.registration_plate}{" "}
            {table?.summary?.total_registration_price} Dinara
          </h3>
        </div>
      </Paper>
    </div>
  );
}

export default Home;
