import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import clsx from "clsx";
import { MenuItem } from "@material-ui/core";
import MLHeader from "./MLHeader";
import MLList from "./MLList";
import MLInputsContainer from "./MLInputsContainer";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { useState, useMemo, useRef, useCallback, useEffect } from "react";
import _, { findLastIndex } from "lodash";
import { mergeBoolean } from "./materiaLlistUtils";
import Divider from "@material-ui/core/Divider";
import EventEmitter from "events";

const useStyles = makeStyles({
  container: {
    padding: "0.75rem",
    //flexGrow : 1,
  },
  nonFullWidth: {
    display: "inline-block",
  },
  inputsContainer: {
    display: "flex",
    alignItems: "baseline",
    flexWrap: "wrap",
    marginBottom: 3,
  },
  listContainer: {
    display: "inline-block",
  },
  listContainerBorder: {
    border: "1px #B8B8B8 solid",
    padding: "1px",
  },
  headerContainer: {
    marginBottom: 2,
  },
  editField: {
    marginRight: 10,
    minWidth: 210,
  },
});

const initialValState = [
  { isValid: true, helperText: "" },
  { isValid: true, helperText: "" },
];

export default function MaterialList(props) {
  const { defaultProps } = MaterialList;

  const styles = useStyles();
  const [editMode, setEditMode] = useState(true);
  const [editIndex, setEditIndex] = useState(-1);
  const [validationState, setValidationState] = useState(initialValState);

  const inputRefs = useRef({});

  const {
    editable = defaultProps.editable,
    options = defaultProps.options,
    options: {
      classes: optionsClasses = defaultProps.options.classes,
      listBorder = defaultProps.options.listBorder,
    },
    columns,
  } = props;

  const { editable: defaultEditable, options: defaultOptions } = defaultProps;

  const nonFullWidth = !mergeBoolean(
    defaultOptions.fullWidth,
    options.fullWidth,
  );

  const initialState = useMemo(() => {
    return Object.fromEntries(columns.map((column) => [column.field, ""]));
  }, [columns]);

  const [inputsState, setInputsState] = useState(initialState);

  const selectFields = useMemo(
    () => columns.map((column) => "lookup" in column),
    [columns],
  );

  const createResetState = useCallback(() => {
    let tempState = {};

    selectFields.forEach((fieldBool, index) => {
      if (fieldBool) {
        const tempField = columns[index].field;
        tempState[tempField] = "";
      }
    });

    setInputsState(tempState);
  }, [columns, selectFields]);

  useEffect(() => {
    createResetState();
  }, [createResetState]);

  const deleteHandler = async (index) => {
    try {
      await editable.onRowDelete({ ...props.data[index] });
      if (editIndex === index) {
        cancelHandler();
      }
      console.log("InputRefs", inputRefs);
      console.log("InputState", inputsState);
    } catch (error) {}
  };

  const addHandler = async (addData) => {
    try {
      await editable.onRowAdd(addData);
      cancelHandler();
    } catch (error) {}
  };

  const updateHandler = async (updateData, index) => {
    try {
      await editable.onRowUpdate({ ...props.data[index], ...updateData });
      cancelHandler();
    } catch (error) {}
  };

  const selectIndex = useCallback(
    (newEditIndex, columnIndexArray) => {
      let tempInputsState = { ...inputsState };
      let stateChanged = false;
      columnIndexArray.forEach((columnIndex) => {
        let tempValue =
          newEditIndex < 0
            ? ""
            : props.data[newEditIndex][columns[columnIndex].field];
        const columnField = columns[columnIndex].field;
        if (inputsState[columnField] !== tempValue) {
          tempInputsState[columnField] = tempValue;
          stateChanged = true;
        }
      });

      if (stateChanged) setInputsState(tempInputsState);
    },
    [props.data, columns, inputsState, setInputsState],
  );

  function editHandler(newEditIndex) {
    setEditIndex(newEditIndex);
    resetValidationState();

    if (newEditIndex >= 0) {
      columns
        .slice(0, 2)
        .filter((column, index) => !selectFields[index])
        .forEach((column) => {
          inputRefs.current[column.field].value =
            props.data[newEditIndex][column.field];
        });

      let tempColumnsIndexes = [];
      selectFields.forEach((fieldBool, columnIndex) => {
        if (fieldBool) tempColumnsIndexes.push(columnIndex);
      });

      selectIndex(newEditIndex, tempColumnsIndexes);
    } else {
      columns
        .slice(0, 2)
        .filter((column, index) => !selectFields[index])
        .forEach((column) => {
          inputRefs.current[column.field].value = "";
        });
    }
  } //end editHandler

  function cancelHandler() {
    Object.values(inputRefs.current).forEach(
      (lmdInputRef) => (lmdInputRef.value = ""),
    );
    setEditIndex(-1);
    createResetState();
    resetValidationState();
  }

  const Container =
    props.components.Container || defaultProps.components.Container;
  const Header = props.components.Header || defaultProps.components.Header;
  const List = props.components.List || defaultProps.components.List;
  const InputsContainer =
    props.components.InputsContainer || defaultProps.components.InputsContainer;

  function resetValidationState() {
    setValidationState(initialValState);
  }

  return (
    <Container
      style={props.options.containerStyle}
      className={clsx(
        styles.container,
        optionsClasses.container && optionsClasses.container,
        nonFullWidth && styles.nonFullWidth,
        nonFullWidth && optionsClasses.nonFullWidth,
      )}
      dfltProps={defaultProps}
    >
      {mergeBoolean(
        defaultProps.options.showTitle,
        props.options.showTitle,
      ) && (
        <Header
          title={props.title}
          style={props.options.headerStyle}
          className={clsx(
            styles.headerContainer,
            optionsClasses.headerContainer && optionsClasses.headerContainer,
          )}
          dfltProps={defaultProps}
        />
      )}

      {(editable.onRowAdd || editable.onRowUpdate) && (
        <InputsContainer
          style={props.options.inputsContainerStyle}
          className={clsx(
            styles.inputsContainer,
            optionsClasses.inputsContainer && optionsClasses.inputsContainer,
          )}
          dfltProps={defaultProps}
          {...props}
          editMode={editMode}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
          editFieldClass={styles.editField}
          inputRefs={inputRefs}
          inputsState={inputsState}
          setInputsState={setInputsState}
          selectFields={selectFields}
          deleteHandler={deleteHandler}
          addHandler={addHandler}
          updateHandler={updateHandler}
          cancelHandler={cancelHandler}
          validationState={validationState}
          setValidationState={setValidationState}
          resetValidationState={resetValidationState}
        />
      )}

      {
        <List
          style={props.options.listStyle}
          className={clsx(
            styles.listContainer,
            listBorder && styles.listContainerBorder,
            listBorder && optionsClasses.listContainerBorder,
            optionsClasses.listContainer && optionsClasses.listContainer,
          )}
          dfltProps={defaultProps}
          {...props}
          editIndex={editIndex}
          setEditIndex={setEditIndex}
          deleteHandler={deleteHandler}
          editHandler={editHandler}
          validationState={validationState}
          setValidationState={setValidationState}
          resetValidationState={resetValidationState}
        />
      }
    </Container>
  );
}

MaterialList.defaultProps = {
  title: "Material List",
  addTitle: "Add",
  components: {
    Container: Paper,
    Header: MLHeader,
    Divider: Divider,
    EditField: TextField,
    List: MLList,
    InputsContainer: MLInputsContainer,
  },
  icons: {
    Edit: Edit,
    Delete: Delete,
  },
  localization: {
    buttons: {
      addText: "Add",
      cancelText: "Cancel",
      updateText: "Update",
    },
  },
  editable: {},
  options: {
    showTitle: true,
    divider: true,
    listBorder: true,
    fullWidth: true,
    //Styling
    containerStyle: {},
    headerStyle: {},
    inputsContainerStyle: {},
    editFieldStyle: {},
    listStyle: {},
    inputVariant: "outlined",
    classes: {},

    EditFieldProps: {},
  },
};
