import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { activatePasswordRecovery } from "../../../data-source/authDataSource";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { S_ATTR, S_ATTR_USER } from "../../../constants/serverContract";
import { unwrapErrorMessage } from "../../../services/utils";
import MessageDialog from "./MessageDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  txtField: {
    //minWidth : 420,
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  resetBtnSpace: {
    marginRight: "0.70rem",
  },
  saveBtnSpace: {
    marginRight: "0.25rem",
  },
  groupLabel: {
    marginTop: "0.75rem",
    marginBottom: "0.45rem",
  },
  newPasswordTxtFild: {
    marginTop: "0.35rem",
    marginBottom: "0.5rem",
  },
}));

export default function ForgottenPassword(props) {
  const classes = useStyles();
  const history = useHistory();

  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
    setError,
  } = useForm();

  function abort() {
    history.push("/");
  }

  async function onSubmitEmail(data) {
    try {
      await activatePasswordRecovery(data[S_ATTR_USER.USER_NAME]);
      setDialogOpen(true);
    } catch (error) {
      setError(S_ATTR_USER.USER_NAME, { message: unwrapErrorMessage(error) });
    }
  }

  const dialogCloseHandler = () => {
    setDialogOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Zaboravljenja lozinka
        </Typography>
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmitEmail)}
          noValidate
        >
          <FormGroup
            fullWidth={true}
            className={classes.txtField}
            variant="outlined"
          >
            <FormLabel className={classes.groupLabel} component="legend">
              <Typography variant="body1">
                Unesite vašu email adressu na kojoj će vam biti poslat link za
                promenu lozinke. Nakon klika na na web adresu bićete preusmereni
                na stranicu na kojoj možete uneti vašu novu lozinku:
              </Typography>
            </FormLabel>
            <TextField
              error={!!errors[S_ATTR_USER.USER_NAME]}
              helperText={
                !!errors[S_ATTR_USER.USER_NAME] &&
                errors[S_ATTR_USER.USER_NAME].message
              }
              name={S_ATTR_USER.USER_NAME}
              inputRef={register({
                validate: (value) =>
                  (!isEmpty(value) && isEmail(value)) ||
                  "Netačan format email adrese / korisničkog imena",
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email"
              autoComplete="username"
              autoFocus
            />
          </FormGroup>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              className={classes.resetBtnSpace}
              color="primary"
              variant="text"
              onClick={abort}
            >
              Odustani
            </Button>
            <Button
              className={classes.saveBtnSpace}
              type="submit"
              color="primary"
              variant="contained"
            >
              Pošalji
            </Button>
          </Box>
        </form>
      </div>
      {dialogOpen && (
        <MessageDialog
          title="Zavoravljena lozinka - Obaveštenje"
          message="Zahtev je promenu lozinke je prihvaćen. Na navedenoj email adresi je poslat link ka stranici za nastavak procedure"
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          button1Text="Zatvori"
          button1Handler={dialogCloseHandler}
        />
      )}
    </Container>
  );
}
