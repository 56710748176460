import React, { useEffect } from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useForm } from "react-hook-form";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import LegalPeopleDialog from "../Clients/LegalPeople/LegalPeopleDialog";
import PhysicalPeopleDialog from "../Clients/PhysicalPeople/PhysicalPeopleDialog";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import { unknownErrorThanActionHandlerFactory } from "../../services/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import format from "date-fns/format";

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "dd.MM.yyyy", { locale: this.locale });
  }
}

const AddRegistration = (props) => {
  const { isOpen = false, isOpenEdit = false, isNew = false } = props;
  const { register, handleSubmit, control, watch, setValue, errors } =
    useForm();

  const onError = (errors, e) =>
    console.log("Error handler (use form", errors, e);
  const [isDialogOpenAddLegal, setIsDialogOpenAddLegal] = React.useState(false);
  const [isDialoOpenPhysicalPeople, setIsDialoOpenPhysicalPeople] =
    React.useState(false);
  const [clicked, setClicked] = React.useState(false);
  const dispatch = useDispatch();
  const [legalPeople, setLegalPeople] = React.useState(undefined);
  const errorHandlerThanAction = unknownErrorThanActionHandlerFactory(dispatch);
  const handleDialogCloseAddLegal = () => {
    setIsDialogOpenAddLegal(false);
  };
  const handleDialogClosePhysical = () => {
    setIsDialoOpenPhysicalPeople(false);
  };

  useEffect(() => {
    if (isNew) {
      props.setRegistrationDate(new Date());
      props.setRegistrationDateExp(new Date());
      props.setRegistrationDateSix(new Date());
      props.setRegistrationDateTlexp(new Date());
      props.setRegistrationDateTacho(new Date());
    }
  }, []);

  useEffect(() => {
    console.log("registrationExpDate changes", props.registrationDateExp);
  }, [props.registrationDateExp]);

  const loadLegalPeople = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/legalpeople`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    result = result?.map((el, i) => {
      return {
        legal_person_id: el.legal_person_id,
        client_id_fk: el.client_id_fk,
        legal_name: el.legal_name,
        town: el.town,
        address: el.address,
        contact_person: el.contact_person,
        pib: el.pib,
        mb: el.mb,
        LegalClient_id: el.LegalClient.client_id,
        legalClient_email: el.LegalClient.email,
        legalClient_phone_number: el.LegalClient.phone_number,
      };
    });
    setLegalPeople(result);
  };
  const submitHandler2 = async (data) => {
    return await getAxiosInstance()
      .post("v1/clients", {
        email: data.email,
        phone_number: data.phone_number,
        client_type: "2",
      })
      .then((res) => {
        return getAxiosInstance().post("v1/legalpeople", {
          client_id_fk: res.data.client_id,
          legal_name: data.legal_name,
          town: data.town,
          address: data.address,
          contact_person: data.contact_person,
          pib: data.pib,
          mb: data.mb,
        });
      })
      .then(async () => {
        await props.loadLegalPerson();
        return handleDialogCloseAddLegal();
      })
      .catch(errorHandlerThanAction);
  };

  const submitHandler3 = async (data) => {
    return await getAxiosInstance()
      .post("v1/clients", {
        email: data.email,
        phone_number: data.phone_number,
        client_type: "1",
      })
      .then((res) => {
        return getAxiosInstance().post("v1/physicalpeople", {
          client_id_fk: res.data.client_id,
          full_name: data.physical_name,
        });
      })
      .then(async () => {
        await props.loadPhysicalPeople();
        return handleDialogClosePhysical();
      })
      .catch(errorHandlerThanAction);
  };
  return (
    <div>
      <Dialog
        onClose={props?.handleDialogClose}
        open={isOpen ? isOpen : isOpenEdit}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit(props?.submitHandler, onError)}>
          <DialogTitle style={{ textAlign: "center" }}>
            {props?.title}
          </DialogTitle>
          <DialogContent>
            <FormControl name="client_type" fullWidth>
              <InputLabel>Tip klijenta</InputLabel>
              <Select
                defaultValue={
                  props?.novi?.Client?.client_type
                    ? props?.novi?.Client?.client_type
                    : ""
                }
                name="client_type"
                id="demo-simple-select"
                value={props?.client_type}
                onChange={props?.handleChangeClientType}
                inputRef={register}
              >
                <MenuItem value={1}>FIZIČKO LICE</MenuItem>
                <MenuItem value={2}>PRAVNO LICE</MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
              key={props?.client_type}
              id="combo-box-demo"
              options={
                props?.isOpenEdit && props?.client_type === 2
                  ? props?.legalPeople?.map((el) => {
                      return el;
                    })
                  : props?.isOpenEdit && props?.client_type === 1
                  ? props?.physicalPeople?.map((el) => {
                      return el;
                    })
                  : props?.client_type === 2
                  ? props?.legalPeople?.map((el) => {
                      return el;
                    })
                  : props?.client_type === 1
                  ? props?.physicalPeople?.map((el) => {
                      return el;
                    })
                  : null
              }
              getOptionLabel={(option) =>
                option?.full_name || option?.legal_name
              }
              // filterOptions={filterOptions}
              disabled={props?.client_type ? false : true}
              onChange={(event, value) =>
                props.isOpenEdit
                  ? props.handleChangeClientEdit(value?.client_id_fk)
                  : props?.handleChangeClient(value?.client_id_fk)
              }
              defaultValue={
                props?.isOpenEdit && props?.client_type === 2
                  ? {
                      legal_name: props?.novi?.Client?.LegaPerson?.legal_name,
                    } || {
                      full_name: props?.novi?.Client?.PhysicalPerson?.full_name,
                    }
                  : null
              }
              // : {full_name: ''}}
              // : { full_name: props?.physicalPeople?.full_name }
              // || { legal_name: props?.legalPeople.legal_name}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // name="client_name"
                  error={
                    props?.client === undefined && clicked === true
                      ? true
                      : false
                  }
                  helperText={
                    props?.client === undefined && clicked === true
                      ? "Obavezno polje"
                      : ""
                  }
                  label="Ime klijenta"
                  style={{ width: "100%" }}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <IconButton
                        disabled={props?.client_type ? false : true}
                        style={{ right: 0, position: "absolute" }}
                        aria-label="delete"
                        size="large"
                        onClick={() => {
                          props?.client_type === 2
                            ? setIsDialogOpenAddLegal(true)
                            : setIsDialoOpenPhysicalPeople(true);
                        }}
                      >
                        <AddCircleOutlineRoundedIcon fontSize="medium" />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
            <TextField
              error={!!errors["registration_plate"]}
              helperText={errors["registration_plate"] && "Polje je obavezno"}
              inputRef={register({ required: false })}
              margin="dense"
              defaultValue={
                props.isOpenEdit ? props?.novi?.registration_plate : ""
              }
              name="registration_plate"
              label="Registraciona oznaka"
              type="text"
              fullWidth
            />
            <FormControl name="vehicle_type_id" fullWidth>
              <InputLabel>Tip vozila</InputLabel>
              <Select
                defaultValue={
                  props.isOpenEdit
                    ? props?.novi?.VehicleType?.vehicle_type_id
                    : ""
                }
                name="vehicle_type_id"
                value={props?.vehicle}
                onChange={props?.handleChangeVehicleType}
                inputRef={register}
              >
                {props?.vehicleTypes?.map((el) => {
                  return (
                    <MenuItem
                      key={el?.vehicle_type_id}
                      value={el?.vehicle_type_id}
                    >
                      {el?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Osiguravajuće društvo</InputLabel>
              <Select
                defaultValue={
                  props.isOpenEdit
                    ? props?.novi?.InsuranceCompny?.insurance_company_id
                    : ""
                }
                name="insurance_company_id"
                value={props?.insurance}
                onChange={props?.handleChangeInsuranceCompany}
                inputRef={register}
              >
                {props?.insuranceCompanies?.map((el) => {
                  return (
                    <MenuItem
                      key={el?.insurance_company_id}
                      value={el?.insurance_company_id}
                    >
                      {el?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              error={!!errors["insurance_price"]}
              helperText={errors["insurance_price"] && "Polje je obavezno"}
              inputRef={register({ required: true })}
              margin="dense"
              defaultValue={
                props.isOpenEdit ? props?.novi?.insurance_price : ""
              }
              name="insurance_price"
              label="Cena Tehničkog Pregleda"
              type="text"
              fullWidth
            />
            <TextField
              error={!!errors["agency_service_price"]}
              helperText={errors["agency_service_price"] && "Polje je obavezno"}
              inputRef={register({ required: true })}
              margin="dense"
              defaultValue={
                props.isOpenEdit ? props?.novi?.agency_service_price : ""
              }
              name="agency_service_price"
              label="Cena Polise"
              type="text"
              fullWidth
            />
            <TextField
              error={!!errors["technical_inspection_price"]}
              helperText={
                errors["technical_inspection_price"] && "Polje je obavezno"
              }
              inputRef={register({ required: true })}
              margin="dense"
              defaultValue={
                props.isOpenEdit ? props?.novi?.technical_inspection_price : ""
              }
              name="technical_inspection_price"
              label="Cena Usluge"
              type="text"
              fullWidth
            />
            <TextField
              error={!!errors["insurance_policy_number"]}
              helperText={
                errors["insurance_policy_number"] && "Polje je obavezno"
              }
              inputRef={register({ required: false })}
              margin="dense"
              defaultValue={
                props.isOpenEdit ? props?.novi?.insurance_policy_number : ""
              }
              name="insurance_policy_number"
              label="Broj Polise"
              type="text"
              fullWidth
            />
            <FormControl name="payment_type" fullWidth>
              <InputLabel>Tip plaćanja</InputLabel>
              <Select
                defaultValue={
                  props?.isOpenEdit
                    ? props?.novi?.PaymentType?.payment_type_id
                    : ""
                }
                name="payment_type"
                value={props?.payment}
                onChange={props?.handleChangePaymentType}
                inputRef={register}
              >
                {props?.paymentTypes?.map((el) => {
                  return (
                    <MenuItem
                      key={el?.payment_type_id}
                      value={el?.payment_type_id}
                    >
                      {el?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl name="company_line_id" fullWidth>
              <InputLabel>Linija</InputLabel>
              <Select
                defaultValue={
                  props?.isOpenEdit
                    ? props?.novi?.CompanyLine?.company_line_id
                    : ""
                }
                name="company_line_id"
                value={props?.line}
                onChange={props?.handleChangeCompanyLine}
                inputRef={register}
              >
                {props?.companyLines?.map((el) => {
                  return (
                    <MenuItem
                      key={el?.company_line_id}
                      value={el?.company_line_id}
                    >
                      {el?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={LocalizedUtils}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Datum registracije"
                autoOk={true}
                //value={props.isOpenEdit ? props?.registrationDate : new Date()}
                value={props.registrationDate}
                //onChange={props.setRegistrationDate}
                onChange={props.handleDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={LocalizedUtils}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy"
                margin="normal"
                id="date-picker-inline2"
                label="Datum isteka registracije"
                autoOk={true}
                // value={props.isOpenEdit ? props?.registrationDateExp : new Date()}
                value={props.registrationDateExp}
                onChange={props.handleDateExp}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={LocalizedUtils}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy"
                margin="normal"
                id="date-picker-inline2"
                label="Šestomesečni"
                autoOk={true}
                // value={props.isOpenEdit ? props?.registrationDateExp : new Date()}
                value={props.registrationDateSix}
                onChange={props.handleDateSix}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={LocalizedUtils}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                maxDate={new Date("31.12.9999")}
                variant="inline"
                format="dd.MM.yyyy"
                margin="normal"
                id="date-picker-inline2"
                label="Datum isteka saobraćajne"
                autoOk={true}
                // value={props.isOpenEdit ? props?.registrationDateExp : new Date()}
                value={props.registrationDateTlexp}
                onChange={props.handleDateTlexp}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={LocalizedUtils}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                variant="inline"
                format="dd.MM.yyyy"
                margin="normal"
                id="date-picker-inline2"
                label="Datum isteka tahograf"
                autoOk={true}
                // value={props.isOpenEdit ? props?.registrationDateExp : new Date()}
                value={props.registrationDateTacho}
                onChange={props.handleDateTacho}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
            <FormControl name="sticker_issue_place" fullWidth>
              <InputLabel>Mesto Izdavanja Nalepnice</InputLabel>
              <Select
                defaultValue={
                  props?.isOpenEdit ? props?.novi?.sticker_issue_place : ""
                }
                name="sticker_issue_place"
                value={props?.stickerIssuePlace}
                onChange={props?.handleChangeStickerPlaceIssue}
                inputRef={register}
              >
                <MenuItem value={"MUP"}>MUP</MenuItem>
                <MenuItem value={"AGENCIJA"}>AGENCIJA</MenuItem>
              </Select>
            </FormControl>
            <TextField
              error={!!errors["processed_by"]}
              helperText={errors["processed_by"] && "Polje je obavezno"}
              inputRef={register({ required: false })}
              margin="dense"
              defaultValue={props.isOpenEdit ? props?.novi?.processed_by : ""}
              name="processed_by"
              label="Obradio"
              type="text"
              fullWidth
            />

            <TextField
              error={!!errors["associate"]}
              helperText={errors["associate"] && "Polje je obavezno"}
              inputRef={register({ required: false })}
              margin="dense"
              defaultValue={props.isOpenEdit ? props?.novi?.associate : ""}
              name="associate"
              label="Saradnik"
              type="text"
              fullWidth
            />
            <TextField
              error={!!errors["comment"]}
              helperText={errors["comment"] && "Polje je obavezno"}
              inputRef={register({ required: false })}
              margin="dense"
              defaultValue={props.isOpenEdit ? props?.novi?.comment : ""}
              name="comment"
              label="Komentar"
              type="text"
              fullWidth
            />
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={props.handleDialogClose}
            >
              OTKAŽI
            </Button>
            <Button
              onClick={() => document.getElementById("hidden").click()}
              variant="contained"
              color="primary"
            >
              SAČUVAJ
            </Button>
            <input
              id="hidden"
              hidden
              type="submit"
              onClick={() => setClicked(true)}
            />
          </DialogActions>
        </form>
      </Dialog>
      {isDialogOpenAddLegal ? (
        <LegalPeopleDialog
          title={"Dodaj pravno lice"}
          isOpen={isDialogOpenAddLegal}
          handleDialogClose={handleDialogCloseAddLegal}
          submitHandler2={submitHandler2}
          loadLegalPeople={loadLegalPeople}
        />
      ) : null}
      {isDialoOpenPhysicalPeople ? (
        <PhysicalPeopleDialog
          title={"Dodaj fizičko lice"}
          isOpen={isDialoOpenPhysicalPeople}
          handleDialogClose={handleDialogClosePhysical}
          submitHandler3={submitHandler3}
        />
      ) : null}
    </div>
  );
};

export default AddRegistration;
