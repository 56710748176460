import React, { useEffect } from "react";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import { Bar } from "react-chartjs-2";
import plugins from "chartjs-plugin-datalabels";

function RegistrationTraffic(props) {
  let startDate = new Date();
  startDate = startDate.getTime();

  let endDate = new Date(startDate);
  endDate = endDate.setMonth(endDate.getMonth() - 12);

  const [test, setTest] = React.useState();
  const loadTest = async () => {
    let result = await getAxiosInstance()
      .post(`/v1/statistics/registrations`, {
        start: new Date("1/1/2021").getTime(),
        end: new Date().getTime(),
        resolution: "months",
      })
      .then((res) => res.data)
      .catch((err) => console.log(err));
    result = {
      ...result,
      data: result?.data.flat(),
      labels: result?.labels.flat(),
    };
    setTest(result);
  };
  useEffect(() => {
    loadTest();
  }, []);
  console.log(test);
  const data = {
    labels: test?.labels,
    datasets: [
      {
        label: "Broj regisracija po mesecima",
        backgroundColor: "#B590FF",
        data: test?.data,
      },
    ],

    options: {
      // scales: {
      //   yAxes: [{
      //     ticks: {
      //       beginAtZero: true,
      //       stepSize: 1
      //     },
      //     stacked: true,
      //     gridLines: {
      //       display: false,
      //       color: "rgba(255,99,132,0.2)"
      //     }
      //   }],
      //   xAxes: [{
      //     gridLines: {
      //       display: false
      //     }
      //   }]
      // },
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: true,
          align: "center",
          anchor: "center",
          color: "white",
        },
      },
    },
  };
  return (
    <div
      style={{
        postion: "relative",
        margin: "auto",
        height: "40vh",
        width: "40vw",
      }}
    >
      {/* <p>Dnevna statistika na dan {date} </p> */}
      <Bar data={data} options={data.options} />
    </div>
  );
}

export default RegistrationTraffic;
