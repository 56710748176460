import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { getAxiosInstance } from "../../data-source/axiosProvider";

const Cash1 = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const startDate = new Date("1/1/2021");
      const endDate = new Date();
      const result = await getAxiosInstance().get(`/v1/cash1/`, {
        start: `${startDate.getDate()}/${
          startDate.getMonth() + 1
        }/${startDate.getFullYear()}`,
        end: `${endDate.getDate()}/${
          endDate.getMonth() + 1
        }/${endDate.getFullYear()}`,
        resolution: "months",
      });

      const groupedData = {};
      result.data.forEach((entry) => {
        const month = new Date(entry.date).toLocaleDateString("default", {
          month: "numeric",
          year: "numeric",
        });
        if (!groupedData[month]) {
          groupedData[month] = 0;
        }
        groupedData[month] += entry.amount;
      });

      const newData = Object.keys(groupedData).map((month) => ({
        date: month,
        amount: groupedData[month],
      }));

      setData(newData);
    };

    fetchData();
  }, []);

  const chartData = {
    labels: data.map((entry) => entry.date),
    datasets: [
      {
        label: "Keš 1 po Mesecima",
        backgroundColor: "#101820FF",
        data: data.map((entry) => entry.amount),
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: true,
        align: "center",
        anchor: "center",
        color: "white",
      },
    },
  };

  return (
    <div
      style={{
        position: "relative",
        margin: "auto",
        height: "40vh",
        width: "40vw",
      }}
    >
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default Cash1;
