import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  useLayoutEffect,
} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { conforms, set } from "lodash";

export default function MLInputsContainer(props) {
  const { dfltProps: defaultProps } = props;
  const {
    columns,
    editIndex,
    inputRefs,
    inputsState,
    setInputsState,
    selectFields,
    options,
    options: {
      classes: optionsClasses = defaultProps.options.classes,
      EditFieldProps = defaultProps.options.EditFieldProps,
    },
    validationState,
    setValidationState,
  } = props;

  const attachRefHandlerFactory = (fieldName) => (domRef) => {
    inputRefs.current[fieldName] = domRef;
  };

  function extractRowData() {
    return Object.entries(inputRefs.current)
      .filter((elArray, index) => !selectFields[index])
      .map(([key, value]) => [key, value.value])
      .concat(
        selectFields
          .map((fieldBool, index) => index)
          .filter((index) => selectFields[index])
          .map((index) => {
            let tempFieldName = columns[index].field;
            return [tempFieldName, inputsState[tempFieldName]];
          }),
      )
      .reduce((acc, entry) => {
        let temp = { ...acc };
        temp[entry[0]] = entry[1];
        return temp;
      }, {});
  }

  function checkIsFieldsValid() {
    return validationState
      .map(({ isValid }) => isValid)
      .every((el) => el === true);
  }

  const onAddOrUpdateHandler = (event) => {
    if (checkIsFieldsValid()) {
      if (editIndex < 0) {
        props.addHandler(extractRowData());
      } else {
        props.updateHandler(extractRowData(), editIndex);
      }
    }
  };

  const onCancelHandler = (event) => {
    props.cancelHandler();
  };

  const onChangeHandlerFactory = (index) => (event) => {
    if (columns[index].validate) {
      let validationResult = columns[index].validate(extractRowData());
      const lastValidationResult = validationState[index];
      if (typeof validationResult === "boolean") {
        validationResult = { isValid: validationResult, helperText: "" };
      } else if (typeof validationResult === "string") {
        validationResult = {
          isValid: validationResult == null,
          helperText: validationResult ?? "",
        };
      }

      if (
        !(
          validationResult.isValid === lastValidationResult.isValid &&
          validationResult.helperText === lastValidationResult.helperText
        )
      ) {
        let resultValState = [...validationState];
        resultValState[index] = { ...validationResult };
        setValidationState(resultValState);
      }
    }

    if (selectFields[index]) {
      let tempInputsState = { ...inputsState };
      tempInputsState[columns[index].field] = event.target.value;
      setInputsState(tempInputsState);
    }
  };

  const editMode = props.editIndex >= 0;

  const EditField =
    props.components.EditField || defaultProps.components.EditField;

  return (
    <div style={props.style} className={props.className}>
      {columns.slice(0, 2).map((column, index) => {
        const isSelect = "lookup" in column;

        return (
          <EditField
            margin="dense"
            className={clsx(
              props.editFieldClass,
              optionsClasses.editField && optionsClasses.editField,
            )}
            select={isSelect}
            error={!validationState[index].isValid}
            helperText={validationState[index].helperText}
            style={options.editFieldStyle}
            value={isSelect ? inputsState[column.field] : undefined}
            inputRef={attachRefHandlerFactory(column.field)}
            label={column.title}
            variant={options.inputVariant || defaultProps.options.inputVariant}
            name={column.field}
            type={isSelect ? "select" : "text"}
            onChange={onChangeHandlerFactory(index)}
            InputLabelProps={{ shrink: true }}
            {...EditFieldProps}
          >
            {isSelect
              ? Object.entries(column.lookup).map(
                  ([lookupKey, lookupValue]) => (
                    <MenuItem key={lookupKey} value={lookupKey}>
                      {lookupValue}
                    </MenuItem>
                  ),
                )
              : null}{" "}
          </EditField>
        );
      })}
      <div style={{ display: "flex" }}>
        <Button
          style={{ bottom: 2 }}
          color="primary"
          variant="contained"
          onClick={onAddOrUpdateHandler}
        >
          {!editMode
            ? props.localization.buttons.addText
            : props.localization.buttons.updateText}
        </Button>
        {editMode && (
          <Button
            style={{ bottom: 2 }}
            color="primary"
            variant="text"
            onClick={onCancelHandler}
          >
            {props.localization.buttons.cancelText}
          </Button>
        )}
      </div>
    </div>
  );
}
