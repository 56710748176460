import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PersonIcon from "@material-ui/icons/Person";
import BussinessIcon from "../components/app-scaffold/BussinessIcon";
import InsuranceCompanyIcons from "../components/app-scaffold/InsuranceCompanyIcon";
import ClientsIcons from "../components/app-scaffold/ClientsIcon";
import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
import RegistrationIcon from "../components/app-scaffold/RegistrationsIcon";
import CompanyIcon from "../components/Company/CompanyIcon";
import WorkingPositionIcon from "@material-ui/icons/EventSeat";
import CompanyLineIcon from "../components/Company/CompanyLineIcon";
import PaymentTypes from "../components/PaymentTypes/PaymentTypeIcon";
import EuroIcon from "@material-ui/icons/Euro";
import DashboardIcon from "@material-ui/icons/Euro";
import CommuteIcon from "@material-ui/icons/Commute";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import TimelineIcon from "@material-ui/icons/Timeline";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import EmployeeIcon from "../components/Company/EmployeeIcon";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import EqualizerIcon from "@material-ui/icons/Equalizer";

export const DRAWER_ITEMS_IDS = {
  HOME: 0,
  STATISTICS: 1,
  REPORT_STATISTIC: 2,
  REPORT_STATISTIC2: 3,
  USERS: 4,
  PROFILE_SETTINGS: 5,
  CLIENT: 6,
  PHYSICAL_CLIENT: 7,
  LEGAL_CLIENT: 8,
  REGISTRATIONS: 9,
  COMPANY: 10,
  WORKING_POSITIONS: 11,
  COMPANY_BRANCHES: 12,
  COMPANY_LINES: 13,
  EMPLOYEES: 14,
  VEHICLE_TYPES: 15,
  PAYMENT_TYPES: 16,
  INSURANCE_COMPANIES: 17,
  EXPENSES: 18,
  CASH1: 19,
  CASH2: 20,
};

export const DRAWER_ROUTES_MAP = {
  [DRAWER_ITEMS_IDS.HOME]: "/home",
  [DRAWER_ITEMS_IDS.STATISTICS]: "/statistics",
  [DRAWER_ITEMS_IDS.REPORT_STATISTIC]: "/reports",
  [DRAWER_ITEMS_IDS.REPORT_STATISTIC2]: "/reports2",
  [DRAWER_ITEMS_IDS.USERS]: "/users",
  [DRAWER_ITEMS_IDS.PROFILE_SETTINGS]: "/profile",
  [DRAWER_ITEMS_IDS.PHYSICAL_CLIENT]: "/phyisicalclient",
  [DRAWER_ITEMS_IDS.LEGAL_CLIENT]: "/legalclient",
  [DRAWER_ITEMS_IDS.INSURANCE_COMPANIES]: "/insurancecompanies",
  [DRAWER_ITEMS_IDS.REGISTRATIONS]: "/registrations",
  [DRAWER_ITEMS_IDS.WORKING_POSITIONS]: "/workingpositions",
  [DRAWER_ITEMS_IDS.COMPANY_BRANCHES]: "/companybranches",
  [DRAWER_ITEMS_IDS.COMPANY_LINES]: "/companylines",
  [DRAWER_ITEMS_IDS.VEHICLE_TYPES]: "/vehicletypes",
  [DRAWER_ITEMS_IDS.PAYMENT_TYPES]: "/paymenttypes",
  [DRAWER_ITEMS_IDS.EMPLOYEES]: "/employees",
  [DRAWER_ITEMS_IDS.EXPENSES]: "/expenses",
  [DRAWER_ITEMS_IDS.CASH1]: "/cash1",
  [DRAWER_ITEMS_IDS.CASH2]: "/cash2",
};

export const ITEM_TYPE = {
  SIMPLE_ITEM: 1,
  NESTED_ITEM: 2,
};

export const drawerItems = [
  {
    itemId: DRAWER_ITEMS_IDS.HOME,
    type: ITEM_TYPE.SIMPLE_ITEM,
    title: "Početna",
    icon: <HomeIcon />,
    match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.HOME],
    position: 1,
  },
  {
    itemId: DRAWER_ITEMS_IDS.STATISTICS,
    type: ITEM_TYPE.NESTED_ITEM,
    title: "Statistika",
    icon: <TimelineIcon />,
    match: null,
    position: 2,
    items: [
      {
        itemId: DRAWER_ITEMS_IDS.PHYSICAL_CLIENT,
        type: ITEM_TYPE.SIMPLE_ITEM,
        title: "Izveštaj",
        icon: <EqualizerIcon />,
        match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.REPORT_STATISTIC],
        position: 1,
      },
      {
        itemId: DRAWER_ITEMS_IDS.REPORT_STATISTIC2,
        type: ITEM_TYPE.SIMPLE_ITEM,
        title: "Napravi izveštaj",
        icon: <AssignmentOutlinedIcon />,
        match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.REPORT_STATISTIC2],
        position: 2,
      },
    ],
  },
  {
    itemId: DRAWER_ITEMS_IDS.USERS,
    type: ITEM_TYPE.SIMPLE_ITEM,
    title: "Korisnici",
    icon: <PeopleAltIcon />,
    match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.USERS],
    position: 3,
  },
  {
    itemId: DRAWER_ITEMS_IDS.PROFILE_SETTINGS,
    type: ITEM_TYPE.SIMPLE_ITEM,
    title: "Profil",
    icon: <SettingsIcon />,
    match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.PROFILE_SETTINGS],
    position: 4,
  },
  {
    itemId: DRAWER_ITEMS_IDS.CLIENT,
    type: ITEM_TYPE.NESTED_ITEM,
    title: "Klijenti",
    icon: <ClientsIcons />,
    match: null,
    position: 5,
    items: [
      {
        itemId: DRAWER_ITEMS_IDS.PHYSICAL_CLIENT,
        type: ITEM_TYPE.SIMPLE_ITEM,
        title: "Fizicka lica",
        icon: <DirectionsWalkIcon />,
        match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.PHYSICAL_CLIENT],
        position: 1,
      },
      {
        itemId: DRAWER_ITEMS_IDS.LEGAL_CLIENT,
        type: ITEM_TYPE.SIMPLE_ITEM,
        title: "Pravna lica",
        icon: <BussinessIcon />,
        match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.LEGAL_CLIENT],
        position: 2,
      },
    ],
  },
  {
    itemId: DRAWER_ITEMS_IDS.REGISTRATIONS,
    type: ITEM_TYPE.SIMPLE_ITEM,
    title: "Registracije",
    icon: <RegistrationIcon />,
    match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.REGISTRATIONS],
    position: 6,
  },
  {
    itemId: DRAWER_ITEMS_IDS.EXPENSES,
    type: ITEM_TYPE.SIMPLE_ITEM,
    title: "Troškovi",
    icon: <DashboardIcon />,
    match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.EXPENSES],
    position: 7,
  },
  {
    itemId: DRAWER_ITEMS_IDS.CASH1,
    type: ITEM_TYPE.SIMPLE_ITEM,
    title: "Keš 1",
    icon: <DashboardIcon />,
    match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.CASH1],
    position: 8,
  },
  {
    itemId: DRAWER_ITEMS_IDS.CASH2,
    type: ITEM_TYPE.SIMPLE_ITEM,
    title: "Keš 2",
    icon: <DashboardIcon />,
    match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.CASH2],
    position: 9,
  },
  {
    itemId: DRAWER_ITEMS_IDS.COMPANY,
    type: ITEM_TYPE.NESTED_ITEM,
    title: "Kompanija",
    icon: <CompanyIcon />,
    match: null,
    position: 10,
    items: [
      {
        itemId: DRAWER_ITEMS_IDS.WORKING_POSITIONS,
        type: ITEM_TYPE.SIMPLE_ITEM,
        title: "Radna pozicije",
        icon: <WorkingPositionIcon />,
        match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.WORKING_POSITIONS],
        position: 1,
      },
      {
        itemId: DRAWER_ITEMS_IDS.COMPANY_BRANCHES,
        type: ITEM_TYPE.SIMPLE_ITEM,
        title: "Ispotave",
        icon: <BussinessIcon />,
        match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.COMPANY_BRANCHES],
        position: 2,
      },
      {
        itemId: DRAWER_ITEMS_IDS.COMPANY_LINES,
        type: ITEM_TYPE.SIMPLE_ITEM,
        title: "Linije",
        icon: <CompanyLineIcon />,
        match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.COMPANY_LINES],
        position: 3,
      },
      {
        itemId: DRAWER_ITEMS_IDS.EMPLOYEES,
        type: ITEM_TYPE.SIMPLE_ITEM,
        title: "Zaposleni",
        icon: <PersonPinIcon />,
        match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.EMPLOYEES],
        position: 4,
      },
    ],
  },
  {
    itemId: DRAWER_ITEMS_IDS.VEHICLE_TYPES,
    type: ITEM_TYPE.SIMPLE_ITEM,
    title: "Tipovi vozila",
    icon: <CommuteIcon />,
    match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.VEHICLE_TYPES],
    position: 11,
  },
  {
    itemId: DRAWER_ITEMS_IDS.PAYMENT_TYPES,
    type: ITEM_TYPE.SIMPLE_ITEM,
    title: "Tipovi plaćanja",
    icon: <DashboardIcon />, //<PaymentTypes />,
    match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.PAYMENT_TYPES],
    position: 12,
  },
  {
    itemId: DRAWER_ITEMS_IDS.INSURANCE_COMPANIES,
    type: ITEM_TYPE.SIMPLE_ITEM,
    title: "Osiguravajuća društva",
    icon: <InsuranceCompanyIcons />,
    match: DRAWER_ROUTES_MAP[DRAWER_ITEMS_IDS.INSURANCE_COMPANIES],
    position: 13,
  },
];

export const DRAWER_ROUTES_PATHS_ARRAY = Object.values(DRAWER_ROUTES_MAP);

export const userDrawerItemsIds = [
  DRAWER_ITEMS_IDS.HOME,
  DRAWER_ITEMS_IDS.PROFILE_SETTINGS,
  DRAWER_ITEMS_IDS.REGISTRATIONS,
  DRAWER_ITEMS_IDS.EXPENSES,
];
export const adminDrawerItemsIds = [
  DRAWER_ITEMS_IDS.HOME,
  DRAWER_ITEMS_IDS.USERS,
  DRAWER_ITEMS_IDS.REGISTRATIONS,
  DRAWER_ITEMS_IDS.EXPENSES,
  DRAWER_ITEMS_IDS.CASH1,
  DRAWER_ITEMS_IDS.CASH2,
  DRAWER_ITEMS_IDS.PROFILE_SETTINGS,
  DRAWER_ITEMS_IDS.STATISTICS,
  {
    id: DRAWER_ITEMS_IDS.CLIENT,
    item_ids: [DRAWER_ITEMS_IDS.PHYSICAL_CLIENT, DRAWER_ITEMS_IDS.LEGAL_CLIENT],
  },
  {
    id: DRAWER_ITEMS_IDS.COMPANY,
    item_ids: [
      DRAWER_ITEMS_IDS.WORKING_POSITIONS,
      DRAWER_ITEMS_IDS.COMPANY_BRANCHES,
      DRAWER_ITEMS_IDS.COMPANY_LINES,
      DRAWER_ITEMS_IDS.EMPLOYEES,
    ],
  },
  DRAWER_ITEMS_IDS.VEHICLE_TYPES,
  DRAWER_ITEMS_IDS.PAYMENT_TYPES,
  DRAWER_ITEMS_IDS.INSURANCE_COMPANIES,
];

export default drawerItems;
