import React, { useEffect } from "react";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import { Bar, Line } from "react-chartjs-2";

const ClientTraffic = () => {
  const [test, setTest] = React.useState({
    datasets: [],
    labels: [],
    options: {
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          display: true,
          align: "center",
          anchor: "center",
          color: "white",
        },
      },
    },
  });
  const startDate = new Date("1/1/2021");
  const endDate = new Date("");
  const loadClientTypeStatistic = async () => {
    const tempBody = {
      start: startDate.toLocaleDateString("sr-RS"),
      end: endDate.toLocaleDateString("sr-RS"),
      branches: [1, 2, 3, 4, 5, 6],
      insurance_companies: [1, 2],
      resolution: "months",
    };

    const result = await getAxiosInstance()
      .get(`/v1/clients`, { ...tempBody })
      .then((res) => res.data)
      .catch((err) => console.log(err));

    const aggregatedData = result.reduce((acc, cur) => {
      const month = new Date(cur.createdAt).toLocaleDateString("sr-RS", {
        month: "2-digit",
        year: "numeric",
      });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(cur);
      return acc;
    }, {});

    const labels = Object.keys(aggregatedData);
    const datasets = [
      {
        label: "Fizicka lica",
        data: labels.map(
          (month) =>
            aggregatedData[month].filter((type) => type.client_type === 1)
              .length,
        ),
        fill: false,
        lineTension: 0.2,
        pointBorderWidth: 10,
        borderColor: "green",
        backgroundColor: "green",
      },
      {
        label: "Pravna lica",
        data: labels.map(
          (month) =>
            aggregatedData[month].filter((prop) => prop.client_type === 2)
              .length,
        ),
        fill: false,
        lineTension: 0.2,
        pointBorderWidth: 10,
        borderColor: "blue",
        backgroundColor: "blue",
      },
    ];

    const chartData = {
      labels,
      datasets,
      options: {
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: true,
            align: "center",
            anchor: "center",
            color: "white",
          },
        },
      },
    };

    setTest(chartData);
  };

  useEffect(() => {
    loadClientTypeStatistic();
  }, []);

  return (
    <div
      style={{
        postion: "relative",
        margin: "auto",
        height: "40vh",
        width: "40vw",
      }}
    >
      <Line data={test} options={test?.options} />
    </div>
  );
};

export default ClientTraffic;
