import {
  mapKeysToCamelCase,
  promiseLogger,
  titledPromiseLogger,
  mapKeysToSnakeCase,
} from "../services/utils";
import { fakeUsersList } from "./fake-data-source/fakeDataSource";
import { AUTH_SETTINGS } from "../Settings";
import { getAxiosInstance } from "./axiosProvider";
import EventEmitter from "events";
import { TOKEN_EVENTS } from "../constants/eventTypes";
import { STORAGE_KEYS } from "../constants/appConstants";
import { USER } from "../constants/appConstants";
import { S_ATTR_USER, S_AD_ATTR } from "../constants/serverContract";
import { BASE_URL } from "../constants/appConstants";

import { userFakeDataSource } from "./fake-data-source/fakeDataSource";
import axios from "axios";
import { getRefreshTokenLogoutService } from "../services/RefreshTokenLogoutService";
import ms from "ms";

export const tokenEventEmitter = new EventEmitter();

export async function authenticate(argUserName, argPassword) {
  if (!AUTH_SETTINGS.FAKE_DATA) {
    return getAxiosInstance()
      .post("/v1/auth/login", {
        [S_ATTR_USER.USER_NAME]: argUserName,
        [S_ATTR_USER.PASSWORD]: argPassword,
      })
      .then((result) => result.data)
      .then((unwrappedData) => mapKeysToCamelCase(unwrappedData));
  } else {
    const fakeUserIndex = 1;
    return mapKeysToCamelCase({
      token: "fake_token",
      user_id: fakeUsersList[fakeUserIndex][S_ATTR_USER.USER_ID],
      user_type: fakeUsersList[fakeUserIndex][S_ATTR_USER.USER_TYPE],
      full_name: fakeUsersList[fakeUserIndex][S_ATTR_USER.FULL_NAME],
    });
  }
}

export async function fetchUser(argUserId) {
  if (typeof argUserId !== "number")
    return Promise.reject("User Id must be a number");

  if (!AUTH_SETTINGS.FAKE_DATA) {
    return getAxiosInstance()
      .get(`/v1/users/${argUserId}`)
      .then((result) => result.data)
      .then((unwrappedData) => mapKeysToCamelCase(unwrappedData))
      .then(({ userId, userType, fullName, picturePath }) => ({
        userId,
        userType,
        fullName,
        picturePath,
      }));
  } else {
    const testError = false;
    if (testError) {
      return new Promise((resolve, reject) =>
        setTimeout(() => {
          console.log("About to reject promise");
          reject("Auth test error - fetch user");
        }, 500),
      );
    } else {
      return new Promise((resolve, reject) =>
        setTimeout(() => {
          try {
            resolve(fakeUsersList.find((el) => el.user_id === argUserId));
          } catch (error) {
            reject(error);
          }
        }, 1000),
      )
        .then((data) => mapKeysToCamelCase(data))
        .then(({ deviceId, ...data }) => ({ userId: deviceId, ...data }));
      //.then( titledPromiseLogger("Data fetching - User"))
    }
  }
}

export async function storeTokenAndUserId(
  argToken,
  argUserId,
  argRefreshToken,
  argRefreshTokenExpiresIn,
) {
  window.localStorage.setItem(STORAGE_KEYS.TOKEN, argToken);
  window.localStorage.setItem(STORAGE_KEYS.USER_ID, argUserId);
  window.localStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, argRefreshToken);
  window.localStorage.setItem(
    STORAGE_KEYS.REFRESH_TOKEN_EXPIRES_IN,
    argRefreshTokenExpiresIn,
  );
  tokenEventEmitter.emit(TOKEN_EVENTS.TOKEN_CHANGED);

  getRefreshTokenLogoutService().startOrRestart(
    ms(argRefreshTokenExpiresIn) + 500,
  );
}

export function getTokenAndUserId() {
  const token = window.localStorage.getItem(STORAGE_KEYS.TOKEN);
  const refreshToken = window.localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  const refreshTokenExpiresIn = window.localStorage.getItem(
    STORAGE_KEYS.REFRESH_TOKEN_EXPIRES_IN,
  );

  if (token == null) {
    return null;
  } else {
    return {
      token,
      refreshToken,
      refreshTokenExpiresIn,
      userId: Number(window.localStorage.getItem(STORAGE_KEYS.USER_ID)),
    };
  }
}

export function getUserId() {
  let tempUserId = window.localStorage.getItem(STORAGE_KEYS.USER_ID);
  if (tempUserId != null) {
    tempUserId = Number(tempUserId);
  }

  return tempUserId;
}

export async function clearTokenAndUserId() {
  getRefreshTokenLogoutService().stop();

  window.localStorage.removeItem(STORAGE_KEYS.TOKEN);
  window.localStorage.removeItem(STORAGE_KEYS.USER_ID);
  window.localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  window.localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN_EXPIRES_IN);
  tokenEventEmitter.emit(TOKEN_EVENTS.TOKEN_CHANGED);
}

export async function activatePasswordRecovery(userName) {
  return await getAxiosInstance().post("/v1/auth/forgottenpassword", {
    [S_ATTR_USER.USER_NAME]: userName,
  });
}

export async function resetPassword(
  newPassword,
  newAgainPassword,
  queryString,
) {
  return await getAxiosInstance().post(`/v1/auth/resetpassword${queryString}`, {
    [S_AD_ATTR.PASSWORD_RESET.NEW_PASSWORD]: newPassword,
    [S_AD_ATTR.PASSWORD_RESET.NEW_AGAIN_PASSWORD]: newAgainPassword,
  });
}

export async function refreshTokenAndRefreshToken() {
  const currentTokens = getTokenAndUserId();
  if (currentTokens) {
    const newAxios = axios.create({ baseURL: BASE_URL });
    const { userId } = currentTokens;

    //avoiding loop in interceptors
    return newAxios
      .post("/v1/auth/refreshtoken", mapKeysToSnakeCase(currentTokens))
      .then((result) => mapKeysToCamelCase(result.data))
      .then((result2) => ({ ...result2, userId }));
  } else {
    throw new Error("No tokens in storage to refresh");
  }
}
