import { getAxiosInstance } from "./axiosProvider";
import {
  mapObjectArrayToCamelCase,
  unknownErrorHandlerFactory,
  mapKeysToSnakeCase,
  removeNullValuesObjArray,
  promiseLogger,
} from "../services/utils";
import _ from "lodash";

export async function fetchWorkingPositions() {
  return await getAxiosInstance()
    .get("/v1/workingpositions")
    .then((result) => result.data)
    .then(mapObjectArrayToCamelCase)
    .then(removeNullValuesObjArray);
  //.then( promiseLogger )
}

export async function createWorkingPosition(workingPosition) {
  return await getAxiosInstance().post(
    "/v1/workingpositions",
    mapKeysToSnakeCase(_.omitBy(workingPosition, _.isNil)),
  );
}

export async function updateWorkingPosition(workingPosition) {
  const { workingPositionId, ...rest } = workingPosition;

  return await getAxiosInstance().patch(
    `/v1/workingpositions/${workingPositionId}`,
    mapKeysToSnakeCase(_.omit(rest, ["createdAt"])),
  );
}

export async function deleteWorkingPosition(workingPosition) {
  return await getAxiosInstance().delete(
    `/v1/workingpositions/${workingPosition.workingPositionId}`,
  );
}
