import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import tableIcons from "../../constants/tableIcons";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import RegistrationsDialog from "./RegistrationDialog";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { unknownErrorThanActionHandlerFactory } from "../../services/utils";
import * as dateFormat from "../../services/dateUtils";
import { LinearProgress } from "@material-ui/core";
export default function RegistrationsTable(props) {
  const [allRegistrations, setAllRegistrations] = React.useState([]);
  const [legalPeople, setLegalPeople] = React.useState([]);
  const [physicalPeople, setPhysicalPeople] = React.useState([]);
  const [vehicleTypes, setVehicleTypes] = React.useState([]);
  const [insuranceCompanies, setInsuranceCompanies] = React.useState([]);
  const [insurance, setInsurance] = React.useState(undefined);
  const [vehicle, setVehicle] = React.useState(undefined);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  // const [isDialogOpenAddLegal, setIsDialogOpenAddLegal] = React.useState(false);
  const [isDialogOpenEdit, setIsDialogOpenEdit] = React.useState(false);
  const [client_type, setClient_type] = React.useState(undefined);
  const [client, setClinet] = React.useState(undefined);

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [selectedDateExp, setSelectedDateExp] = React.useState(new Date());
  const [selectedDateSix, setSelectedDateSix] = React.useState(new Date());
  const [selectedDateTlexp, setSelectedDateTlexp] = React.useState(new Date());
  const [selectedDateTacho, setSelectedDateTacho] = React.useState(new Date());

  const [paymentTypes, setPaymentTypes] = React.useState([]);
  const [companyLines, setCompanyLines] = React.useState([]);
  const [stickerIssuePlace, setStickerIssuePlace] = React.useState();
  const [line, setLine] = React.useState(undefined);
  const [payment, setPayment] = React.useState(undefined);
  const [novi, setNew] = React.useState([]);
  const tableRef = React.useRef();
  const [nekiTest, setNekiTest] = React.useState("");
  const userId = useSelector((store) => store.auth.userId);

  const handleChangeClientType = (event) => {
    setClient_type(event.target.value);
  };
  const handleChangeClient = (event) => {
    setClinet(event);
  };
  const handleChangeClientEdit = (event) => {
    setClinet(event);
  };
  // dodaj novi hendler koji ce preko value da kontrolise dialog.....
  const handleDialogClose = () => {
    setLine(undefined);
    // setClient_type(undefined);
    setClinet(undefined);
    setVehicle(undefined);
    setIsDialogOpen(false);
  };
  // const handleDialogCloseAddLegal = () => {
  //   setIsDialogOpenAddLegal(false);
  // }
  const handleDialogCloseEdit = () => {
    // setClient_type(undefined);
    setClinet(undefined);
    setVehicle(undefined);
    setIsDialogOpenEdit(false);
  };
  const handleChangeVehicleType = (event) => {
    setVehicle(event.target.value);
  };
  const handleChangeInsuranceCompany = (event) => {
    setInsurance(event.target.value);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleDateChangeExp = (date) => {
    setSelectedDateExp(date);
  };
  const handleDateChangeSix = (date) => {
    setSelectedDateSix(date);
  };
  const handleDateChangeTlexp = (date) => {
    setSelectedDateTlexp(date);
  };
  const handleDateChangeTacho = (date) => {
    setSelectedDateTacho(date);
  };
  const handleChangePaymentType = (event) => {
    setPayment(event.target.value);
  };
  const handleChangeCompanyLine = (event) => {
    setLine(event.target.value);
  };
  const handleChangeStickerPlaceIssue = (event) => {
    setStickerIssuePlace(event.target.value);
  };
  useEffect(() => {
    loadAllRegistrations();
  }, []);

  const loadAllRegistrations = async () => {
    setModalOpen(true);
    let result = await getAxiosInstance()
      .get(`/v1/registrations`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    result = result?.map((el) => {
      return {
        ...el,
        client_name:
          el?.Client?.PhysicalPerson === null
            ? el?.Client?.LegaPerson?.legal_name
            : el?.Client?.PhysicalPerson?.full_name,
        registration_date1: dateFormat.formatDate(el.registration_date),
      };
    });
    setModalOpen(false);
    setAllRegistrations(result);
  };
  const loadLegalPerson = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/legalpeople`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setLegalPeople(result);
  };
  const loadPhysicalPeople = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/physicalpeople`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setPhysicalPeople(result);
  };
  const loadVehicleTypes = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/vehicletypes`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setVehicleTypes(result);
  };
  const loadInsuranceCompanies = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/insurancecompanies`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setInsuranceCompanies(result);
  };
  const loadPaymentTypes = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/paymenttypes`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setPaymentTypes(result);
  };
  const loadCompanyLines = async () => {
    let result = await getAxiosInstance()
      .get(`/v1/companylines`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setCompanyLines(result);
  };

  const TableColumns = [
    { title: "Reg. oznaka", field: "registration_plate" },
    { title: "Cena osiguranja", field: "insurance_price" },
    { title: "Cena Usluge", field: "technical_inspection_price" },
    { title: "Datum registracije", field: "registration_date1" },
    { title: "Ime klijenta", field: "client_name" },
  ];

  const submitHandler = async (data) => {
    await getAxiosInstance()
      .post("v1/registrations", {
        client_id_fk: client,
        registration_plate: data.registration_plate,
        vehicle_type_id_fk: vehicle,
        insurance_company_id_fk: insurance,
        insurance_price: data.insurance_price,
        agency_service_price: data.agency_service_price,
        technical_inspection_price: data.technical_inspection_price,
        insurance_policy_number: data.insurance_policy_number,
        sticker_issue_place: data.sticker_issue_place,
        payment_type_id_fk: payment,
        registration_date: selectedDate.getTime(),
        registration_exp_date: selectedDateExp.getTime(),
        six_month_exp_date: selectedDateSix.getTime(),
        traffic_license_exp_date: selectedDateTlexp.getTime(),
        tachograph_exp_date: selectedDateTacho.getTime(),
        user_id_fk: userId,
        company_line_id_fk: line,
        processed_by: data.processed_by,
        associate: data.associate,
        comment: data.comment,
        correctness: 1,
      })
      .then(() => {
        handleDialogClose();
        //tableRef.current.onQueryChange();
        loadAllRegistrations();
      })
      .catch(errorHandlerThanAction);
  };

  const updateRegistration = async (data) => {
    getAxiosInstance()
      .patch(`v1/registrations/${novi.registration_id}`, {
        client_id_fk: client,
        registration_plate: data.registration_plate,
        vehicle_type_id_fk: vehicle,
        insurance_company_id_fk: insurance,
        insurance_price: data.insurance_price,
        agency_service_price: data.agency_service_price,
        technical_inspection_price: data.technical_inspection_price,
        insurance_policy_number: data.insurance_policy_number,
        sticker_issue_place: stickerIssuePlace,
        payment_type_id_fk: payment,
        registration_date: selectedDate,
        registration_exp_date: selectedDateExp,
        six_month_exp_date: selectedDateSix,
        traffic_license_exp_date: selectedDateTlexp,
        tachograph_exp_date: selectedDateTacho,
        user_id_fk: userId,
        company_line_id_fk: line,
        processed_by: data.processed_by,
        associate: data.associate,
        comment: data.comment,
        correctness: 1,
      })
      .then((res) => {
        loadAllRegistrations();
        handleDialogCloseEdit();
      })
      .catch(errorHandlerThanAction);
  };

  const [modalOpen, setModalOpen] = useState(false);

  const deleteUserHandler = async (newData) => {
    getAxiosInstance()
      .delete(`v1/registrations/${newData.registration_id}`)
      .then((res) => {
        loadAllRegistrations();
      })
      .catch(errorHandlerThanAction);
  };
  const dispatch = useDispatch();
  const errorHandlerThanAction = unknownErrorThanActionHandlerFactory(dispatch);

  useEffect(() => {
    // console.log("Props na registration dialog - legal people", legalPeople);
  }, [legalPeople]);

  return (
    <>
      {modalOpen && <LinearProgress style={{ marginBottom: "10px" }} />}
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsDialogOpen(true);
            loadLegalPerson();
            loadPhysicalPeople();
            loadVehicleTypes();
            loadInsuranceCompanies();
            loadPaymentTypes();
            loadCompanyLines();
          }}
        >
          Dodaj registraciju
        </Button>
        <MaterialTable
          detailPanel={(rowData) => {
            return (
              <div>
                <p>{rowData?.Employee?.phone_number}</p>
                <p>{rowData?.CompanyLine?.name}</p>
                <p>{rowData?.PaymentType?.name}</p>
                <p>{rowData?.InsuranceCompny?.name}</p>
                <p>{rowData?.VehicleType?.name}</p>
              </div>
            );
          }}
          tableRef={tableRef}
          columns={TableColumns}
          data={allRegistrations}
          title="Registracije"
          icons={tableIcons}
          // onRowClick={(event, rowData) => console.log(rowData)}
          localization={{
            header: { actions: "Akcije" },
            toolbar: { searchPlaceholder: "Pretraži" },
            body: {
              editTooltip: "Izmeni",
              editRow: {
                saveTooltip: "Sačuvaj",
                cancelTooltip: "Poništi",
                deleteText:
                  "Da li ste sigurni da želite da obrišete registraciju?",
              },
              deleteTooltip: "Obrisi",
              deleteRow: { saveTooltip: "Sačuvaj", cancelTooltip: "Poništi" },
              emptyDataSourceMessage: "Nema registracija za prikazivanje",
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} od {count}",
              labelRowsSelect: "redova",
              firstTooltip: "Prva stranica",
              lastTooltip: "Zadnja stranica",
              nextTooltip: "Sledeća stranica",
              previousTooltip: "Predhodna stranica",
            },
          }}
          actions={[
            {
              icon: tableIcons.Edit,
              tooltip: "Izmeni",
              onClick: (event, rowData) => {
                loadLegalPerson();
                loadPhysicalPeople();
                loadVehicleTypes();
                loadInsuranceCompanies();
                loadPaymentTypes();
                loadCompanyLines();
                setNew(rowData);
                setClient_type(rowData?.Client?.client_type);
                setNekiTest(rowData?.Client?.client_type);
                setStickerIssuePlace(rowData?.sticker_issue_place);
                setSelectedDate(rowData.registration_date);
                setSelectedDateExp(rowData.registration_exp_date);
                setSelectedDateSix(rowData.six_month_exp_date);
                setSelectedDateTlexp(rowData.traffic_license_exp_date);
                setSelectedDateTacho(rowData.tachograph_exp_date);
                // setNekiTest(rowData?.client_type)
                setTimeout(() => {
                  setIsDialogOpenEdit(true);
                }, 200);
                // let newObject = novi.map((el, i) => {
                //   return {
                //     client: el.locationClient.client_type,
                //     VehicleType: el.VehicleType.vehicle_type_id
                //   }
                // })
                // setNew(newObject)
              },
            },
          ]}
          options={{
            headerStyle: { backgroundColor: "#D1D1D1", fontWeight: "bold" },
            pageSizeOptions: [10, 15, 20],
            pageSize: 10,
          }}
          editable={{
            // onRowUpdate: (newData, oldData) => updateUserHandler(newData, oldData),
            onRowDelete: (newData, oldData) =>
              deleteUserHandler(newData, oldData),
          }}
        />
        {isDialogOpen ? (
          <RegistrationsDialog
            isNew={true}
            // novi={novi}
            registrationDate={selectedDate}
            handleDate={handleDateChange}
            setRegistrationDate={setSelectedDate}
            registrationDateExp={selectedDateExp}
            handleDateExp={handleDateChangeExp}
            setRegistrationDateExp={setSelectedDateExp}
            registrationDateSix={selectedDateSix}
            handleDateSix={handleDateChangeSix}
            setRegistrationDateSix={setSelectedDateSix}
            registrationDateTlexp={selectedDateTlexp}
            handleDateTlexp={handleDateChangeTlexp}
            setRegistrationDateTlexp={setSelectedDateTlexp}
            registrationDateTacho={selectedDateTacho}
            handleDateTacho={handleDateChangeTacho}
            setRegistrationDateTacho={setSelectedDateTacho}
            title={"Dodaj registraciju"}
            isOpen={isDialogOpen}
            loadLegalPerson={loadLegalPerson}
            loadPhysicalPeople={loadPhysicalPeople}
            legalPeople={legalPeople}
            physicalPeople={physicalPeople}
            client_type={client_type}
            handleChangeClientType={handleChangeClientType}
            client={client}
            handleChangeClient={handleChangeClient}
            vehicleTypes={vehicleTypes}
            handleChangeVehicleType={handleChangeVehicleType}
            vehicle={vehicle}
            insuranceCompanies={insuranceCompanies}
            handleChangeInsuranceCompany={handleChangeInsuranceCompany}
            date={selectedDate}
            paymentTypes={paymentTypes}
            companyLines={companyLines}
            line={line}
            handleChangeCompanyLine={handleChangeCompanyLine}
            handleChangeStickerPlaceIssue={handleChangeStickerPlaceIssue}
            stickerIssuePlace={stickerIssuePlace}
            handleChangePaymentType={handleChangePaymentType}
            handleDialogClose={handleDialogClose}
            submitHandler={submitHandler}
          />
        ) : null}
        {isDialogOpenEdit ? (
          <RegistrationsDialog
            registrationDate={selectedDate}
            handleDate={handleDateChange}
            setRegistrationDate={setSelectedDate}
            registrationDateExp={selectedDateExp}
            handleDateExp={handleDateChangeExp}
            setRegistrationDateExp={setSelectedDateExp}
            registrationDateSix={selectedDateSix}
            handleDateSix={handleDateChangeSix}
            setRegistrationDateSix={setSelectedDateSix}
            registrationDateTlexp={selectedDateTlexp}
            handleDateTlexp={handleDateChangeTlexp}
            setRegistrationDateTlexp={setSelectedDateTlexp}
            registrationDateTacho={selectedDateTacho}
            handleDateTacho={handleDateChangeTacho}
            setRegistrationDateTacho={setSelectedDateTacho}
            novi={novi}
            title={"Izmeni registraciju"}
            isOpenEdit={isDialogOpenEdit}
            loadLegalPerson={loadLegalPerson}
            loadPhysicalPeople={loadPhysicalPeople}
            legalPeople={legalPeople}
            physicalPeople={physicalPeople}
            client_type={client_type}
            nekiTest={nekiTest}
            handleChangeClientType={handleChangeClientType}
            client={client}
            handleChangeClient={handleChangeClient}
            handleChangeClientEdit={handleChangeClientEdit}
            vehicleTypes={vehicleTypes}
            handleChangeVehicleType={handleChangeVehicleType}
            vehicle={vehicle}
            insuranceCompanies={insuranceCompanies}
            handleChangeInsuranceCompany={handleChangeInsuranceCompany}
            date={selectedDate}
            paymentTypes={paymentTypes}
            companyLines={companyLines}
            line={line}
            handleChangeCompanyLine={handleChangeCompanyLine}
            stickerIssuePlace={stickerIssuePlace}
            handleChangeStickerPlaceIssue={handleChangeStickerPlaceIssue}
            handleChangePaymentType={handleChangePaymentType}
            handleDialogClose={handleDialogCloseEdit}
            submitHandler={updateRegistration}
          />
        ) : null}
      </div>
    </>
  );
}
