import MaterialList from "../common/MaterialList";
import {
  weakNotEmptyFieldValidationFactory,
  isTotallyEmpty,
} from "../../services/validationUtils";
import { useSimpleErrorHandler } from "../../hooks/useErrorHandlerHook";
import useDispatch from "react-redux";
import { useState } from "react";
import {
  fetchWorkingPositions,
  createWorkingPosition,
  updateWorkingPosition,
  deleteWorkingPosition,
} from "../../data-source/workingPositionsDataSource";
import _ from "lodash";
import isEmpty from "validator/lib/isEmpty";

/*
const testData = [
  {
    name : "Tehničar",
    description : "Radnik na liniji tehničkog pregleda",        
  },
  {
    name : "Racunovođa",
    description : "Računovodstveni poslovi u okviru firme, evidencija prometa,...",  
  }

];
*/

export default function WorkingPositions(props) {
  const errorHandler = useSimpleErrorHandler();
  const [data, setData] = useState([]);

  useState(() => {
    fetchWorkingPositions().then(setData).catch(errorHandler);
  }, []);

  function sanitizeRow(rowData) {
    return {
      ...rowData,
      description:
        rowData.description != null
          ? rowData.description.trim()
          : rowData.description,
    };
  }

  async function onAddWorkingPosigion(rowData) {
    const { name = null, description = null } = rowData;
    if (isTotallyEmpty(name)) return errorHandler("Polje naziv je obavezno");

    return createWorkingPosition(sanitizeRow(rowData))
      .then(fetchWorkingPositions)
      .then(setData)
      .catch(errorHandler);
  }

  async function onUpdateWorkingPosition(rowData) {
    const { name = null } = rowData;
    if (isTotallyEmpty(name)) return errorHandler("Polje naziv je obavezno");

    return updateWorkingPosition(sanitizeRow(rowData))
      .then(fetchWorkingPositions)
      .then(setData)
      .catch(errorHandler);
  }

  async function onDeleteWorkingPosition(rowData) {
    return deleteWorkingPosition(rowData)
      .then(fetchWorkingPositions)
      .then(setData)
      .catch(errorHandler);
  }

  const columns = [
    {
      title: "Naziv",
      field: "name",
      validate: weakNotEmptyFieldValidationFactory("name"),
    },
    {
      title: "Opis",
      field: "description",
    },
  ];

  return (
    <MaterialList
      columns={columns}
      data={data}
      title="Radne pozicije"
      editable={{
        onRowAdd: onAddWorkingPosigion,
        onRowUpdate: onUpdateWorkingPosition,
        onRowDelete: onDeleteWorkingPosition,
      }}
      localization={{
        buttons: {
          addText: "Dodaj",
          cancelText: "Poništi",
          updateText: "Izmeni",
        },
      }}
      options={{
        listStyle: {
          width: "70ch",
          marginBottom: 2,
        },
      }}
    />
  );
}
