import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import tableIcons from "../../constants/tableIcons";
import { useDispatch } from "react-redux";
import { unknownErrorThanActionHandlerFactory } from "../../services/utils";
import Button from "@material-ui/core/Button";
import { LinearProgress } from "@material-ui/core";

export default function InsuranceCompanies() {
  const [allCompanies, setAllCompanies] = React.useState([]);
  const tableRef = React.useRef();
  const [openModal, setModalOpen] = useState(false);

  const loadAllExpenses = async () => {
    setModalOpen(true);
    let result = await getAxiosInstance()
      .get(`/v1/cash2`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    setModalOpen(false);
    setAllCompanies(result);
  };
  useEffect(() => {
    loadAllExpenses();
  }, []);

  const TableColumns = [
    {
      title: "Datum",
      type: "date",
      field: "date",
      validate: (rowData) =>
        rowData.date === ""
          ? { isValid: false, helperText: "Polje ne može biti prazno" }
          : true,
      render: (rowData) => new Date(rowData.date).toLocaleDateString("sr-RS"),
    },
    {
      title: "Iznos",
      field: "amount",
      validate: (rowData) =>
        rowData.amount === ""
          ? { isValid: false, helperText: "Polje ne može biti prazno" }
          : true,
    },
  ];

  const dispatch = useDispatch();
  const errorHandlerThanAction = unknownErrorThanActionHandlerFactory(dispatch);

  const updateCompaniesHandler = async (newData) => {
    const { date = null, amount = null } = newData;

    try {
      if (date == null) {
        errorHandlerThanAction("Polje Datum je obavezno");
        throw new Error("Greška u polju datum");
      }
      if (amount == null) {
        errorHandlerThanAction("Polje iznos je obavezno");
        throw new Error("Greška u polju iznos");
      }
    } catch (error) {
      console.log(error);
      throw error;
    }

    await getAxiosInstance()
      .patch(`/v1/cash2/${newData.cash2_id}`, {
        date: newData.date,
        amount: newData.amount,
      })
      .then(() => {
        loadAllExpenses();
      })
      .catch(errorHandlerThanAction);
  };

  const deleteCompaniesHandler = async (newData) => {
    return getAxiosInstance()
      .delete(`/v1/cash2/${newData.cash2_id}`)
      .then(() => {
        loadAllExpenses();
      })
      .catch(errorHandlerThanAction);
  };

  const addCompaniesHandler = async (data) => {
    const { date = null, amount = null } = data;

    if (date == null) {
      errorHandlerThanAction("Polje Datum je obavezno");
      throw new Error("Greška u polju datum");
    }
    if (amount == null) {
      errorHandlerThanAction("Polje iznos je obavezno");
      throw new Error("Greška u polju iznos");
    }
    await getAxiosInstance()
      .post(`/v1/cash2`, {
        date: data.date.getTime(),
        amount: data.amount,
      })
      .then(() => {
        loadAllExpenses();
      })
      .catch(errorHandlerThanAction);
  };

  const total = allCompanies.reduce((acc, curr) => acc + curr.amount, 0);

  const onClickHandler = (event) => {
    document
      .querySelector("[data-mycustomid='add-icon-handler']")
      .parentNode.click();
  };

  return (
    <>
      {openModal && <LinearProgress style={{ marginBottom: "10px" }} />}
      <div>
        <Button variant="contained" color="primary" onClick={onClickHandler}>
          DODAJ keš 2
        </Button>
        <MaterialTable
          tableRef={tableRef}
          columns={TableColumns}
          data={allCompanies}
          title="Keš 2"
          options={{
            headerStyle: { backgroundColor: "#D1D1D1", fontWeight: "bold" },
          }}
          icons={{
            ...tableIcons,
            Add: (props) => (
              <button
                style={{ display: "none" }}
                disabled
                data-mycustomid={"add-icon-handler"}
              ></button>
            ),
          }}
          localization={{
            toolbar: { searchPlaceholder: "Pretraži" },
            body: {
              editTooltip: "Izmeni",
              editRow: {
                saveTooltip: "Sačuvaj",
                cancelTooltip: "Poništi",
                deleteText: "Da li ste sigurni da želite da obrišete keš 2",
              },
              deleteTooltip: "Obriši",
              addTooltip: "Dodaj",
            },
            header: { actions: "Akcije" },
          }}
          editable={{
            onRowAdd: (newData, oldData) =>
              addCompaniesHandler(newData, oldData),
            onRowUpdate: (newData, oldData) =>
              updateCompaniesHandler(newData, oldData),
            onRowDelete: (newData, oldData) =>
              deleteCompaniesHandler(newData, oldData),
          }}
        />
        <div>Ukupna suma: {total}</div>
      </div>
    </>
  );
}
