const ACTIONS = {
  MESSAGE: {
    SHOW: "SHOW",
    HIDE_AND_REMOVE: "HIDE_AND_REMOVE",
  },
  AUTH: {
    LOG_IN: "LOG_IN_LOCALLY",
    LOG_OUT: "LOG_OUT",
    SET_FETCHING_USER: "SET_FETCHING_USER",
    CLEAR_USER: "CLEAR_USER",
  },
};

export default ACTIONS;
export const { MESSAGE: MESSAGE_ACTIONS, AUTH: AUTH_ACTIONS } = ACTIONS;
