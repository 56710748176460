import { SvgIcon } from "@material-ui/core";

export default function InsuranceCompanies(props) {
  return (
    <SvgIcon
      id="Layer_6"
      enable-background="new 0 0 64 64"
      height="52"
      viewBox="0 0 64 64"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m43 21.855v2.145h2v-2.145c1.155-.366 2-1.435 2-2.709 0-1.088-.604-2.066-1.578-2.553l-1.949-.975c-.292-.145-.473-.438-.473-.764 0-.471.384-.854.854-.854h.291c.471 0 .855.383.855.854v.146h2v-.146c0-1.274-.845-2.343-2-2.709v-2.145h-2v2.145c-1.155.366-2 1.435-2 2.709 0 1.088.604 2.066 1.578 2.553l1.949.975c.292.145.473.438.473.764 0 .471-.384.854-.854.854h-.291c-.471 0-.855-.383-.855-.854v-1.146h-2v1.146c0 1.274.845 2.343 2 2.709z" />
        <path d="m21 55h6v6h-6z" />
        <path d="m13 55h6v6h-6z" />
        <path d="m40.935 30.444c1.953.737 4.179.737 6.128.001 6.78-2.555 9.937-5.238 9.937-8.445 0-2.414-1.104-9.339-2.048-14.537l-.448-2.463h-2.032c-2.914 0-5.832-.652-8.472-1.889-2.64 1.237-5.558 1.889-8.472 1.889h-2.032l-.448 2.464c-.944 5.197-2.048 12.122-2.048 14.536 0 3.207 3.157 5.89 9.935 8.444zm-4.935-22.444h1.394c2.351 0 4.65-.696 6.606-2 1.956 1.304 4.255 2 6.606 2h1.394s2 11 2 14c0 2.376-5.646 4.752-7.994 5.638-.642.242-1.32.362-2.006.362s-1.364-.12-2.006-.362c-2.348-.886-7.994-3.262-7.994-5.638 0-3 2-14 2-14z" />
        <path d="m25.184 37h-21.184c-.552 0-1 .449-1 1v2c0 .551.448 1 1 1h23v-2.184c-.847-.302-1.514-.969-1.816-1.816z" />
        <path d="m5 61h6v-8h16v-3h-18v-4h18v-3h-22z" />
        <path d="m60 33h-32c-.552 0-1 .449-1 1v2c0 .551.448 1 1 1h32c.552 0 1-.449 1-1v-2c0-.551-.448-1-1-1z" />
        <path d="m37 51h6v10h-6z" />
        <path d="m29 61h6v-12h18v12h6v-22h-30zm4-19h22v4h-22z" />
        <path d="m45 51h6v10h-6z" />
        <path d="m7.104 21.613c.456-5.607 2.449-9.192 4.087-11.236-4.707 1.247-8.191 5.53-8.191 10.623v.537c.589-.342 1.272-.537 2-.537.774 0 1.491.231 2.104.613z" />
        <path d="m11 21c.737 0 1.41.222 2 .574v13.426h2v-13.426c.59-.352 1.263-.574 2-.574.681 0 1.312.187 1.874.488-.622-6.721-3.61-10.079-4.874-11.206-1.264 1.128-4.252 4.485-4.874 11.206.562-.301 1.193-.488 1.874-.488z" />
        <path d="m23 21c.728 0 1.411.195 2 .537v-.537c0-5.093-3.484-9.376-8.191-10.623 1.638 2.043 3.631 5.629 4.087 11.236.613-.382 1.33-.613 2.104-.613z" />
      </g>
    </SvgIcon>
  );
}
