import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { mergeBoolean } from "./materiaLlistUtils";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import FolderIcon from "@material-ui/icons/Folder";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Edit from "@material-ui/icons/Edit";
import { useEffect } from "react";
import ML_EVENTS from "./materialListConstants";

export default function MLList(props) {
  const { dfltProps: defaultProps } = props;
  const {
    columns,
    icons = defaultProps.icons,
    options = defaultProps.options,
    editable = defaultProps.options,
  } = props;
  const {
    onRowAdd = undefined,
    onRowUpdate = undefined,
    onRowDelete = undefined,
  } = props.editable;

  const onEditClickHandlerFactory = (lmdEditIndex) => (event) => {
    if (props.editIndex !== lmdEditIndex) {
      props.editHandler(lmdEditIndex);
    }
  };

  const onDeleteClickHandlerFactory = (lmdDeleteIndex) => (event) => {
    props.deleteHandler(lmdDeleteIndex);
  };

  return (
    <List style={props.style} className={props.className}>
      {props.data.map((dataItem, index, array) => {
        const isPrimarySelect = "lookup" in columns[0];
        const primaryText =
          columns.length > 0 &&
          (isPrimarySelect
            ? columns[0].lookup[dataItem[columns[0].field]]
            : dataItem[columns[0].field]);
        const isSecondarySelect = columns.length > 1 && "lookup" in columns[1];
        const secondaryText =
          columns.length > 1 &&
          (isSecondarySelect
            ? columns[1].lookup[dataItem[columns[1].field]]
            : dataItem[columns[1].field]);

        return (
          <>
            <ListItem selected={index === props.editIndex}>
              <ListItemText primary={primaryText} secondary={secondaryText} />
              {
                <ListItemSecondaryAction>
                  {editable.onRowUpdate && (
                    <IconButton
                      edge="end"
                      onClick={onEditClickHandlerFactory(index)}
                    >
                      {(icons.Edit && <icons.Edit />) ||
                        (defaultProps.icons.Edit && (
                          <defaultProps.icons.Edit />
                        ))}
                    </IconButton>
                  )}
                  {editable.onRowDelete && (
                    <IconButton
                      edge="end"
                      onClick={onDeleteClickHandlerFactory(index)}
                    >
                      {(icons.Delete && <icons.Delete />) ||
                        (defaultProps.icons.Delete && (
                          <defaultProps.icons.Delete />
                        ))}
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              }
            </ListItem>

            {mergeBoolean(defaultProps.options.divider, options.divider) &&
              array.length - 1 > index && <props.components.Divider />}
          </>
        );
      })}
    </List>
  );
}
