import ms from "ms";
import { LOGOUT_SERVICE_SETTINGS } from "../Settings";
import { STORAGE_KEYS } from "../constants/appConstants";
import isPast from "date-fns/isPast";
import store from "../data-source/store";
import { logOutLocally } from "../actions/authActions";
import debug from "../services/debugUtils";

class RefreshTokenLogoutService {
  constructor(refreshTimeout) {
    this.refreshTimeout =
      refreshTimeout || ms(LOGOUT_SERVICE_SETTINGS.LOGOUT_CHECK_INTERVAL);
    this.intervalHandle = null;
  }

  _internalCheckHandler() {
    debug.log("RefreshTokenLougoutService Running");
    const expiresIn = window.localStorage.getItem(
      STORAGE_KEYS.REFRESH_TOKEN_EXPIRES_IN,
    );
    if (expiresIn != null) {
      const expiresInDate = new Date(ms(expiresIn));

      if (isPast(expiresInDate)) {
        store.dispatch(logOutLocally());
      }
    }
  }

  start(newRefreshTimeout) {
    this.stop();

    if (newRefreshTimeout) {
      this.refreshTimeout = newRefreshTimeout;
    }

    this.intervalHandle = setInterval(
      this._internalCheckHandler,
      this.refreshTimeout,
    );
    debug.log("RefreshTokenLougoutService Started");
  }

  stop() {
    if (this.intervalHandle != null) {
      clearInterval(this.intervalHandle);
      this.intervalHandle = null;

      debug.log("RefreshTokenLougoutService Stopped");
    }
  }

  startOrRestart(newRefreshTimeout) {
    this.start(newRefreshTimeout);
  }
}

let refreshTokenLogoutService = new RefreshTokenLogoutService();

export function getRefreshTokenLogoutService() {
  return refreshTokenLogoutService;
}
