import { useDispatch } from "react-redux";
import {
  unknownErrorThanActionHandlerFactory,
  unknownErrorHandlerFactory,
} from "../services/utils";
import { useCallback } from "react";

export default function useErrorHandler() {
  const dispatch = useDispatch();
  const errorThenActionHandler = useCallback(
    unknownErrorThanActionHandlerFactory(dispatch),
    [dispatch],
  );

  return errorThenActionHandler;
}

export function useSimpleErrorHandler() {
  const dispatch = useDispatch();
  const errorHandler = useCallback(unknownErrorHandlerFactory(dispatch), [
    dispatch,
  ]);

  return errorHandler;
}
