import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import RegistrationTraffic from "./RegistrationTraffic";
import FinanceTraffic from "./FinanceTraffic";
import ClientTrafic from "./ClientTraffic";
import FinanceTrafficIspostava from "./FinanceTrafficIspostava";
import Cash1 from "./Cash1";
import Cash2 from "./Cash2";
import Cash1nCash2 from "./Cash1nCash2";
import { Button } from "@material-ui/core";
import ReactToPrint from "react-to-print";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Reports(props) {
  const componentToPrint = useRef(null);
  const classes = useStyles();
  const reactToPrintContent = React.useCallback(() => {
    return componentToPrint.current;
  }, [componentToPrint.current]);

  const reactToPrintTrigger = React.useCallback(() => {

    return  <Button
      className={classes.formControl}
      variant="contained"
      color="primary"
    >Štampaj
  </Button>;
  }, []);
  return (
    <div>
       <Grid xs={6} sm={3}>
        <ReactToPrint
          content={reactToPrintContent}
          documentTitle="Test Name"
          // onBeforeGetContent={handleOnBeforeGetContent}
          removeAfterPrint
          trigger={reactToPrintTrigger}
        />
        </Grid>
      <div ref={componentToPrint}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <RegistrationTraffic />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <FinanceTraffic />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <ClientTrafic />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <FinanceTrafficIspostava />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Cash1 />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Cash2 />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper}>
            <Cash1nCash2 />
          </Paper>
        </Grid>
      </Grid>
      </div>
    </div>
  );
}

export default Reports;
