import { showMessage } from "../actions/messageActions";
import MESSAGES_TYPES from "../constants/messagesTypes";
import camelCase from "camelcase";
import { snakeCase } from "snake-case";
import _, { isEmpty } from "lodash";

function extractErrorString(error) {
  let tempErrorMsg = (
    Array.isArray(error.response.data)
      ? error.response.data[0]
      : error.response.data
  )
    .toString()
    .trim();
  tempErrorMsg = tempErrorMsg.replace(/^\s*Error\s*:*\s*/i, "");
  return tempErrorMsg;
}

export const errorHandlerFactory = (dispatch) => (error) => {
  if (error?.response?.data != null) {
    dispatch(showMessage(extractErrorString(error), MESSAGES_TYPES.ERROR));
  } else {
    dispatch(
      showMessage(
        error.request ? "Server not response" : error.message,
        MESSAGES_TYPES.ERROR,
      ),
    );
  }
};

export const unknownErrorThanActionHandlerFactory =
  (dispatch) => (error, actionFunctionOrObj) => {
    let dispatchResult;

    if (error instanceof Error) {
      if (error?.response?.data != null) {
        dispatchResult = dispatch(
          showMessage(extractErrorString(error), MESSAGES_TYPES.ERROR),
        );
      } else {
        dispatchResult = dispatch(
          showMessage(error.message, MESSAGES_TYPES.ERROR),
        );
      }
    } else if (typeof error === "string") {
      dispatchResult = dispatch(showMessage(error, MESSAGES_TYPES.ERROR));
    } else {
      dispatchResult = dispatch(showMessage(error, MESSAGES_TYPES.ERROR));
    }

    if (actionFunctionOrObj == null) {
      return dispatchResult;
    } else if (typeof actionFunctionOrObj === "function") {
      return dispatch(actionFunctionOrObj());
    } else {
      return dispatch(actionFunctionOrObj);
    }
  };

export const unknownErrorHandlerFactory = (dispatch) => (error) => {
  if (error instanceof Error) {
    if (error.response && error.response.data) {
      let tempErrorMsg = (
        Array.isArray(error.response.data)
          ? error.response.data[0]
          : error.response.data
      )
        .toString()
        .trim();
      tempErrorMsg = tempErrorMsg.replace(/^\s*Error\s*:*\s*/i, "");

      dispatch(showMessage(tempErrorMsg, MESSAGES_TYPES.ERROR));
    } else {
      dispatch(showMessage(error.message, MESSAGES_TYPES.ERROR));
    }
  } else if (typeof error === "string") {
    dispatch(showMessage(error, MESSAGES_TYPES.ERROR));
  }
};

export function mapKeysToCamelCase(argObject, argExcludeAry) {
  if (typeof argExcludeAry === "undefined" || argExcludeAry === null) {
    argExcludeAry = [];
  }

  if (!(argExcludeAry instanceof Array)) {
    throw new Error("Exclude array is not of array type");
  }

  let resultObj = {};

  let tempResult2 = Object.keys(argObject)
    .map((key) => ({
      original: key,
      cameled: camelCase(key),
      value: argObject[key],
    }))
    .filter(
      (originalAndCameled) =>
        !(
          argExcludeAry.includes(originalAndCameled.original) ||
          argExcludeAry.includes(originalAndCameled.cameled)
        ),
    )
    .forEach((originalAndCameled) => {
      resultObj[originalAndCameled.cameled] = originalAndCameled.value;
    });

  return resultObj;
}

export function mapObjectArrayToCamelCase(objArray, excludeArray) {
  excludeArray = Array.isArray(excludeArray) ? excludeArray : null;
  return objArray.map((data) => mapKeysToCamelCase(data, excludeArray));
}

export function mapKeysToSnakeCase(argObj) {
  const objEntries = Object.entries(argObj).map(([key, value]) => [
    snakeCase(key),
    value,
  ]);
  return Object.fromEntries(objEntries);
}

export function mapObjectArrayToSnakeCase(objArray, excludeArray) {
  excludeArray = Array.isArray(excludeArray) ? excludeArray : null;
  return objArray.map((data) => mapKeysToSnakeCase(data, excludeArray));
}

export function isDefined(arg) {
  return typeof arg !== "undefined";
}

export function isDefinedAndNotNull(arg) {
  return typeof arg !== "undefined" && arg !== null;
}

export function isUndefined(arg) {
  return typeof arg === "undefined";
}

export const EMPTY_STRING = "";

export class ValidationStatus {
  constructor(success, msg) {
    this.success = success;
    this.msg = msg;
  }
}

export function promiseLogger(value) {
  console.log(value);
  return value;
}

export function titledPromiseLogger(argMessage) {
  return function (value) {
    console.log(argMessage);
    return promiseLogger(value);
  };
}

export function splitToObjectsByKeys(argObject, invertedObjNameKeyMap) {
  let splitedObjects = {};
  Object.entries(argObject).forEach(([key, value]) => {
    let objName = invertedObjNameKeyMap[key];
    if (!(objName in splitedObjects)) {
      splitedObjects[objName] = {};
    }
    splitedObjects[objName][key] = value;
  });
  return splitedObjects;
}

export function createInvertedObjectKeyMap(objNameObjMap) {
  let invObjKeyMap = {};
  Object.entries(objNameObjMap).forEach(([objectName, keyArray]) => {
    let tempKeys = keyArray;
    if (typeof keyArray !== "object")
      throw new Error(
        "Values inside objNameObjMap must be array of keys, or object as keys constants",
      );
    if (!Array.isArray(keyArray) && typeof keyArray === "object") {
      tempKeys = Object.values(keyArray);
    }

    tempKeys.forEach((nestedObjKey) => {
      if (!(nestedObjKey in invObjKeyMap)) {
        invObjKeyMap[nestedObjKey] = {};
      }
      invObjKeyMap[nestedObjKey] = objectName;
    });
  });
  return invObjKeyMap;
}

export function excludeFromObjByKeys(argObj, argArrayOfKeys) {
  return _.omit(argObj, argArrayOfKeys);
}

export function excludeFromObjArrayByKeys(argArray, argArrayOfKeys) {
  return argArray.map((el) => _.omit(el, argArrayOfKeys));
}

export function splitToObjBySnakeKeys(
  argObject,
  invertedObjKeyMap,
  excludeArray,
) {
  return splitToObjectsByKeys(
    mapKeysToSnakeCase(
      excludeArray
        ? _.omit(argObject, ["clientId", "physicalClientId"])
        : argObject,
    ),
    invertedObjKeyMap,
  );
}

export function removeNullValuesObjArray(argObjArray) {
  return argObjArray.map((obj) => _.omitBy(obj, _.isNull));
}

export function removeNullValuesObj(argObj) {
  return _.omitBy(argObj, _.isNull);
}

export function pipe(arg) {
  return function () {
    const [first, ...rest] = arguments;
    let temp = first(arg);
    for (let value of rest) {
      temp = value(temp);
    }

    return temp;
  };
}

export function camelCasedObjValues(argObject) {
  return Object.entries(argObject)
    .map(([key, value]) => ({ [key]: camelCase(value) }))
    .reduce((acc, next) => ({ ...acc, ...next }));
}

export function convertArrayToObject(argArray) {
  let tempObject = {};
  for (const [key, value] of argArray.entries()) {
    let stringKey = String(key);
    tempObject = {
      ...tempObject,
      [stringKey]: value,
    };
  }
  console.log("Temp object", tempObject);
  return tempObject;
  //return Object.fromEntries( argArray.entries() );
}

export function convertArrayToObject2(argArray) {
  return Object.fromEntries(argArray.entries());
}

function extractKeyValueFromObjFactory(keyAsKey, keyAsValue) {
  return function (argObject) {
    console.log(
      "Single Object to extract",
      argObject,
      argObject[keyAsKey],
      argObject[keyAsValue],
    );
    return [argObject[keyAsKey], argObject[keyAsValue]];
  };
}

export function convertArrayToObjectAsKeyValueKeys(
  keyAsKey,
  keyAsValue,
  objectArray,
) {
  const objectExtractor = extractKeyValueFromObjFactory(keyAsKey, keyAsValue);
  let temp = objectArray
    .map((lmdObj) => objectExtractor(lmdObj))
    .reduce((acc, [first, second]) => {
      acc[first] = second;
      return acc;
    }, {});
  return temp;
}

export function promiseDecorator(mainFunction) {
  return function (result) {
    mainFunction(result);
    return result;
  };
}

export function isNilOrEmpty(arg) {
  return _.isNil(arg) || arg === "";
}

function emptyToNullCustomizer(something, objValue) {
  if (_.isString(objValue) && objValue.trim() === "") {
    return null;
  }
}

export function replaceEmptyWithNullInObj(argObj) {
  return _.assignWith({}, argObj, emptyToNullCustomizer);
}

function trimCustomizer(somthing, objValue) {
  if (_.isString(objValue)) return objValue.trim();
}

export function trimStringValuesInObj(argObj) {
  return _.assignWith({}, argObj, trimCustomizer);
}

export function unwrapErrorMessage(error) {
  return error?.response?.data.replace("Error:", "").trim();
}

export function joinPath(firstPath, secondPath) {
  if (secondPath.includes("//")) return secondPath;
  if (secondPath.charAt(0) === "/") {
    return firstPath + secondPath;
  } else {
    let tempSecondPath = `\\${secondPath}`;
    return firstPath + tempSecondPath.substring(0);
  }
}

export function isNotEmptyImagePath(imagePath) {
  if (typeof imagePath !== "string") return false;

  return imagePath.trim() !== EMPTY_STRING;
}

const COLORS_LOOKUPTABLE = ["#3194DA", "red", "green", "orange"];
export function getColor(index) {
  return COLORS_LOOKUPTABLE[index] ?? "blue";
}
