export function mergeBoolean(argDefaultValue, argValue) {
  return typeof argValue !== "undefined" ? argValue : argDefaultValue;
}

export function keyForValue(argObj, argValue) {
  let result = Object.entries(argObj).find(([key, value]) => {
    console.log("Key/value", key, value, argValue);
    return value == argValue;
  });
  return result ? result[0] : result;
}
