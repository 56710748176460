import React, { useEffect, useState } from "react";
import tableIcons from "../../../constants/tableIcons";
import { getAxiosInstance } from "../../../data-source/axiosProvider";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { unknownErrorThanActionHandlerFactory } from "../../../services/utils";
import LegalPeopleDialog from "./LegalPeopleDialog";
import MaterialTable from "material-table";
import { LinearProgress } from "@material-ui/core";

export default function LegalPeopleTable() {
  const [legalPeople, setLegalPeople] = React.useState([]);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  // const tableRef = React.useRef();

  const [openModal, setModalOpen] = useState(false);

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  useEffect(() => {
    loadLegalPeople();
  }, []);

  const dispatch = useDispatch();
  const errorHandlerThanAction = unknownErrorThanActionHandlerFactory(dispatch);

  const loadLegalPeople = async () => {
    setModalOpen(true);
    let result = await getAxiosInstance()
      .get(`/v1/legalpeople`)
      .then((res) => res.data)
      .catch((err) => console.log(err));
    result = result?.map((el, i) => {
      return {
        legal_person_id: el.legal_person_id,
        client_id_fk: el.client_id_fk,
        legal_name: el.legal_name,
        town: el.town,
        address: el.address,
        contact_person: el.contact_person,
        // pib: el.pib,
        // mb: el.mb,
        LegalClient_id: el.LegalClient.client_id,
        legalClient_email: el.LegalClient.email,
        legalClient_phone_number: el.LegalClient.phone_number,
      };
    });
    setModalOpen(false);
    setLegalPeople(result);
  };

  const TableColumns = [
    {
      title: "Naziv",
      field: "legal_name",
      validate: (rowData) =>
        rowData.legal_name === "" ? "Obavezno polje" : true,
    },
    { title: "Graad", field: "town" },
    { title: "Adresa", field: "address" },
    { title: "Kontakt osoba", field: "contact_person" },
    { title: "Broj telefona", field: "legalClient_phone_number" },
    // { title: 'PIB', field: 'pib' },
    // { title: 'MB', field: 'mb' }
  ];

  const submitHandler = async (data) => {
    await getAxiosInstance()
      .post("v1/clients", {
        email: data.email,
        phone_number: data.phone_number,
        client_type: "2",
      })
      .then((res) => {
        return getAxiosInstance().post("v1/legalpeople", {
          client_id_fk: res.data.client_id,
          legal_name: data.legal_name,
          town: data.town,
          address: data.address,
          contact_person: data.contact_person,
          pib: data.pib,
          mb: data.mb,
        });
      })
      .then(() => {
        handleDialogClose();
        loadLegalPeople();
      })
      .catch(errorHandlerThanAction);
  };
  const updateLegalPersonHandler = async (newData) => {
    console.log(newData);
    await getAxiosInstance()
      .patch(`v1/clients/${newData.client_id_fk}`, {
        email: newData.legalClient_email,
        phone_number: newData.legalClient_phone_number,
      })
      .then((res) => {
        return getAxiosInstance().patch(
          `v1/legalpeople/${newData.legal_person_id}`,
          {
            legal_name: newData.legal_name,
            town: newData.town,
            address: newData.address,
            contact_person: newData.contact_person,
            pib: newData.pib,
            mb: newData.mb,
          },
        );
      })
      .then(() => {
        loadLegalPeople();
      })
      .catch(errorHandlerThanAction);
  };

  const deleteLegalPersonHandler = async (newData) => {
    getAxiosInstance()
      .delete(`v1/legalpeople/${newData.legal_person_id}`)
      .then((res) => {
        getAxiosInstance().delete(`v1/clients/${newData.client_id_fk}`);
      })
      .then((res) => {
        loadLegalPeople();
      })
      .catch(errorHandlerThanAction);
  };

  return (
    <>
      {openModal && <LinearProgress style={{ marginBottom: "10px" }} />}
      <div>
        <Button
          variant="contained"
          onClick={() => {
            setIsDialogOpen(true);
          }}
          color="primary"
        >
          Dodaj pravno lice
        </Button>
        <MaterialTable
          // tableRef={tableRef}
          columns={TableColumns}
          data={legalPeople}
          title="Pravna lica"
          icons={tableIcons}
          localization={{
            header: { actions: "Akcija" },
            toolbar: { searchPlaceholder: "Pretraži" },
            body: {
              editTooltip: "Izmeni",
              editRow: {
                saveTooltip: "Sačuvaj",
                cancelTooltip: "Poništi",
                deleteText:
                  "Da li ste sigurni da želite da obrišete pravno lice?",
              },
              deleteTooltip: "Obrisi",
              deleteRow: { saveTooltip: "Sačuvaj", cancelTooltip: "Poništi" },
            },
          }}
          options={{
            headerStyle: { backgroundColor: "#D1D1D1", fontWeight: "bold" },
          }}
          editable={{
            onRowUpdate: (newData, oldData) =>
              updateLegalPersonHandler(newData, oldData),
            onRowDelete: (newData, oldData) =>
              deleteLegalPersonHandler(newData, oldData),
          }}
        />
        {isDialogOpen ? (
          <LegalPeopleDialog
            title={"Dodaj pravno lice"}
            isOpen={isDialogOpen}
            handleDialogClose={handleDialogClose}
            submitHandler={submitHandler}
          />
        ) : null}
      </div>
    </>
  );
}
