import axios from "axios";
import { BASE_URL } from "../constants/appConstants";
import {
  tokenEventEmitter,
  getTokenAndUserId,
  refreshTokenAndRefreshToken,
  storeTokenAndUserId,
} from "./authDataSource";
import { TOKEN_EVENTS } from "../constants/eventTypes";
import store from "../data-source/store";
import { logOutLocally } from "../actions/authActions";

let _axiosCreateOptions = {
  baseURL: BASE_URL,
};

let _instance;

let _instanceNoBaseUrl;

export function recreateAxios() {
  const axiosOptTemplate = {
    baseURL: BASE_URL,
  };

  _axiosCreateOptions = {
    ...axiosOptTemplate,
  };

  const tempTokenAndUserId = getTokenAndUserId();

  let tempNoBaseUrl = {};

  if (tempTokenAndUserId) {
    const { token: tempLocalStorageToken } = getTokenAndUserId();
    _axiosCreateOptions.headers = {
      Authorization: `Bearer ${tempLocalStorageToken}`,
    };
    tempNoBaseUrl.headers = { ..._axiosCreateOptions.headers };
  }

  _instance = axios.create(_axiosCreateOptions);
  _instanceNoBaseUrl = axios.create(tempNoBaseUrl);

  _instance.interceptors.response.use(
    responseSucessInterceptor,
    responseErrorInterceptor,
  );
  _instanceNoBaseUrl.interceptors.response.use(
    responseSucessInterceptor,
    responseErrorInterceptor,
  );

  console.log("Axios recreated");
}

function responseSucessInterceptor(response) {
  return response;
}
async function responseErrorInterceptor(error) {
  if (
    error.response &&
    error.response.status == 401 &&
    error.response.data &&
    error.response.data.shouldRefreshToken
  ) {
    try {
      error.response.data = error.response.data.message;
      const { token, userId, refreshToken, refreshTokenExpiresIn } =
        await refreshTokenAndRefreshToken();
      await storeTokenAndUserId(
        token,
        userId,
        refreshToken,
        refreshTokenExpiresIn,
      );

      const tokenAndUserId = getTokenAndUserId();

      /** @type {import('axios').AxiosRequestConfig} */
      const newConfig = {
        ...error.config,
        headers: {
          ...error.config.headers,
          Authorization: `Bearer ${tokenAndUserId.token}`,
        },
      };

      return axios(newConfig);
    } catch (error) {
      setTimeout(() => {
        store.dispatch(logOutLocally());
      }, 750);

      return Promise.reject(error);
    }
  }

  return Promise.reject(error);
}

recreateAxios();

tokenEventEmitter.on(TOKEN_EVENTS.TOKEN_CHANGED, recreateAxios);

function getInstance() {
  return _instance;
}

function getAxiosNoBaseUrl() {
  return _instanceNoBaseUrl;
}

export { getInstance as getAxiosInstance };
export { recreateAxios as recreateAxiosInstance };
export { getAxiosNoBaseUrl };
