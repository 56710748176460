import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../Home/Home";
import Users from "../Users/UsersTable";
import ProfileSettings from "../Profile/Profile";
import InsuranceCompanies from "../InsuranceCompanies/InsuranceCompanies";
import PhysicalPeople from "../Clients/PhysicalPeople/PhysicalPeopleTable";
import LegalPeople from "../Clients/LegalPeople/LegalPeopleTable";
import AppSnackbar from "../common/AppSnackbar";
import RegistrationsTable from "../Registrations/RegistrationsTable";
import PaymentTypes from "../PaymentTypes/PaymentTypes";
import VehicleTypes from "../VehicleTypes/VehicleTypes";
import WorkingPositions from "../Company/WorkingPositions";
import CompanyLines from "../Company/CompanyLine";
import Employees from "../Company/EmployeesTable";
import CompanyBranches from "../Company/CompanyBranchesTable";
import Reports from "../Statistics/Reports";
import Expenses from "../Expenses/expensesTable";
import Cash1 from "../Cash/Cash1Table";
import Cash2 from "../Cash/Cash2Table";

export default function MainContent() {
  return (
    <>
      <Switch>
        <Route path="/home" component={Home} />
        <Route path="/reports" component={Reports} />
        <Route path="/reports2" component={Home} />
        <Route path="/users" component={Users} />
        <Route path="/profile" component={ProfileSettings} />
        <Route path="/phyisicalclient" component={PhysicalPeople} />
        <Route path="/legalclient" component={LegalPeople} />
        <Route path="/insurancecompanies" component={InsuranceCompanies} />
        <Route path="/registrations" component={RegistrationsTable} />
        <Route path="/workingpositions" component={WorkingPositions} />
        <Route path="/companybranches" component={CompanyBranches} />
        <Route path="/companylines" component={CompanyLines} />
        <Route path="/employees" component={Employees} />
        <Route path="/paymenttypes" component={PaymentTypes} />
        <Route path="/vehicletypes" component={VehicleTypes} />
        <Route path="/expenses" component={Expenses} />
        <Route path="/cash1" component={Cash1} />
        <Route path="/cash2" component={Cash2} />
        <Redirect exact from="/login" to="/home" />
      </Switch>
      <AppSnackbar />
    </>
  );
}
