import { forwardRef } from "react";
import Search from "@material-ui/icons/Search";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Edit from "@material-ui/icons/Edit";
import Check from "@material-ui/icons/Check";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AddBox from "@material-ui/icons/AddBox";
import ViewColumn from "@material-ui/icons/ViewColumn";

export const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
};

// FirstPage :(props, ref) => <FirstPage {...props} ref={ref} />
/*
const tableIcons2 = {
    FirstPage: (props, ref) => <FirstPage {...props} ref={ref} />,
    LastPage:(props, ref) => <LastPage {...props} ref={ref} />,
    Search: (props, ref) => <Search {...props} ref={ref} />,
    NextPage: (props, ref) => <ChevronRight {...props} ref={ref} />,
    PreviousPage: (props, ref) => <ChevronLeft {...props} ref={ref} />,
    ResetSearch: (props, ref) => <Clear {...props} ref={ref} />,
};

export function makeTableIcons2(){
    return Object.fromEntries( Object.entries(tableIcons2).map( ([key, value]) =>({ key : forwardRef(value)} )) );
}
*/
export default tableIcons;
