import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import {
  OutlinedInput,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { resetPassword } from "../../../data-source/authDataSource";
import MessageDialog from "./MessageDialog";
import { unwrapErrorMessage } from "../../../services/utils";
import isEmpty from "validator/lib/isEmpty";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  txtField: {
    //minWidth : 420,
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  resetBtnSpace: {
    marginRight: "0.70rem",
  },
  saveBtnSpace: {
    marginRight: "0.25rem",
  },
  groupLabel: {
    marginTop: "0.55rem",
    marginBottom: "0.25rem",
  },
  newAgainPasswordTxtFild: {
    marginTop: "0.35rem",
    marginBottom: "0.5rem",
  },
  serverErrorTextHelper: {
    marginTop: "1.4rem",
  },
}));

const onSendRequestClick = (event) => {
  event.preventDefault();
};

const EDIT_PASSWORD_DEFAULT_STATE = {
  showNewPassword: false,
  showNewAgainPassword: false,
};

const FORM_FIELDS = {
  NEW_PASSWORD: "new_password",
  NEW_AGAIN_PASSWORD: "new_again_password",
};

export default function ResetPassword(props) {
  const classes = useStyles();
  const history = useHistory();

  const [editPasswordState, setEditPasswordState] = useState(
    EDIT_PASSWORD_DEFAULT_STATE,
  );

  const { handleSubmit, errors, register, setError, clearErrors } = useForm();

  const location = useLocation();

  const [dialogOpen, setDialogOpen] = useState(false);
  /*
  useEffect(() => {
    const searchPath = location.search;
    if(searchPath == null || isEmpty( searchPath, { ignore_whitespace : true }) ){
      history.push("/")
    }
  }, []);
*/
  const dialogCloseHandler = () => {
    setDialogOpen(false);
    history.push("/");
  };

  function abort() {
    history.push("/");
  }

  function handleClickShowNewPassword() {
    setEditPasswordState({
      ...editPasswordState,
      showNewPassword: !editPasswordState.showNewPassword,
    });
  }

  function handleClickShowNewAgainPassword() {
    setEditPasswordState({
      ...editPasswordState,
      showNewAgainPassword: !editPasswordState.showNewAgainPassword,
    });
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }

  async function onSubmitHandler(data, e) {
    try {
      await resetPassword(
        data[FORM_FIELDS.NEW_PASSWORD],
        data[FORM_FIELDS.NEW_AGAIN_PASSWORD],
        location.search,
      );
      setDialogOpen(true);
    } catch (error) {
      setError(FORM_FIELDS.NEW_AGAIN_PASSWORD, {
        type: "manual",
        message: unwrapErrorMessage(error),
      });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Promena lozinke
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          className={classes.form}
          noValidate
        >
          <FormGroup
            fullWidth={true}
            className={classes.txtField}
            variant="outlined"
          >
            <FormLabel className={classes.groupLabel} component="legend">
              Unesite novu lozinku:
            </FormLabel>
            <FormControl
              fullWidth={true}
              className={classes.txtField}
              variant="outlined"
            >
              <InputLabel
                htmlFor="outlined-adornment-password"
                variant="outlined"
              >
                Nova lozinka
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                value={editPasswordState.newPassword}
                type={editPasswordState.showNewPassword ? "text" : "password"}
                variant="outlined"
                name={FORM_FIELDS.NEW_PASSWORD}
                inputRef={register({ required: "Polje je obavezno" })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {editPasswordState.showNewPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={95}
              />
              <FormHelperText error={errors[FORM_FIELDS.NEW_PASSWORD]}>
                {errors[FORM_FIELDS.NEW_PASSWORD]?.message}
              </FormHelperText>
            </FormControl>
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.newAgainPasswordTxtFild}
            >
              <InputLabel
                htmlFor="outlined-adornment-repeatedpassword"
                variant="outlined"
              >
                Ponovljena nova lozinka
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-repeatedpassword"
                value={editPasswordState.newAgainPassword}
                type={
                  editPasswordState.showNewAgainPassword ? "text" : "password"
                }
                variant="outlined"
                name={FORM_FIELDS.NEW_AGAIN_PASSWORD}
                inputRef={register({ required: "Polje je obavezno" })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewAgainPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {editPasswordState.showNewAgainPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={176}
              />
              <FormHelperText error={errors[FORM_FIELDS.NEW_AGAIN_PASSWORD]}>
                {errors[FORM_FIELDS.NEW_AGAIN_PASSWORD]?.message}
              </FormHelperText>
            </FormControl>
          </FormGroup>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              className={classes.resetBtnSpace}
              color="primary"
              variant="text"
              onClick={abort}
            >
              Odustani
            </Button>
            <Button
              className={classes.saveBtnSpace}
              type="submit"
              color="primary"
              variant="contained"
            >
              Promeni
            </Button>
          </Box>
        </form>
      </div>
      {dialogOpen && (
        <MessageDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          title="Promena lozinke - Obaveštenje"
          message="Vaša lozinka je uspešno promenjena"
          button1Text="Zatvori"
          button1Handler={dialogCloseHandler}
        />
      )}
    </Container>
  );
}
