import MaterialList from "../common/MaterialList";
import React, { useEffect, useState, useMemo } from "react";
import { getAxiosInstance } from "../../data-source/axiosProvider";
import { useDispatch } from "react-redux";
import {
  unknownErrorThanActionHandlerFactory,
  convertArrayToObjectAsKeyValueKeys,
  promiseDecorator,
} from "../../services/utils";
import {
  S_ATTR_COMP_BRANCH,
  S_ATTR_COMP_LINE,
} from "../../constants/serverContract";

export default function CompanyLines() {
  const [companyLines, setCompanyLines] = React.useState([]);
  let [companyBranches, setCompanyBranches] = useState({});

  const defaultColumns = [
    {
      title: "Naziv",
      field: "name",
    },
    {
      title: "Ispostave",
      field: "company_branch_id_fk",
      lookup: { 1: "", 2: "" },
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);
  const dispatch = useDispatch();
  const errorHandlerThanAction = unknownErrorThanActionHandlerFactory(dispatch);

  useEffect(() => {
    (async function () {
      const tempCompanyLines = loadCompanyLines(false);
      const tempCompanyBranches = loadCompanyBranches(false);

      let tempResult = await Promise.all([
        tempCompanyLines,
        tempCompanyBranches,
      ]);
      if (tempResult.every((resultItem) => resultItem != null)) {
        let tempColumns = [...defaultColumns];
        tempColumns = adjustColumnFields(
          S_ATTR_COMP_LINE.COMPANY_BRANCH_ID_FK,
          [S_ATTR_COMP_BRANCH.COMPANY_BRANCH_ID, S_ATTR_COMP_BRANCH.NAME],
          false,
        )(tempResult[1], tempColumns);
        console.log("Temp columns", tempColumns);
        setCompanyBranches(tempResult[1]);
        setCompanyLines(tempResult[0]);
        setColumns(tempColumns);
      } else {
        errorHandlerThanAction(
          "Došlo je do greške prilikom učitavanja podataka sa servera",
        );
      }
    })();
  }, []);

  async function loadCompanyLines(putInState = true) {
    return getAxiosInstance()
      .get(`/v1/companylines`)
      .then((res) => res.data)
      .then((res) => {
        if (putInState) setCompanyLines(res);

        return res;
      })
      .catch(errorHandlerThanAction);
  }

  async function loadCompanyBranches(putInState = true) {
    return getAxiosInstance()
      .get(`/v1/companybranches`)
      .then((res) => res.data)
      .then((res) => {
        if (putInState) setCompanyBranches(res);

        return res;
      })
      .catch(errorHandlerThanAction);
  }

  function adjustColumnFields(
    fieldName,
    lookupKeysArray,
    shouldSetState = false,
  ) {
    return function (result, columns, filterOptions) {
      let tempNewColumns = columns;
      const columnIndex = tempNewColumns.findIndex(
        (el) => el.field === fieldName,
      );
      tempNewColumns[columnIndex] = {
        ...tempNewColumns[columnIndex],
        lookup: convertArrayToObjectAsKeyValueKeys(...lookupKeysArray, result),
      };
      if (shouldSetState) setColumns(tempNewColumns);
      return tempNewColumns;
    };
  }

  console.log(companyBranches.company_branch_id_fk);
  const addCompanyLines = async (newData) => {
    const { name = null } = newData;
    if (name == null || name.trim().length === 0) {
      errorHandlerThanAction("Polje Naziv je obavezno");
      throw new Error("Greska u polju Naziv");
    }
    await getAxiosInstance()
      .post(`/v1/companylines`, {
        name: newData.name,
        company_branch_id_fk: newData.company_branch_id_fk,
      })
      .then((newCompanyLines) => {
        let company_lines = companyLines;
        company_lines.push(newCompanyLines);
        setCompanyLines(company_lines);
      });
    loadCompanyLines();
  };

  const deleteCompanyLines = async (newData) => {
    return getAxiosInstance()
      .delete(`/v1/companylines/${newData.company_line_id}`)
      .then(() => {
        loadCompanyLines();
      })
      .catch(errorHandlerThanAction);
  };

  const updateCompanyLines = async (newData) => {
    return getAxiosInstance()
      .patch(`/v1/companylines/${newData.company_line_id}`, {
        name: newData.name,
        company_branch_id_fk: newData.company_branch_id_fk,
      })
      .then(() => {
        loadCompanyLines();
      })
      .catch(errorHandlerThanAction);
  };

  return (
    <MaterialList
      columns={columns}
      data={companyLines}
      title="Linije"
      localization={{
        buttons: {
          addText: "Dodaj",
          cancelText: "Poništi",
          updateText: "Izmeni",
        },
      }}
      editable={{
        onRowAdd: (newData) => addCompanyLines(newData),
        onRowUpdate: (newData) => updateCompanyLines(newData),
        onRowDelete: (newData) => deleteCompanyLines(newData),
      }}
      options={{
        listStyle: {
          width: "70ch",
          marginBottom: 2,
        },
      }}
    />
  );
}
